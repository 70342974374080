import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import createStyles from './MobileMenu.styles';
import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AppsIcon from '@material-ui/icons/Apps';
import { Link } from 'react-router-dom';

const MobileMenu = (props) => {
  const classes = createStyles();
  const { categories } = props;

  return (
    <Drawer
      anchor='left'
      onClose={() => props.toggleDrawer(false)}
      open={props.isOpen}
    >
      <div
        onClick={() => props.toggleDrawer(false)}
        onKeyDown={() => props.toggleDrawer(false)}
        className={classes.drawerMenuList}
      >
        <IconButton
          onClick={() => props.toggleDrawer(false)}
          color='inherit'
          aria-label='open menu'
          component='span'
          className={classes.menuMobile}
        >
          <CloseIcon />
        </IconButton>
        <div className={classes.listTitle}>
          <AppsIcon className={classes.titleIcon} />
          <Typography color='primary'>Categorias</Typography>
        </div>
        <List component='nav' aria-label='main mailbox folders'>
          {categories &&
            categories.map((category) => {
              return (
                <div key={category.id}>
                  <ListItem
                    className={classes.listItem}
                    button
                    component='a'
                    href={'/categoria/' + category.id}
                  >
                    <ListItemText primary={category.title}></ListItemText>
                  </ListItem>
                </div>
              );
            })}
          {/* <ListItem className={classes.listItem} button component='a' href='/teste'>
            <ListItemText primary='Reforço da Educação Básica'></ListItemText>
          </ListItem>
          <ListItem className={classes.listItem} button component='a' href='/teste'>
            <ListItemText primary='Competência e Geração de Renda'></ListItemText>
          </ListItem>
          <ListItem className={classes.listItem} button component='a' href='/teste'>
            <ListItemText primary='Saúde e Segurança do Trabalho'></ListItemText>
          </ListItem> */}
        </List>
        <Divider className={classes.divider} />
        <List component='nav' aria-label='main mailbox folders'>
          <ListItem
            className={classes.listItem}
            button
            component='a'
            href='/politicas'
          >
            <ListItemText primary='Políticas e termos de uso'></ListItemText>
          </ListItem>

          <ListItem
            className={classes.listItem}
            button
            component='a'
            href='/perguntas-frequentes'
          >
            <ListItemText primary='Perguntas Frequentes'></ListItemText>
          </ListItem>

        </List>
      </div>
    </Drawer>
  );
};

MobileMenu.propTypes = {};

export default MobileMenu;
