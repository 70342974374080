import request from 'shared/request';
// import BadRequestError from 'errors/BadRequestError';
import { getPaymentMethodMock } from 'mock/PaymentMethod';

export const getMyPayments = async () => {
  try {
    const response = await request.get('/orders/mine/history');
    return response.data;

  } catch (error) {
    //TODO
  }
};

export const getPaymentMethod = async () => {
  try {
    // const response = await request.get('/products/showcase');
    // return response.data;

    return new Promise(resolve =>
      setTimeout(function() {
        const result = getPaymentMethodMock();
        resolve(result);
      }, 1000)
    );
  } catch (error) {
    //TODO
  }
};

export const savePaymentMethod = async ({flag, number, fullName, month, year, defaultPayment}) => {
  try {
    // const response = await request.post('/products/showcase');
    // return response.data;
    return new Promise(resolve =>
      setTimeout(function() {

        resolve('success');
      }, 1000)
    );
  } catch (error) {
    //TODO
  }
}

export const deletePaymentMethod = async (id) => {
  try {
    // const response = await request.delete('/products/showcase');
    // return response.data;
    return new Promise(resolve =>
      setTimeout(function() {

        resolve('success');
      }, 1000)
    );
  } catch (error) {
    //TODO
  }
}