import React, { useState } from 'react';
import { Formik } from 'formik';
import ChangePasswordForm from './ChangePasswordForm';
import { changePasswordFormValidator } from './ChangePasswordForm.validator';
import InvalidCredentialsError from 'shared/error/Exceptions/InvalidCredentialsException';


const ChangePasswordFormWrapper = props => {
  const initialValues = {
    newPassword: '', 
    passwordConfirmation: '', 
    oldPassword: ''
  };

  const {
    handlePasswordChange, 
    isLoading, 
    handleClose, 
    errors, 
    afterPasswordChange, 
    handleOpenRecoverPass
  } = props;

  const handleSubmitForm = async (formValues, actions) => {
    try {
      await handlePasswordChange(formValues);
      handleClose();
      if (afterPasswordChange) afterPasswordChange();
    } catch (err) {
      if (err instanceof InvalidCredentialsError) {
        this.setState({
          externalErrors: { email: '' }
        });
      }
    }
  };


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={changePasswordFormValidator}
      onSubmit={handleSubmitForm}
    >
      {propsFormik => (
        <ChangePasswordForm
          {...propsFormik}
          isLoading={isLoading}
          handleOpenRecoverPass={handleOpenRecoverPass}
        />
      )}
    </Formik>
  );
};

export default ChangePasswordFormWrapper;
