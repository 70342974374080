import PageContent from './PageContent.jsx';
import { openLoginBox, closeLoginBox } from 'modules/Login/Login.ducks.js';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    isLoginBoxOpen: state.session.ui.isLoginBoxOpen,
    authBoxPosition: state.session.ui.authBoxPosition
  };
};

const mapDispatchToProps = {
  openLoginBox,
  closeLoginBox
};

export default connect(mapStateToProps, mapDispatchToProps)(PageContent);
