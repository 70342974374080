import React, { useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import RegisterForm from './Form/RegisterBoxForm.formik';
import Button from 'components/ButtonPrimary';
import createStyles from './RegisterBoxPF.styles';

const RegisterBoxPF = (props) => {
  const classes = createStyles();
  const {
    afterRegister,
    history,
    citiesByState,
    citys
  } = props;

  const [signUpCompleted, setSignUpCompleted] = React.useState(false);
  const [confirmationEmail, setConfirmationEmail] = React.useState('');

  const goToVitrine = () => {
    window.open('/', '_self');
  }

  const FormBox = () => {
    return (
      <CardContent className={classes.cardContent}>
        <Typography
          variant='subtitle1'
          align='center'
          className={classes.title}
        >
          Cadastre-se
        </Typography>

        <RegisterForm
          history={history}
          setSignUpCompleted={setSignUpCompleted}
          setConfirmationEmail={setConfirmationEmail}
          afterRegister={afterRegister}
          citiesByState={citiesByState}
          citys={citys}
        />

        <Typography align='center' className={classes.registerCall}>
          Já tem uma conta?{' '}
          <Link to='/login'
            className={classes.loginHook}>
            Faça login
          </Link>
        </Typography>
      </CardContent>
    );
  };

  const MessageBox = () => {
    window.scrollTo(0, 0)
    return (
      <CardContent className={classes.cardContent}>
        <Typography
          variant='subtitle1'
          align='center'
          className={classes.title}
        >
          Você foi cadastrado!
        </Typography>
        <Typography
          className={classes.messageText}
          variant='body1'
          align='center'
        >
          {`Um email de confirmação do seu cadastro foi enviado para ${confirmationEmail}`}
        </Typography>
        <Button lowercase width='100%' onClick={goToVitrine}>
          Clique para Continuar...
        </Button>
      </CardContent>
    );
  };

  return (
    <Card
      onClick={(e) => e.stopPropagation()}
      elevation={props.elevation || 2}
      className={`${classes.rootRegisterBox} ${props.className}`}
    >
      {!signUpCompleted ? <FormBox /> : <MessageBox />}
    </Card>
  );
};

RegisterBoxPF.propTypes = {
  handleClose: PropTypes.func,
  handleLoginClick: PropTypes.func,
  register: PropTypes.func,
  elevation: PropTypes.number
};

export default RegisterBoxPF;
