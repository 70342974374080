import React, { useState } from 'react';
import createStyles from './CartPayment.styles';
import PropTypes from 'prop-types';
import { Typography, useMediaQuery, Menu, MenuItem } from '@material-ui/core';
import MainContainer from 'components/MainContainer';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OrderSummary from 'modules/Cart/OrderSummary';
import BilletForm from './BilletForm';
import GratuityForm from './GratuityForm';
import CreditCardForm from './CreditCardForm';
import EditUserInfoModal from './EditUserInfoModal';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link, useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ContactsOutlined } from '@material-ui/icons';

const CartPayment = (props) => {
  const location = useLocation();
  const {
    handleNext,
    user,
    totals,
    saleCreditCard,
    cart,
    saleError,
    hasSaleError,
    isLoading,
    saleSuccess,
    saleReset,
    saleBillet,
    billet,
    saleFree,
    cartItems,
    isLoadingTotals
  } = props;
  const classes = createStyles(props);
  const [address, setAddress] = useState();
  const history = useHistory();

  useEffect(() => {
    if (location.state) {
      setAddress(location.state.data.address);
    }
  }, [location.state]);

  useEffect(() => {
    saleReset();
    setPaymentTabs(['Cartão de Crédito', 'Boleto']);
    // setPaymentTabs(['Cartão de Crédito']);
    if (totals?.grandTotal === 0) {
      setPaymentTabs(['Gratuito']);
    }
  }, []);

  useEffect(() => {
    if (!props.isAuthenticated) {
      history.push('/carrinho/itens');
    }
  }, [props.isAuthenticated]);

  useEffect(() => {
    if (saleSuccess) {
      handleNext();
    }
  }, [saleSuccess]);

  const isMobileXS = useMediaQuery('(max-width: 550px)');
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [paymentTabs, setPaymentTabs] = useState([]);
  const [activePaymentTab, setActivePaymentTab] = useState('');

  useEffect(() => {
    setActivePaymentTab(paymentTabs[0]);
  }, [paymentTabs]);

  const handleOpenMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleOpenAddressEdit = (event) => {
    setIsEditUserModalOpen(true);
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  return (
    <MainContainer>
      {props.isAuthenticated && (
        <>
          <div className={classes.paymentRoot}>
            <div className={classes.orderInfoContainer}>
              <div className={classes.subInfoCard}>
                <div className={classes.titleAction}>
                  <Typography variant='subtitle1'>
                    Informações da inscrição
                  </Typography>
                  {isMobileXS ? (
                    <div>
                      <MoreVertIcon onClick={handleOpenMenu} />
                      <Menu
                        anchorEl={anchorElMenu}
                        keepMounted
                        open={Boolean(anchorElMenu)}
                        onClose={handleCloseMenu}
                      >
                        <MenuItem onClick={handleOpenAddressEdit}>
                          Alterar
                        </MenuItem>
                      </Menu>
                    </div>
                  ) : (
                    <></>
                    // <ButtonPrimary
                    //   variant='outlined'
                    //   onClick={handleOpenAddressEdit}
                    // >
                    //   Alterar
                    // </ButtonPrimary>
                  )}
                </div>
                <div className={classes.sectionSeparator}>
                  <Typography variant='body1' gutterBottom>
                    <b>Dados para Matrícula</b>
                  </Typography>
                  <Typography variant='body1' gutterBottom>
                    {user.name}
                  </Typography>
                  <Typography variant='body1'>{user.cpf}</Typography>
                  {user.tel && (
                    <Typography variant='body1'>{user.tel}</Typography>
                  )}
                  <Typography variant='body1'>{user.email}</Typography>
                </div>
                {address && (
                  <>
                    <div className={classes.sectionSeparator}>
                      <Typography variant='body1' gutterBottom>
                        <b>Dados do Responsável Financeiro</b>
                      </Typography>
                      <Typography variant='body1'>{address.name}</Typography>
                      <Typography variant='body1'>{address.cpf}</Typography>
                      <Typography variant='body1'>{address.email}</Typography>

                      <Typography variant='body1'>{address.cep}</Typography>
                      <Typography variant='body1'>
                        {address.address}, {address.number}
                      </Typography>
                      <Typography variant='body1'>
                        {address.neighborhood}
                      </Typography>
                      <Typography variant='body1'>
                        {address.city} - {address.state}
                      </Typography>
                    </div>

                    {/* <div className={classes.sectionSeparator}>
                  <Typography variant='body1' gutterBottom>
                    <b>Endereço de cobrança</b>
                  </Typography>
                  <Typography variant='body1'>{address.address}</Typography>
                  <Typography variant='body1'>
                    {address.neighborhood}
                  </Typography>
                  <Typography variant='body1'>
                    {address.city} - {address.state}
                  </Typography>
                  <Typography variant='body1'>CEP: {address.cep}</Typography>
                </div> */}
                  </>
                )}
              </div>
              <div className={classes.resumeInfoCard}>
              {isLoadingTotals ? (
                <div className={classes.rootInnerLoading}>
                  <CircularProgress />
                </div>
              ) : (<>
               <OrderSummary items={cartItems} />
  
              </>)}
              </div>
            </div>

            <div className={classes.paymentInfoContainer}>
              <Typography variant='subtitle1'>Forma de Pagamento</Typography>
              <ul className={classes.paymentOptionsTabs}>
                {paymentTabs && paymentTabs.map((tab, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => setActivePaymentTab(tab)}
                      className={
                        tab === activePaymentTab
                          ? classes.activeTab
                          : classes.nonActiveTab
                      }
                    >
                      {tab}
                    </li>
                  );
                })}
              </ul>

              {cartItems && activePaymentTab === 'Cartão de Crédito' && 
                <CreditCardForm
                  handleNext={handleNext}
                  sale={saleCreditCard}
                  address={address}
                  cart={cart}
                  products={cartItems}
                  isLoading={isLoading}
                  hasSaleError={hasSaleError}
                />
              }

              {cartItems && activePaymentTab === 'Boleto' && 
                <BilletForm 
                  handleNext={handleNext} 
                  sale={saleBillet} 
                  address={address}
                  cart={cart}
                  totals={totals}
                  products={cartItems}
                  isLoading={isLoading}
                />
              }

              {cartItems && activePaymentTab === 'Gratuito' && 
                <GratuityForm 
                  handleNext={handleNext} 
                  sale={saleCreditCard} 
                  address={address}
                  cart={cart}
                  totals={totals}
                  products={cartItems}
                  isLoading={isLoading}
                />
              }

              {hasSaleError && (
                <Alert className={classes.paymentError} severity='error'>
                  {saleError
                    ? saleError
                    : 'Houve um problema ao processar seu pedido! Para mais detalhes entre em contato com sua operadora de Cartão de Crédito.'}
                </Alert>
              )}
            </div>
          </div>

          <EditUserInfoModal
            open={isEditUserModalOpen}
            handleClose={() => setIsEditUserModalOpen(false)}
          />
        </>
      )}
    </MainContainer>
  );
};

CartPayment.propTypes = {
  isLoading: PropTypes.bool,
  handleNext: PropTypes.func,
  cartItems: PropTypes.array,
  user: PropTypes.object
};

export default CartPayment;
