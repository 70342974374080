import React, { useRef, useEffect } from 'react';
import createStyles from './RegisterConfirmation.styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { Typography } from '@material-ui/core';
import Button from 'components/ButtonPrimary';


const RegisterConfirmation = props => {
    const classes = createStyles();
    const rootRef = useRef(null);
    const queryString = props.location.search;
    const urlParams = new URLSearchParams(window.location.search);

    const {
        isLoading,
        isConfirmation,
        confirmationRegister,
        resetConfirmationRegistert
    } = props;
    useEffect(() => {
        try {
            var requestObject = {
                email: urlParams.get("email"),
                confirmation: urlParams.get("confirmation")
            }
            confirmationRegister(requestObject);
        } catch (err) {
            console.log(err)
        }
    }, [])

    return (
        <React.Fragment style={classes.container} >
            <div style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {isLoading && isConfirmation
                    ?
                    <>
                        <div className={classes.confirmationContent}>
                            <CheckCircleIcon fontSize='large' />
                            <Typography>
                                Conta Confirmada com sucesso!
                            </Typography>
                        </div>
                    </> : <>
                        <div className={classes.confirmationContent}>
                            <HourglassEmptyIcon fontSize='large' />
                            <Typography>
                                Aguarde a Confirmação da sua conta....
                            </Typography>
                        </div>
                    </>}
            </div>
        </React.Fragment>
    );
};

export default RegisterConfirmation;
