import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 360,
    height: 286,
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    padding: '30px',
  },
  content:{
    display: 'flex', 
    flexDirection: 'column', 
    alignSelf: 'center', 
    width: 300, 
    height: 226, 
    position: 'relative', 
    left: 30
  },
  title: {
    textAlign: 'center', 
    color: 'rgba(0, 0, 0, 0.8)', 
    marginBottom: '10px'
  }, 
  titleSuccess: {
    color: 'rgba(0, 0, 0, 0.8)',  
    marginBottom: '16px'
  },
  text: {
    color: 'rgba(0, 0, 0, 0.65)', 
    width: 300, 
    height: 38, 
    marginBottom: '16px'
  }, 
  button: {
    marginTop: '24px'
  }, 
  registerLink: {
    marginTop: '16px'
  },
  clearIcon: {
    position: 'absolute',
    left: '90.83%',
    top: '0.83%',
    cursor: 'pointer'
  }
}));

export default createStyles;
