import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  forgotPasswordLabel: {
    color: theme.colors.blueCompany,
    textDecoration: 'none'
  },
  rememberMeLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    marginBottom: '1.6rem'
  },
  form: {
    display: 'flex',
    // width: '65rem',
    padding: '4rem 8rem',
    '@media(max-width:922px)': {
      width: 'auto',
      flexDirection: 'column',
      padding: '4rem 4rem'
    },
    '@media(max-width:422px)': {
      padding: '4rem 0rem'
    }
  },
  formLeft: {
    margin: '1rem',
    width: '60%',
    '@media(max-width:922px)': {
      width: '100%',
      margin: '0'
    }
  },
  formRight: {
    margin: '1rem',
    width: '40%',
    '& span': {
      fontSize: '1.4rem'
    },
    '@media(max-width:922px)': {
      width: '100%',
      margin: '0'
    }
  },
  warning: {
    marginBottom: '1.6rem',
    height: 15,
    left: '0%',
    right: '0%',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 11,
    lineHeight: 15,
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.04em',
    color: 'rgba(0, 0, 0, 0.65)'
  },
  errorsText: {
    margin: '-1rem 0 2rem 0'
  },
  label: {
    color: theme.colors.text.primary,
    marginBottom: '0.5rem',
    fontSize: '1.4rem',
    width: '100%'
  }
}));

export default createStyles;
