import React, { useEffect, useState, useRef } from 'react';
import MainContainer from 'components/MainContainer';
import NewPasswordFormik from './FormNewPassword/NewPasswordForm.formik';
import Card from '@material-ui/core/Card';
import createStyles from './NewPassword.styles';
import CardContent from '@material-ui/core/CardContent';

const NewPassword = props => {
  const [isMobile, setIsMobile] = useState(false);
  const classes = createStyles(isMobile);
  const [resetToken, setResetToken] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const rootRef = useRef(null);

  const { isLoading, success, registerNewPassword, emailToResetPassword, serverError } = props;

  useEffect(() => {
    const updateWidth = () => {
      if (rootRef.current) {
        setIsMobile(rootRef.current.offsetWidth < 720 ? true : false)
      }
    };
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  });

  useEffect(() => {
    const token = props.location.search.substring(7, props.location.search.indexOf("&"));
    const email = props.location.search.substring(props.location.search.indexOf("&")).substring(7);
    
    setResetToken(token);
    setResetEmail(email);
  }, []);


  return (
    <React.Fragment>
      <div ref={rootRef} className={classes.registrationContent}>
        <section className={classes.cardContainer}>
              <NewPasswordFormik 
                registerNewPassword={registerNewPassword}
                isLoading={isLoading}
                success={success}
                emailToResetPassword={emailToResetPassword}
                resetToken={resetToken}
                resetEmail={resetEmail}
                serverError={serverError}
              />
        </section>
        </div>
    </React.Fragment>

  );
};

export default NewPassword;
