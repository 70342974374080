import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  root:  props => ({
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    padding: '1.6rem',
    backgroundColor: theme.colors.white,
    boxShadow: '0px 1px 6px 0px rgba(0,0,0,0.25)',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'all 150ms ease-in-out',
    borderRadius: '5px',
    borderLeft: '5px solid ',
    borderLeftColor: props.status == 1 ? theme.colors.purpleBlog
                  : props.status == 2 ? theme.colors.cyanYou 
                  : props.status == 3 ? theme.colors.yellowHealthCare
                  : props.status == 4 ? theme.colors.blueCompany : theme.colors.divider,

    '&:hover': {
      boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.25)',

      '& $image': {
        transform: 'scale(1.03)'
      }
    },
    '@media (max-width: 950px)': {
      flexDirection: 'column',

    },
  }),

  imageContainer: {
    borderRadius: '3px',
    boxSizing: 'border-box',
    width: '13.6rem',
    height: '13.6rem',
    display: 'block',
    cursor: 'pointer',
    overflow: 'hidden',
    '@media (max-width: 950px)': {
      width: '100%',
      marginBottom: '1.6rem'

    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'all 200ms ease-in-out',

    '&:hover': {
      filter: 'brightness(90%)'
    }
  },

  infoContainer: {
    boxSizing: 'border-box',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    paddingLeft: '1.6rem',
    '@media (max-width: 950px)': {
      paddingLeft: '0'


    },
  },
  lowerInfo: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%',

    '@media (max-width: 950px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',

    },
  },
  title: {
    margin: '0',
    color: theme.colors.text.dark,
    fontSize: '2.2rem',
    marginBottom: '1.5rem',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  detailsList: {
    margin: '0',
    marginLeft: '-1rem',
    listStyle: 'none',
    padding: '0',
    marginBottom: '0rem',
    color: theme.palette.text.secondary,
    '@media (max-width: 950px)': {
      marginBottom: '1.6rem',

    },
  },
  detailsListRight: {
    margin: '0',
    marginLeft: '1rem',
    listStyle: 'none',
    padding: '0',
    marginBottom: '0rem',
    color: theme.palette.text.secondary,
    '@media (max-width: 950px)': {
      marginBottom: '1.6rem',

    },
  },

  detail: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)':{
      marginBottom: '1rem',
    },
    '& p': {
      fontSize: '1.6rem',
      margin: '0',
      display: 'inline-block',
      marginLeft: '1rem'
    }
  },
  status: props => ({

    '&:before':{
      content: '""',
      display: 'inline-block',
      marginRight: '.8rem',
      width: '1rem',
      height: '1rem',
      borderRadius: '2rem',
      backgroundColor: props.status == 1 ? theme.colors.purpleBlog
      : props.status == 2 ? theme.colors.cyanYou 
      : props.status == 3 ? theme.colors.yellowHealthCare
      : props.status == 4 ? theme.colors.blueCompany : theme.colors.divider,

    }

  }),
 
  noClassesAvailable: {
    textAlign: 'center',
    width: '100%',
    padding: '3rem 0'
  },
  coursepropreties: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export default createStyles;


