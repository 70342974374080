import * as Yup from 'yup';
import { validateCNPJ } from 'shared/util/ValidateCNPJ.js';

const loginForm = {
  name: Yup.string().required('Este campo é obrigatório'),
  email: Yup.string()
    .required('Este campo é obrigatório')
    .email('Email inválido'),
  cnpj: Yup.string()
    .required('Este campo é obrigatório')
    .test('validateCpf', 'CPF inválido', function (value) {
      return validateCNPJ(value);
    }),
  cep: Yup.string().required('Este campo é obrigatório')
    .test('cep', 'Este campo é obrigatório', function(value) {
      // needed because placeholder `_____-___` counts as a non-empty string for required check above
      return value && value.replace(/_|-/g, '');
    }),
  password: Yup.string()
    .required('Este campo é obrigatório')
    .matches(/[A-Z]/, 'Ao menos uma letra maiúscula.')
    .matches(/\d/, 'Ao menos um número.')
    .min(8, 'A senha deve conter no mín. 8 caracteres'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'As senhas devem ser iguais'
  )
};

export const registerFormValidator = Yup.object().shape(loginForm);
