import AuthenticationBox from './AuthenticationBox.jsx';
import { connect } from 'react-redux';
import { openLoginBox, closeLoginBox } from 'modules/Login/Login.ducks.js';

const mapStateToProps = (state) => {
  return {
    isLoading: state.session.isLoading
  };
};

const mapDispatchToProps = {
  openLoginBox,
  closeLoginBox
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationBox);
