import AddCardModal from './AddCardModal.jsx';
import { connect } from 'react-redux';
import { handleSavePaymentMethod } from 'modules/PaymentMethods/PaymentMethods.ducks.js';

const mapStateToProps = state => {
  return {
    isAdding: state.paymentMethods.isLoading
  };
};

const mapDispatchToProps = {
  handleSavePaymentMethod
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCardModal);