import { getAdresses } from 'api/Address';
import { saveAddress } from 'api/Address';
import { deleteAddress } from 'api/Address';

// Actions
const FETCH_ADDRESS_START = 'sesi/login/FETCH_ADDRESS_START';
const FETCH_ADDRESS_SUCCESS = 'sesi/login/FETCH_ADDRESS_SUCCESS';
const FETCH_ADDRESS_FAILURE = 'sesi/login/FETCH_ADDRESS_FAILURE';
const ADDRESS_RESET = 'sesi/login/ADDRESS_RESET';

const SAVE_ADDRESS_START = 'sesi/login/SAVE_ADDRESS_START';
const SAVE_ADDRESS_SUCCESS = 'sesi/login/SAVE_ADDRESS_SUCCESS';
const SAVE_ADDRESS_FAILURE = 'sesi/login/SAVE_ADDRESS_FAILURE';
const SAVE_ADDRESS_RESET = 'sesi/login/SAVE_ADDRESS_RESET';

const DELETE_ADDRESS_START = 'sesi/login/DELETE_ADDRESS_START';
const DELETE_ADDRESS_SUCCESS = 'sesi/login/DELETE_ADDRESS_SUCCESS';
const DELETE_ADDRESS_FAILURE = 'sesi/login/DELETE_ADDRESS_FAILURE';
const DELETE_ADDRESS_RESET = 'sesi/login/DELETE_ADDRESS_RESET';

// Reducer
const initialState = {
  adresses: [],
  isLoading: false, 
  isAdding: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_ADDRESS_START:
      return { ...state, isLoading: true };
    case FETCH_ADDRESS_SUCCESS:
      return { ...state, ...action.payload, isLoading: false };
    case FETCH_ADDRESS_FAILURE:
      return { ...state, ...action.payload };
    case ADDRESS_RESET:
      return { ...state, ...initialState };
    case SAVE_ADDRESS_START:
      return { ...state, isAdding: true };
    case SAVE_ADDRESS_SUCCESS:
      return { ...state, ...action.payload, isAdding: false };
    case SAVE_ADDRESS_FAILURE:
      return { ...state, ...action.payload };
    case SAVE_ADDRESS_RESET:
      return { ...state, ...initialState };
    case DELETE_ADDRESS_START:
      return { ...state, isLoading: true };
    case DELETE_ADDRESS_SUCCESS:
      return { ...state, ...action.payload, isLoading: false };
    case DELETE_ADDRESS_FAILURE:
      return { ...state, ...action.payload };
    case DELETE_ADDRESS_RESET:
      return { ...state, ...initialState }; 
    default:
      return state;
  }
}

// Action Creators
export function fetchAddressStart() {
  return { type: FETCH_ADDRESS_START };
}

export function fetchAddressSuccess(data) {
  return { type: FETCH_ADDRESS_SUCCESS, payload: data };
}

export function fetchAddressFailure(data) {
  return { type: FETCH_ADDRESS_FAILURE, payload: data };
}

export function reset() {
  return { type: ADDRESS_RESET };
}

export function saveAddressStart() {
  return { type: SAVE_ADDRESS_START };
}

export function saveAddressSuccess(data) {
  return { type: SAVE_ADDRESS_SUCCESS, payload: data };
}

export function saveAddressFailure(data) {
  return { type: SAVE_ADDRESS_FAILURE, payload: data };
}

export function saveAddressReset() {
  return { type: SAVE_ADDRESS_RESET };
}


export function deleteAddressStart() {
  return { type: DELETE_ADDRESS_START };
}

export function deleteAddressSuccess(data) {
  return { type: DELETE_ADDRESS_SUCCESS, payload: data };
}

export function deleteAddressFailure(data) {
  return { type: DELETE_ADDRESS_FAILURE, payload: data };
}

export function deleteReset() {
  return { type: DELETE_ADDRESS_RESET };
}

// side effects, only as applicable
export const fetchAddress = () => {
  return async dispatch => {
    dispatch(fetchAddressStart());

    try {
      const response = await getAdresses();
      dispatch(fetchAddressSuccess(response));
    } catch (error) {
      dispatch(fetchAddressFailure(error));
    }
  };
};

export const handleSaveAddress = ({name, street, complement, neighbourhood, city, uf, cep}) => {
  return async dispatch => {
    dispatch(saveAddressStart());

    try {
      const response = await saveAddress({name, street, complement, neighbourhood, city, uf, cep});
      dispatch(saveAddressSuccess(response));
    } catch (error) {
      dispatch(saveAddressFailure(error));
    }
  };
}

export const handleDeleteAddress = (id) => {
  return async dispatch => {
    dispatch(deleteAddressStart());

    try {
      const response = await deleteAddress(id);
      dispatch(deleteAddressSuccess(response));
    } catch (error) {
      dispatch(deleteAddressFailure(error));
    }
  };
};


