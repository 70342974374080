import React from 'react';
import createStyles from './DotIndicators.styles';

// const DotIndicator = ()  => { return <p> teste </p> }

// export default DotIndicator

const DotIndicators = props => {
  const classes = createStyles(props);

  const {
    slides,
    visibleSlides,
    currentSlide,
  } = props;

  const renderDotIndicators = () => {
    let dotArray = [];

    const totalDots = slides - visibleSlides;

    for (let i = 0; i <= totalDots; i++) {
      dotArray.push(
        <div
          className={
            i == currentSlide
              ? `${classes.dotIndicator} ${classes.active}`
              : classes.dotIndicator
          }
        ></div>
      );
    }
    return dotArray;
  };

  return (
    <div className={classes.dotIndicatorsContainer}>
      {renderDotIndicators()}
    </div>
  );
};

export default DotIndicators;
