import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  searchHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '@media (max-width: 1025px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      height: '10rem'
    }
  },
  searchDescription: {
    marginRight: '2rem'
  },
  filters: {
    display: 'flex',
    '@media (max-width: 450px)': {
      width: '100%'
    }
  },
  headerSelectSeach: {
    width: '30rem',
    marginRight: '2rem',
    '@media (max-width: 450px)': {
      width: '100%'
    }
  },
  searchTermSpan: {
    color: theme.colors.text.primary
  }
}));

export default createStyles;
