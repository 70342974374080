import React, { useState, useEffect } from 'react';
import Input from 'components/Input';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from 'components/ButtonPrimary';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { Typography, FormControl, InputLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Select, { components } from 'react-select';
import PaymentIcon from '@material-ui/icons/Payment';
import createStyles from './AddCardForm.styles';
import { FormHelperText } from '@material-ui/core';

const StyledCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#32559D;'
    }
  },
  checked: {},
  body1: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '19px'
  }
})((props) => <Checkbox color='default' {...props} />);

const month = [
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' }
];
const year = [
  { value: '2020', label: '2020' },
  { value: '2021', label: '2021' },
  { value: '2022', label: '2022' }
];

const AddCardForm = (props) => {
  const classes = createStyles();
  const {
    errors,
    touched,
    values,
    isSubmitting,
    isLoading,
    // externalErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    className,
    formErrors
  } = props;

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Input
        placeholder='Ex: 0000.0000.0000.0000'
        label='Número do cartão'
        type='text'
        name='number'
        mask={'9999.9999.9999.9999'}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.number}
        // className={classes.input}
        customClasses={{ root: classes.input }}
        error={touched.number ? errors.number : null}
      />

      <Input
        placeholder='Ex: JOAO SILVA'
        label='Nome do titular (como está escrito no cartão)'
        type='text'
        name='fullName'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.fullName}
        // className={classes.input}
        customClasses={{ root: classes.input }}
        error={touched.fullName ? errors.fullName : null}
      />

      <InputLabel className={classes.label}>Data de validade</InputLabel>
      <div className={classes.selectContainer}>
        <Select
          className={classes.select}
          placeholder='Mês'
          options={month}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0
          })}
        />

        <Select
          className={classes.select}
          placeholder='Ano'
          options={year}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0
          })}
        />
      </div>

      <InputLabel className={classes.label}>Código de Segurança</InputLabel>
      <div className={classes.selectContainer}>
        <Input
          placeholder='000'
          type='text'
          name='securityCode'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.securityCode}
          // className={classes.input2}
          customClasses={{ root: classes.input2 }}
          error={touched.securityCode ? errors.securityCode : null}
        />
        <PaymentIcon
          style={{ color: 'rgba(0, 0, 0, 0.65)', marginLeft: '19.33px' }}
          fontSize='large'
        />
      </div>
      {formErrors && (
        <FormHelperText className={classes.errorsText} error>
          {formErrors}
        </FormHelperText>
      )}

      <FormControlLabel
        style={{ position: 'relative', top: '15%' }}
        control={
          <StyledCheckbox onChange={handleChange} name='paymentSelected' />
        }
        label={
          <Typography variant='body2'>
            Usar como forma de pagamento padrão
          </Typography>
        }
      />

      <Button lowercase isLoading={isLoading} type='submit' width='100%'>
        Cadastrar cartão
      </Button>
    </form>
  );
};

export default AddCardForm;
