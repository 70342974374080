import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  rootLoading: {
    height: 'calc(100vh - 7rem)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  searchResultsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '2rem -1.5rem',
    '@media (max-width: 786px)': {
      justifyContent: 'center'
    },
  },
  courseCard: {
    width: '23%',
    padding: '1.5rem',
    boxSizing: 'border-box',
    '@media (max-width: 1400px)': {
      width: '33.33%'
    },
    '@media (max-width: 1025px)': {
      width: '50%'
    },
    '@media (max-width: 786px)': {
      width: '100%',
      maxWidth: '45rem'
    }
  },
  paginationContainer: {
    margin: '4rem 0'
  }
}));

export default createStyles;
