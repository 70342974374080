import { combineReducers } from 'redux';
import session from 'modules/Login/Login.ducks.js';
import vitrine from 'modules/Vitrine/Vitrine.ducks.js';
import search from 'modules/Search/Search.ducks.js';
import courseDetails from 'modules/CourseDetails/CourseDetails.ducks.js';
import category from 'modules/Category/Category.ducks.js';
import myCourses from 'modules/MyCourses/MyCourses.ducks.js';
import favorites from 'modules/Favorites/Favorites.ducks.js';
import cart from 'modules/Cart/Cart.ducks.js';
import purchaseHistory from 'modules/PurchaseHistory/PurchaseHistory.ducks.js';
import userRegistration from 'modules/Registration/Registration.ducks.js';
import adresses from 'modules/Adresses/Address.ducks.js';
import faq from 'modules/FAQ/FAQ.ducks.js'
import policy from 'modules/Politicas/Politicas.ducks.js'
import historyPayments from 'modules/MyPaymentHistory/MyPaymentHistory.ducks.js'

import courses from 'modules/Courses/Courses.ducks.js';

const reducers = combineReducers({
  session,
  vitrine,
  search,
  courseDetails,
  category,
  myCourses,
  favorites,
  cart, 
  purchaseHistory, 
  adresses,
  userRegistration,
  courses,
  faq,
  policy,
  historyPayments
});

export default reducers;
