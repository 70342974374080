export const getCEP = (cep) => {
  let address = {
    address: '',
    neighbourhood: '',
    city: '',
    state: '',
    error: false
  };
  return fetch(`https://viacep.com.br/ws/${cep}/json/`)
    .then((response) => response.json())
    .then((data) => {

      if (data.erro) {
        return Promise.reject("CEP inválido");
      }

      address.address = data.logradouro;
      address.neighbourhood = data.bairro;
      address.city = data.localidade;
      address.state = data.uf;
      address.ibge = data.ibge;
      address.error = false;

      return address;
    })
    .catch((err) => {
      return Promise.reject(err);
    })
};