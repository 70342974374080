import * as Yup from 'yup';

const addEditAddressForm = {
  cep: Yup.string().required('Este campo é obrigatório')
    .test('cep', 'Este campo é obrigatório', function(value) {
      // needed because placeholder `_____-___` counts as a non-empty string for required check above
      return value && value.replace(/_|-/g, '');
    })
};

export const addEditAddressFormValidator = Yup.object().shape(addEditAddressForm);
