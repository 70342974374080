import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
 bar: {
  background: '#32559D', 
  height: 68
 }, 
 title: {
   color: '#fff', 
   position: 'relative', 
   left: '133px', 
   top: '20px'
 }, 
 rootLoading: {
  height: 'calc(100vh - 7rem)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
},
 selectContainer: isMobile => ({
   display: 'flex', 
   flexDirection: 'column', 
   justifyContent: 'center', 
   alignItems: isMobile ? 'flex-start' : 'center',
   height: '120px',
   padding: isMobile ? '15px 0' : '30px 135px'
 }), 
 select: isMobile => ({
   width: isMobile ? '90%' : '100%',
 }), 
 courseContainer: isMobile => ({
   display: 'flex', 
   flexDirection: isMobile ? 'column' : 'row',
   flexWrap: 'wrap', 
   justifyContent: 'flex-start',
   alignItems: isMobile ? 'center' : 'flex-start',
   marginTop: '3rem'
 }), 
 item: isMobile => ({
   width: isMobile ? '90%' : '15%', 
   minWidth: '350px',
   marginBottom: '35px',
   '&:not(:last-child)':{
    marginRight: isMobile ? '0' : '10px',
 },
 }),
 paginationContainer: {
   margin: '4rem 0'
 },
 headerSelectSeach: {
  width: '30rem',
  marginTop: '1rem',
  marginRight: '2rem',
  '@media (max-width: 450px)': {
      width: '100%'
  }
 }
}));

export default createStyles;
