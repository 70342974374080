import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  dotIndicatorsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dotIndicator: {
    width: '.5rem',
    height: '.5rem',
    margin: '0 0.5rem',
    borderRadius: '30px',
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.background.bg45}`,
    transition: 'all 500ms ease-in-out',
  },
  active: {
    width: '1rem',
    height: '1rem',
  },
}));

export default createStyles;
