import ForgotPasswordModal from './ForgotPasswordModal.jsx';
import { connect } from 'react-redux';
import { handlePasswordRecover } from 'modules/Login/Login.ducks.js';

const mapStateToProps = state => {
  return {
    isRecovering: state.session.isRecovering,
    errors: state.session.errors,
    emailSent: state.session.emailSent
  };
};

const mapDispatchToProps = {
  handlePasswordRecover
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordModal);
