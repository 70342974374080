import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  paymentRoot: {
    display: 'flex',
    marginBottom: '3rem',
    marginTop: '2rem',
    '@media(max-width:1100px)': {
      flexDirection: 'column'
    }
  },
  orderInfoContainer: {
    flex: '1 1 0px',
    marginRight: '1.5rem',
    '@media(max-width:1100px)': {
      marginRight: '0'
    }
  },
  rootInnerLoading: {
    height: '399px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  subInfoCard: {
    padding: '1.5rem',
    boxShadow: '1px 2px 8px rgba(0, 0, 0, 0.2)',
    marginBottom: '1.5rem',
    '@media(max-width:1100px)': {
      marginBottom: '3rem'
    }
  },
  resumeInfoCard: {
    padding: '1.5rem',
    boxShadow: '1px 2px 8px rgba(0, 0, 0, 0.2)',
    '@media(max-width:1100px)': {
      marginRight: '0',
      marginBottom: '3rem'
    }
  },

  paymentInfoContainer: {
    flex: '1 1 0px',
    padding: '1.5rem',
    boxShadow: '1px 2px 8px rgba(0, 0, 0, 0.2)',
    marginLeft: '1.5rem',
    '@media(max-width:1100px)': {
      marginLeft: '0'
    }
  },
  titleAction: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    '@media(max-width:400px)': {
      marginBottom: '3rem'
    }
  },
  sectionSeparator: {
    marginBottom: '2rem'
  },
  activeTab: {
    backgroundColor: theme.colors.blueCompany,
    color: theme.colors.white
  },
  nonActiveTab: {
    backgroundColor: 'white',
    color: theme.colors.text.primary,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderColor: 'rgb(34, 73, 141)'
  },
  paymentOptionsTabs: {
    display: 'flex',
    listStyle: 'none',
    justifyContent: 'flex-start',
    margin: '2.5rem 0 0 0',
    padding: 0,
    '& li': {
      padding: '1.5rem 2.5rem',
      cursor: 'pointer',
      marginRight: '10px'
    }
  },
  paymentError: {
    height: 'auto',
  }
}));

export default createStyles;
