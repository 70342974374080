import { getCourses } from 'api/Courses';
import { getCategories } from 'api/Category';

// Actions
const FETCH_COURSES_START = 'sesi/login/FETCH_COURSES_START';
const FETCH_COURSES_SUCCESS = 'sesi/login/FETCH_COURSES_SUCCESS';
const FETCH_COURSES_FAILURE = 'sesi/login/FETCH_COURSES_FAILURE';
const COURSES_RESET = 'sesi/login/COURSES_RESET';

const FETCH_CATEGORIES_START = 'sesi/login/FETCH_CATEGORIES_START';
const FETCH_CATEGORIES_SUCCESS = 'sesi/login/FETCH_CATEGORIES_SUCCESS';
const FETCH_CATEGORIES_FAILURE = 'sesi/login/FETCH_CATEGORIES_FAILURE';
const CATEGORIES_RESET = 'sesi/login/CATEGORIES_RESET';

// Reducer
const initialState = {
  courses: [],
  categories: [],
  totalSearchResults: 0,
  isLoading: false,
  categoriesInCourses: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_COURSES_START:
      return { ...state, isLoading: true };
    case FETCH_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.payload,
        isLoading: false
      };
    case FETCH_COURSES_FAILURE:
      return { ...state, ...action.payload, isLoading: false };
    case COURSES_RESET:
      return { ...state, ...initialState };
    case FETCH_CATEGORIES_START:
    case FETCH_CATEGORIES_FAILURE:
      return { ...state, ...action.payload, isLoading: false };
    case CATEGORIES_RESET:
      return { ...state, ...initialState };
    default:
      return state;
  }
}

// Action Creators
export function fetchCoursesStart() {
  return { type: FETCH_COURSES_START };
}

export function fetchCoursesSuccess(data) {
  return { type: FETCH_COURSES_SUCCESS, payload: data };
}

export function fetchCoursesFailure(data) {
  return { type: FETCH_COURSES_FAILURE, payload: data };
}

export function reset() {
  return { type: COURSES_RESET };
}

export function fetchCategoriesStart() {
  return { type: FETCH_CATEGORIES_START };
}

export function fetchCategoriesSuccess(data) {
  return { type: FETCH_CATEGORIES_SUCCESS, payload: data };
}

export function fetchCategoriesFailure(data) {
  return { type: FETCH_CATEGORIES_FAILURE, payload: data };
}

export function resetCategories() {
  return { type: CATEGORIES_RESET };
}

// side effects, only as applicable
export const fetchCourses = (id, currentPage, itensPerPage, filters) => {
  return async (dispatch) => {
    dispatch(fetchCoursesStart());

    try {
      
      const response = await getCourses(id, currentPage, itensPerPage, filters);
      dispatch(fetchCoursesSuccess(response));
    } catch (error) {
      dispatch(fetchCoursesFailure(error));
    }
  };
};

export const fetchCategories = () => {
  return async (dispatch) => {
    dispatch(fetchCategoriesStart());

    try {
      const response = await getCategories();
      dispatch(fetchCategoriesSuccess(response));
    } catch (error) {
      dispatch(fetchCategoriesFailure(error));
    }
  };
};
