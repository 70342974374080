import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from 'components/ButtonPrimary';
import { Typography, Checkbox, InputLabel } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FormHelperText } from '@material-ui/core';
import Select, { components } from 'react-select';
import {
  creditCardYears,
  creditCardMonths,
  installmentOptions,
  getInstallments
} from 'shared/util/CreditCard.js';
import PaymentIcon from '@material-ui/icons/Payment';
import ButtonPrimary from 'components/ButtonPrimary';
import { getCartItems } from 'shared/util/LocalStorage';

import createStyles from './CreditCardForm.styles';


const CreditCardForm = (props) => {
  const classes = createStyles();
  const {
    errors,
    touched,
    values,
    isSubmitting,
    isLoading,
    externalErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
    dirty,
    products,
    className,
  } = props;

  const [isValids, setIsValids] = useState(false);
  const [valid, setValid] = useState(false);
  const [validContract, setValidContract] = useState(false);

  const [installments, setInstallments] = useState(1);

  useEffect(() => {
    let minInstallments = 12;

    products.forEach(product => {
      if (product.product.custom_attributes.filter(item => item.attribute_code === "parcels")[0].value < minInstallments) {
        minInstallments = product.product.custom_attributes.filter(item => item.attribute_code === "parcels")[0].value;
      }
    });

    setInstallments(minInstallments);
  }, []);

  const handleAgreementChange = (event) => {
    setValid(event.target.checked);
  }
  const handleValidContract = (event) => {
    setValidContract(event.target.checked);
  }

  useEffect(() => {
    if(valid && validContract){
      setIsValids(true);
    }else{
      setIsValids(false);
    }
  },[valid, validContract])

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Input
        name='cardNumber'
        label='Número do cartão'
        mask={'9999.9999.9999.9999'}
        maskPlaceholder={''}
        type='text'
        gutterBottom
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.cardNumber}
      // error={touched.cardNumber ? errors.cardNumber : null}
      />
      <Input
        name='personName'
        label='Nome do titular (como está escrito no cartão)'
        type='text'
        gutterBottom
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.personName}
      // error={touched.personName ? errors.personName : null}
      />

      <InputLabel className={classes.label}>Data de validade</InputLabel>
      <div className={classes.selectContainer}>
        <Input
          id='cardMonth'
          name='cardMonth'
          type='text'
          customClasses={{ root: classes.select }}
          placeholder='Mês'
          onChange={(option) => {
            setFieldValue('cardMonth', option.value);
            setFieldTouched('cardMonth', true);
          }}
          onBlur={(option) => {
            setFieldTouched('cardMonth', true);
          }}
          // error={touched.cardMonth ? errors.cardMonth : null}
          gutterBottom
          select
          selectOptions={creditCardMonths}
          value={creditCardMonths.value}
        />

        <Input
          id='cardYear'
          name='cardYear'
          type='text'
          customClasses={{ root: classes.select }}
          placeholder='Ano'
          onChange={(option) => {
            setFieldValue('cardYear', option.value);
            setFieldTouched('cardYear', true);
          }}
          onBlur={(option) => {
            setFieldTouched('cardYear', true);
          }}
          // error={touched.cardYear ? errors.cardYear : null}
          gutterBottom
          select
          selectOptions={creditCardYears()}
          value={creditCardYears.value}
        />
      </div>

      <InputLabel className={classes.label}>Código de Segurança</InputLabel>
      <div className={classes.securityCodeContainer}>
        <Input
          name='securityCode'
          placeholder='000'
          mask={'999'}
          maskPlaceholder={''}
          type='text'
          gutterBottom
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.securityCode}
        // error={touched.securityCode ? errors.securityCode : null}
        />
        <PaymentIcon
          style={{ color: 'rgba(0, 0, 0, 0.65)', marginLeft: '1rem' }}
          fontSize='large'
        />
      </div>
      <Input
        name='installmentOptions'
        label='Opções de parcelamento'
        customClasses={{ root: classes.installmentOptionsInput }}
        type='text'
        value={installmentOptions.value}
        select
        selectOptions={getInstallments(installments)}
        gutterBottom
        onChange={(option) => {
          setFieldValue('installmentOptions', option.value);
          setFieldTouched('installmentOptions', true);
        }}
        onBlur={(option) => {
          setFieldTouched('installmentOptions', true);
        }}
        defaultValue={installmentOptions[0]}
      // error={touched.installmentOptions ? errors.installmentOptions : null}
      />

      <div className={classes.contract}>
        <Checkbox
          size='medium'
          color='primary'
          name='useTerms'
          onChange={handleValidContract}
          onBlur={handleBlur}
          value={values.useTerms}
        />
        <Typography
          className={classes.checkboxLabel}
          variant='body2'
          color='textPrimary'
        >
          Li e aceito os termos do <a href="/files/Contrato_de_Prestacao_de_Servicos_Educacionai_2024.pdf" target="_blank">contrato</a>
        </Typography>
      </div>

      <div className={classes.actions_agreement}>
        <Checkbox
          size='medium'
          color='primary'
          name='agreement'
          onChange={handleAgreementChange}
          onBlur={handleBlur}
          value={values.agreement}
        />
        <Typography
          className={classes.checkboxLabel}
          variant='body2'
          color='textPrimary'>
          Estou ciente que a efetivação da matrícula ocorrerá após a quitação da primeira 
          parcela, entrega da documentação de pré requisito de entrada no curso 
          pelo Portal do Aluno e assinatura eletrônica do contrato:
          <ul>
            <li>Entregar documentação exigida (RG, CNH ou Carteira de Trabalho - páginas 
              correspondentes a identificação, CPF; Comprovante de residência atualizado 
              (contas com CEP recebidas nos últimos 3 meses preferencialmente entregue pelos 
              Correios), Comprovante de escolaridade ou auto declaração de compatibilidade, e 
              comprovantes de pré requisito para ingresso no curso).
              <ol>
                <li>Link do Portal <a href="https://senaies.com.br/portal-do-aluno/" target="_blank">https://senaies.com.br/portal-do-aluno</a>.</li>
                <li>Login: Nº do seu CPF sem pontos nem traços;</li>
                <li>Senha: data de nascimento informada no cadastro sem as barras (/).</li>
              </ol>
            </li>
            <li>Assinar a Ficha de Matrícula eletronicamente.
              <ol>
                <li>Documento enviado por e-mail pela <span style={{ textDecoration: 'underline' }}>plataforma <strong>TOTVS Sign</strong></span>, 
                após conferência da documentação enviada. É necessário acessar o link e assinar o contrato conforme instruções 
                fornecidas pela plataforma.</li>
              </ol>
            </li>
          </ul>
        </Typography>
      </div>

      <div className={classes.actions_button}>
        <ButtonPrimary type='submit' disabled={!isValids} lowercase wrapText isLoading={isLoading}>
          Pagar com cartão de crédito
        </ButtonPrimary>
      </div>

      {externalErrors && (
        <FormHelperText className={classes.errorsText} error>
          {externalErrors}
        </FormHelperText>
      )}
    </form>
  );
};

export default CreditCardForm;
