import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.white,
    boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'all 150ms ease-in-out',
    overflow: 'hidden',
    borderRadius: '5px'
  },

  imageContainer: (props) => ({
    display: 'flex',
    alignItems: 'flex-end',
    height: '25rem',
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, .0), rgba(0, 0, 0, .0) 80%, rgba(0, 0, 0, .5), rgba(0, 0, 0, 1)) , url(${props.course.image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top'
  }),

  galleryOverlay: {
    position: 'relative',
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, .0), rgba(0, 0, 0, .0) 0%, rgba(0, 0, 0, .8))`,
    height: '25rem',
    width: '100%',
    opacity: 0,
    transition: 'all 100ms ease-in-out',

    '&:hover': {
      opacity: 1
    }
  },
  galleryLabel: {
    color: theme.colors.white,
    position: 'absolute',
    bottom: '.5rem',
    left: '50%',
    transform: 'translateX(-50%)',
    userSelect: 'none'
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)'
  },
  areaTagContainer: {
    position: 'absolute',
    left: '0',
    top: '0',
    backgroundColor: theme.colors.yellowTag,
    margin: '0',
    padding: '1rem',
    zIndex: '1',
    display: 'flex',
    alignItems: 'center',
    cursor: 'default'
  },
  areaTagText: {
    display: 'block',
    textDecoration: 'none',
    fontSize: '1.6rem',
    fontWeight: '700',
    color: theme.colors.text.dark,
    margin: '0'
  },
  tagDiscount: {
    position: 'absolute',
    backgroundColor: theme.colors.blueLight,
    margin: '0',
    padding: '1rem',
    zIndex: '1',
    display: 'flex',
    alignItems: 'center',
    cursor: 'default',
    bottom: 0,
    right: 0,
    '& p': {
      color: theme.colors.white
    }
  },
  infoContainer: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: 'calc(100% - 4rem)',
    padding: '1.6rem 2rem'
  },
  classSelector: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'calc(100% + 1rem)',
    flexWrap: 'wrap',
    margin: '0 -.5rem',
    marginBottom: '2rem'
  },
  lowerInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },

  detailsList: {
    margin: '0',
    listStyle: 'none',
    padding: '0',
    marginBottom: '1rem',
    width: '100%',
    color: theme.palette.text.secondary
  },

  detail: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.5rem',

    '& h6': {
      fontSize: '1.6rem',
      margin: '0',
      display: 'inline-block',
      marginLeft: '.6rem'
    }
  },
  detailInfo: {
    color: theme.colors.text.dark
  },
  priceContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  originalPrice: {
    textAlign: 'center',
    textDecoration: 'line-through'
  },
  price: {
    color: theme.colors.blueCompany,
    fontSize: '3rem',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  paymentConditions: {
    marginBottom: '2rem',
    textAlign: 'center'
  },

  noClassesAvailable: {
    textAlign: 'center',
    width: '100%',
    padding: '3rem 0'
  },
  favoriteButton: {
    marginTop: '1rem'
  },
  favoriteLabel: {
    textTransform: 'none',
    fontSize: '1.4rem'
  },
  favoriteIcon: {
    color: theme.colors.text.primary
  }
}));

export default createStyles;
