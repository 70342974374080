import React, { useState } from 'react';
import { Formik } from 'formik';
import AddEditAddressForm from './AddEditAddressForm';
import { addEditAddressFormValidator } from './AddEditAddressForm.validator';

const AddEditAddressFormWrapper = props => {

  const {
    isLoading, 
    handleSave, 
    handleCloseModal, 
    formData, 
    isEdit
  } = props;

  const initialValues = {
    name: formData ? formData.name : '', 
    neighbourhood: formData ? formData.neighbourhood : '', 
    cep: formData ? formData.cep : '', 
    address: formData ? formData.street : '', 
    state: formData ? formData.state : '', 
    number: formData ? formData.number : '', 
    city: formData ? formData.city : '', 
    complement:  formData ? formData.complement : '', 
    deliveryAddress:  formData ? formData.deliveryAddress : true,  
    chargeAddress:  formData ? formData.chargeAddress : false,
    buttonTitle: props.buttonTitle
  };

  const handleSubmit = async (formValues, actions) => {
    try {
      await handleSave(formValues);
      handleCloseModal();
    } catch (err) {
      //TODO
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={addEditAddressFormValidator}
    >
      {propsFormik => (
        <AddEditAddressForm
          {...propsFormik}
          isLoading={isLoading}
          isEdit={isEdit}
        />
      )}
    </Formik>
  );
};

export default AddEditAddressFormWrapper;
