import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  authContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '2rem 0 10rem 0'
  }
}));

export default createStyles;
