export const creditCardMonths = [
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' }
];

export const creditCardYears = () => {
  let years = [];

  for (let i = 0; i < 20; i++) {
    const year = new Date().getFullYear() + i;
    years.push({ value: year.toString(), label: year.toString() });
  }

  return years;
};

export const installmentOptions = [
  { value: '1', label: '1x sem juros' },
  { value: '2', label: '2x sem juros' },
  { value: '3', label: '3x sem juros' },
  { value: '4', label: '4x sem juros' },
  { value: '5', label: '5x sem juros' },
  { value: '6', label: '6x sem juros' },
  { value: '7', label: '7x sem juros' },
  { value: '8', label: '8x sem juros' },
  { value: '9', label: '9x sem juros' },
  { value: '10', label: '10x sem juros' },
  { value: '11', label: '11x sem juros' },
  { value: '12', label: '12x sem juros' }
];

export const getInstallments = (installments) => {
  if(installments == 1){
    return [
      { value: '1', label: '1x sem juros' }
    ]
  }
  return  installmentOptions.filter(op=> parseInt(op.value) <= installments);
};
