import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  legend: {
  marginTop: '1.1rem',
  marginBottom: '2.1rem',
  },
  input: {
    marginBottom: '1.6rem',
    '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': { 
      WebkitAppearance: 'none',
      margin: 0,
        
    }
  },
  container: {
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center'
  },
  form: isMobile => ({
    marginTop: '2.4rem', 
    display: 'flex', 
    flexDirection: isMobile ? 'column' : 'row', 
    // alignItems: 'center', 
    justifyContent: 'space-evenly', 
  }),
  formColumn:isMobile => ({
    width: '35rem',
    '&:not(:last-child)': {
      marginRight: isMobile ?'0' : '2rem',
    }
  }),
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.colors.background.bg30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',

  }
}));

export default createStyles;
