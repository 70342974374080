import React, { useEffect, useState, useRef } from 'react';
import { Typography } from '@material-ui/core';
import AddressCard from 'modules/Adresses/AddressCard';
import createStyles from './Address.styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';


const Address = props => {
  const isMobile = useMediaQuery('(max-width:814px)');

  const classes = createStyles(isMobile);

  const { fetchAddress, handleDeleteAddress } = props;

  const {
    adresses,
    isLoading } = props;


  useEffect(() => {
    fetchAddress();
  }, []);


  const handleDelete = (id) => {
    handleDeleteAddress(id);
  }


  return (
    <React.Fragment>
          {isLoading ? (
            <div className={classes.rootLoading}>
              <CircularProgress />
          </div>

         ) : ( 
          <div className={classes.container}>
            <Typography variant='h3' className={classes.title}>
              Endereços
            </Typography>

            <div className={classes.cardContainer}>
              { 
                adresses.map(address => 
                  <AddressCard data={address} delete={() => handleDelete(address.id)}/>
                )
              }
              <AddressCard />
            </div>
          </div>
         )}
    </React.Fragment >
  );
};

export default Address;
