import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import LoginForm from './Form/LoginBoxForm.formik';
import createStyles from './LoginBox.styles';
import ForgotPasswordModal from './ForgotPasswordModal';

const LoginBox = (props) => {
  const classes = createStyles();
  const {
    login,
    isLoading,
    handleClose,
    externalErrors,
    handleRegisterClick,
    afterLogin,
    redirectAfterLogin,
  } = props;
  const [isOpen, setOpen] = React.useState(false);

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <Card
      onClick={(e) => e.stopPropagation()}
      elevation={props.elevation || 2}
      className={`${classes.root} ${props.className}`}
    >
      <CardContent className={classes.cardContent}>
        <Typography
          variant='subtitle1'
          align='center'
          className={classes.title}
        >
          Login do Cliente
        </Typography>

        <LoginForm
          login={login}
          isLoading={isLoading}
          errors={externalErrors}
          handleClose={handleClose}
          afterLogin={afterLogin}
          redirectAfterLogin={redirectAfterLogin}
          handleOpenModal={setOpen}
        />

        <Typography align='center' className={classes.registerCall}>
          Não tem cadastro?
          <Link
            className={classes.registerLink}
            onClick={handleRegisterClick}
            style={{cursor: "pointer"}}
            // onClick={handleCloseModal}
            // href='/cadastro'
          >
            Cadastre-se
          </Link>
        </Typography>
        <ForgotPasswordModal open={isOpen} handleClose={handleCloseModal} />
      </CardContent>
    </Card>
  );
};

LoginBox.propTypes = {
  endIcon: PropTypes.shape({
    icon: PropTypes.element,
    className: PropTypes.string
  }),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  handleClose: PropTypes.func,
  handleRegisterClick: PropTypes.func,
  login: PropTypes.func,
  afterLogin: PropTypes.func
};

export default LoginBox;
