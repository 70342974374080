import { getQuestions } from 'api/FAQ';

// Actions
const FETCH_QUESTIONS_START = 'sesi/faq/FETCH_QUESTIONS_START';
const FETCH_QUESTIONS_SUCCESS = 'sesi/faq/FETCH_QUESTIONS_SUCCESS';
const FETCH_QUESTIONS_FAILURE = 'sesi/faq/FETCH_QUESTIONS_FAILURE';
const QUESTIONS_RESET = 'sesi/faq/QUESTIONS_RESET';

// Reducer
const initialState = {
  faq: [],
  isLoading: false

};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_QUESTIONS_START:
      return { ...state, isLoading: true };
    case FETCH_QUESTIONS_SUCCESS:
      return {
        ...state,
        faq: action.payload.data || [],
        isLoading: false
      };
    case FETCH_QUESTIONS_FAILURE:
        // TODO DÉBITO TÉCNICO: mudar para empty state
      return { ...state, ...action.payload, isLoading: false };
    case QUESTIONS_RESET:
      return { ...state, ...initialState };
    default:
      return state;
  }
}

// Action Creators
export function fetchQuestionsStart() {
  return { type: FETCH_QUESTIONS_START };
}

export function fetchQuestionsSuccess(data) {
  return { type: FETCH_QUESTIONS_SUCCESS, payload: data };
}

export function fetchQuestionsFailure(data) {
  return { type: FETCH_QUESTIONS_FAILURE, payload: data };
}

export function reset() {
  return { type: QUESTIONS_RESET };
}

// side effects, only as applicable
export const fetchQuestions = () => {
  return async (dispatch) => {
    dispatch(fetchQuestionsStart());
    try {
      const response = await getQuestions();
      dispatch(fetchQuestionsSuccess(response));
    } catch (error) {
      dispatch(fetchQuestionsFailure(error));
    }
  };
};
