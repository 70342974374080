import React from 'react';
import createStyles from './CourseDetailsContainer.styles';

const CourseDetailsContainer = props => {
  const classes = createStyles(props);

  return <div className={classes.root + " " + (props.className && props.className)} ref={props.descriptionRef}>{props.children}</div>;
};

CourseDetailsContainer.propTypes = {};

export default CourseDetailsContainer;
