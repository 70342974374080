import * as Yup from 'yup';
import { validateCNPJ } from 'shared/util/ValidateCNPJ.js';
import moment from 'moment';
import 'moment/locale/pt-br'

const dateRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/

const addEditAddressForm = {
  cep: Yup.string().required('Este campo é obrigatório')
    .test('cep', 'Este campo é obrigatório', function(value) {
      // needed because placeholder `_____-___` counts as a non-empty string for required check above
      return value && value.replace(/_|-/g, '');
    }),
  state: Yup.string().required('Este campo é obrigatório'),
  city: Yup.string().required('Este campo é obrigatório'),
  neighbourhood: Yup.string().required('Este campo é obrigatório'),
  address: Yup.string().required('Este campo é obrigatório'),
  number: Yup.string().required('Este campo é obrigatório'),
  name: Yup.string().required('Este campo é obrigatório'),
  firstName: Yup.string().required('Este campo é obrigatório'),
  lastName: Yup.string().required('Este campo é obrigatório'),
  cpfCnpj: Yup.string()
  .required('Este campo é obrigatório')
  .test('cpfCnpj', 'CNPJ inválido', function (value) {
    return (value !== undefined ? validateCNPJ(value) : '');
  }),
  email: Yup.string()
  .required('Este campo é obrigatório')
  .email('Email inválido'),
  phone: Yup.string().test('phone', 'Este campo é obrigatório', function(value) {
    return (value !== undefined ? value.replace(/_|-|\(|\)|\s/g, '') : '').length >= 10;
  }),
  birthDate: Yup.string().required('Este campo é obrigatório')
    .test('birthDate', 'Data de nascimento inválida', function(value) {
      return value && value.match(dateRegex) &&
          moment(value, "DD/MM/YYYY").isBefore(moment(new Date, "DD/MM/YYYY")) && 
          moment(value, "DD/MM/YYYY").isAfter(moment('01/01/1900', "DD/MM/YYYY"));
  }),
};

export const addEditAddressFormValidator = Yup.object().shape(addEditAddressForm);
