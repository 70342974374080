import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  input: {
    borderColor: theme.colors.background.bg45,
    height: '50px'
  },
  form: {
    // marginTop: '2.4rem',
    padding: '2rem'
  },
  errorsText: {
    margin: '-1rem 0 2rem 0'
  },
  formWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media(max-width:550px)': {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  voucherBtn: {
    height: '50px'
  },
  checkboxLabel: {
    height: 'auto',
  },
  inputWrapper: {
    flexGrow: 1,
    marginRight: '1rem',
    '@media(max-width:550px)': {
      width: '100%',
      marginBottom: '1rem'
    }
  },
  buttonWrapper: {
    flexBasis: '35%',
    paddingLeft: '1rem',
    boxSizing: 'border-box',
    '@media(max-width:550px)': {
      paddingLeft: '0'
    }
  },
  selectContainer: {
    display: 'flex',
    marginTop: '0.5rem',
    width: '100%'
  },
  select: {
    width: '20rem',
    '&:first-child': {
      marginRight: '1rem'
    },
    '@media(max-width:590px)': {
      width: '100%'
    }
  },
  label: {
    color: (props) =>
      props.error ? theme.colors.alerts.error : theme.colors.text.primary,
    marginBottom: '0.5rem',
    fontSize: '1.4rem',
    width: '100%'
  },
  securityCodeContainer: {
    display: 'flex',
    width: '30rem',
    '@media(max-width:450px)': {
      width: '100%'
    }
  },
  installmentOptionsInput: {
    width: '41rem',
    '@media(max-width:590px)': {
      width: '100%'
    }
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
    marginBottom: '2rem'
  },
  actions_agreement: {
    display: 'flex',
    marginBottom: '2rem',
    alignItems: 'flex-start'
  },
  contract: {
    display: 'flex',
    marginBottom: '2rem',
    alignItems: 'center'
  },
  actions_button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2rem'
  }
}));

export default createStyles;
