import React, { useState } from 'react';
import createStyles from './EditAddress.styles';
import Modal from '@material-ui/core/Modal';
// import EditProfileForm from './EditProfileForm';
import EditAddressForm from './EditAddressForm';
import ClearIcon from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ButtonPrimary from 'components/ButtonPrimary';
import { Typography } from '@material-ui/core';

const EditAddress = (props) => {
  const classes = createStyles(props);
  const { open, handleClose, user } = props;
  const [isFormOpen, setIsFormOpen] = useState(false);

  if (!user || !user.addresses) return null;

  const chargeAddress = user.addresses.chargeAddress;
  const deliveryAddress = user.addresses.deliveryAddress;

  const handleOpenAddressEdit = () => {
    setIsFormOpen(true);
  };

  return (
    <div className={classes.editAddressRoot}>
      {isFormOpen ? (
        <EditAddressForm />
      ) : (
        <>
          <div className={classes.addressCard}>
            <div className={classes.addressCardHeader}>
              <div>
                <Typography variant='subtitle1'>
                  {chargeAddress.title}
                </Typography>
                <Typography>endereço de cobrança</Typography>
              </div>
              <ButtonPrimary
                lowercase
                className={classes.alterBtn}
                variant='outlined'
                onClick={handleOpenAddressEdit}
              >
                Alterar
              </ButtonPrimary>
            </div>
            <div className={classes.addressCardBody}>
              <Typography>{chargeAddress.address}</Typography>
              <Typography>{chargeAddress.complement}</Typography>
              <Typography>{chargeAddress.neighborhood}</Typography>
              <Typography>{`${chargeAddress.city} - ${chargeAddress.state}`}</Typography>
              <Typography>{`CEP: ${chargeAddress.cep}`}</Typography>
            </div>
          </div>

          <div className={classes.addressCard}>
            <div className={classes.addressCardHeader}>
              <div>
                <Typography variant='subtitle1'>
                  {deliveryAddress.title}
                </Typography>
                <Typography>endereço de cobrança</Typography>
              </div>
              <ButtonPrimary
                lowercase
                className={classes.alterBtn}
                variant='outlined'
                onClick={handleOpenAddressEdit}
              >
                Alterar
              </ButtonPrimary>
            </div>
            <div className={classes.addressCardBody}>
              <Typography>{deliveryAddress.address}</Typography>
              <Typography>{deliveryAddress.complement}</Typography>
              <Typography>{deliveryAddress.neighborhood}</Typography>
              <Typography>{`${deliveryAddress.city} - ${deliveryAddress.state}`}</Typography>
              <Typography>{`CEP: ${deliveryAddress.cep}`}</Typography>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EditAddress;
