import React from 'react';
import RegisterBoxPF from 'components/AuthenticationBox/RegisterBoxPF';
import RegisterBoxPJ from 'components/AuthenticationBox/RegisterBoxPJ';
import createStyles from './Register.styles';

const Register = (props) => {
  const classes = createStyles(props);
  const { history } = props;
  // if (history.location.state.cpfCNPJ.length === 14) {
    return (
      <div className={classes.rootRegister}>
        <RegisterBoxPF className={classes.registerBox} history={history} />
      </div>
    );
  // } else {
  //   return (
  //     <div className={classes.rootRegister}>
  //       <RegisterBoxPJ className={classes.registerBox} history={history} />
  //     </div>
  //   );
  // }

};

export default Register;
