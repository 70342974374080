import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from 'components/ButtonPrimary';
import { Typography, Checkbox } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FormHelperText } from '@material-ui/core';
import ButtonPrimary from 'components/ButtonPrimary';

import createStyles from './VoucherForm.styles';

const VoucherForm = (props) => {
  const classes = createStyles(props);
  const {
    errors,
    touched,
    values,
    isSubmitting,
    isLoading,
    externalErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    className
  } = props;

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <div className={classes.formWrapper}>
        <div className={classes.inputWrapper}>
          <Input
            name='voucherCode'
            type='text'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.voucherCode}
            // className={classes.input}
            customClasses={{ input: classes.input }}
            error={touched.voucherCode ? errors.voucherCode : null}
          />
        </div>
        <div className={classes.voucherBtn}>
          <ButtonPrimary
            lowercase
            isLoading={isLoading}
            type='submit'
            buttonText='Aplicar cupom'
          />
        </div>
      </div>

      {externalErrors && (
        <FormHelperText className={classes.errorsText} error>
          {externalErrors}
        </FormHelperText>
      )}
    </form>
  );
};

export default VoucherForm;
