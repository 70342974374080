import request from 'shared/request';
// import BadRequestError from 'errors/BadRequestError';
// import { getCoursesMock } from 'mock/Courses';

export const getCourses = async (id, page, size, filters) => {
  try {
    const response = await request.post('/products/', {
      
        page, size, 
        params : {
          cidade: [{id}],
          mostrar:[],
          turno:[],
          categoria: [],
          duracao: []
        }
      
    });
    return response.data;

    // return new Promise(resolve =>
    //   setTimeout(function() {
    //     const result = getCoursesMock();

    //     resolve(result);
    //   }, 1000)
    // );
  } catch (error) {
    //TODO
  }
};
