import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  button: {
    boxShadow: 'none',
    backgroundColor: theme.colors.white,
    borderRadius: '.3rem .3rem 0 0',
    color: theme.palette.text.primary,
    fontSize: '1.5rem',
    transition: 'all 150ms ease-in-out',
    padding: '1rem 2rem',
    width: props => props.width,
    textTransform: props => (props.lowercase ? 'none' : 'uppercase'),
    transition: 'all 150ms ease-in-out',
    '&:hover': {
      boxShadow: 'none',
      color: theme.colors.blueCompany,
      backgroundColor: theme.colors.white,

      '&:after': {
        backgroundColor: theme.colors.blueCompany,
      }
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      display: 'block',
      height: '.1rem',
      width: '100%',
      backgroundColor: theme.colors.dividerBlue,

    }
  },

  active:{
    backgroundColor: theme.colors.blueCompany,
    color: theme.colors.white,

    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.colors.blueCompany,
      color: theme.colors.white,

      '&:after': {
        backgroundColor: theme.colors.blueCompany,
      }
    },
    '&:after': {
      backgroundColor: theme.colors.blueCompany,
    }
  },
  spinner: {
    color: theme.colors.white
  }
}));

export default createStyles;
