import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from 'components/ButtonPrimary';
import { Link } from 'react-router-dom';
import { FormHelperText, Typography } from '@material-ui/core';

import createStyles from './ChangePasswordForm.styles';

const ChangePasswordForm = props => {
  const classes = createStyles();
  const {
    errors,
    touched,
    values,
    isSubmitting,
    isLoading,
    // externalErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    className,
    formErrors,
    handleOpenRecoverPass
  } = props;

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const visibilityIcon = isPasswordVisible ? <Visibility /> : <VisibilityOff />;

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Input
        placeholder='*******'
        label='Nova senha'
        type={isPasswordVisible ? 'text' : 'password'}
        name='newPassword'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.newPassword}
        // className={classes.input}
        customClasses={{ root: classes.input }}
        helperText="A senha deve conter no mínimo oito caracteres alfanuméricos."
        error={touched.newPassword ? errors.newPassword : null}
        endIcon={{
          onClick: () => setIsPasswordVisible(!isPasswordVisible),
          icon: visibilityIcon,
          className: classes.emailIcon
        }}
      />
      <Typography className={classes.warning} variant='caption'>
        A senha deve conter no mínimo oito caracteres alfanuméricos.
      </Typography>
      <Input
        placeholder='*******'
        label='Repita a nova senha'
        type={isPasswordVisible ? 'text' : 'password'}
        name='passwordConfirmation'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.passwordConfirmation}
        error={touched.passwordConfirmation ? errors.passwordConfirmation : null}
        // className={classes.input}
        customClasses={{ root: classes.input }}
        helperText="A senha deve ser exatamente igual à senha acima."
        endIcon={{
          onClick: () => setIsPasswordVisible(!isPasswordVisible),
          icon: visibilityIcon,
          className: classes.emailIcon
        }}
      />
      <Typography className={classes.warning} variant='caption'>
        A senha deve ser exatamente igual à senha acima.
      </Typography>
      <Input
        placeholder='*******'
        label='Confirme com a senha antiga'
        type={isPasswordVisible ? 'text' : 'password'}
        name='oldPassword'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.oldPassword}
        // className={classes.input}
        customClasses={{ root: classes.input }}
        error={touched.oldPassword ? errors.oldPassword : null}
        endIcon={{
          onClick: () => setIsPasswordVisible(!isPasswordVisible),
          icon: visibilityIcon,
          className: classes.emailIcon
        }}
      />
      <div className={classes.actions}>
        <Link onClick={handleOpenRecoverPass} className={classes.forgotPasswordLabel}>
          Esqueceu a senha?
        </Link>
      </div>
      {formErrors && (
        <FormHelperText className={classes.errorsText} error>
          {formErrors}
        </FormHelperText>
      )}
      <Button lowercase isLoading={isLoading} type='submit' width='100%'>
        Enviar
      </Button>
    </form>
  );
};

export default ChangePasswordForm;
