import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  root: ({ props }) => ({
    // transition: 'all 300ms ease-in-out',
    visibility: props.isOpen ? 'visible' : 'hidden',
    position: 'fixed',
    top: '10rem',
    left: '0',
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    zIndex: '100',
    overflow: 'hidden',
    paddingBottom: '1rem'
  }),
  filterBox: ({ props }) => ({
    transform: props.isOpen ? '' : 'translateY(-5rem)',
    opacity: props.isOpen ? '1' : '0',
    backgroundColor: theme.colors.white,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    padding: '2rem',
    boxShadow:
      props.variant === 'formBased' ? 'none' : '1px 2px 8px rgba(0, 0, 0, 0.2)',
    transition: 'all 400ms ease-in-out'
  }),
  container: ({ props }) => ({
    transform: props.isOpen ? '' : 'translateY(-5rem)',
    opacity: props.isOpen ? '1' : '0',
    backgroundColor: theme.colors.white,
    display: 'flex',
    width: '90%',
    flexDirection: 'column',
    maxWidth: '144rem',
    boxShadow:
      props.variant === 'formBased' ? '1px 2px 8px rgba(0, 0, 0, 0.2)' : 'none',
    transition: 'all 400ms ease-in-out'
  }),
  buttonSubmit: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '2rem 3rem'
  },
  filter: {
    flex: '1',
    margin: '0 1rem',
    padding: '1rem 0',
    borderTop: '1px solid',
    borderColor: theme.colors.divider,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
    // width: '16.5%',
  },

  filterLabel: {
    fontSize: '2rem',
    color: theme.colors.textDark
  },
  // option:{
  //     // display: 'block',

  // },
  optionLabel: {
    fontSize: '1.6rem',
    padding: '1rem 0',
    color: theme.palette.text.primary
  }
}));

export default createStyles;
