import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import theme from 'App.theme.js';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CustomStepIcon from '../CustomStepIcon';
import { StepConnector, Typography } from '@material-ui/core';

import createStyles from './CartHeader.styles';

const CartHeader = (props) => {
  const { isLoading, activeStep, steps } = props;
  const classes = createStyles(props);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Stepper
        activeStep={activeStep}
        connector={<StepConnector className={classes.stepConnectorRoot} />}
        alternativeLabel
      >
        {steps.map((step, key) =>
          step.hidden ? null : (
            <Step key={step.label}>
              <StepLabel
                StepIconComponent={() => (
                  <CustomStepIcon
                    step={key}
                    active={activeStep === key}
                    completed={key < activeStep}
                  />
                )}
              >
                {!isMobile && step.label}
              </StepLabel>
            </Step>
          )
        )}
      </Stepper>

      {isMobile && (
        <Typography variant='h3' align='center' color='textPrimary'>
          {steps[activeStep].label}
        </Typography>
      )}
    </div>
  );
};

CartHeader.propTypes = {
  isLoading: PropTypes.bool,
  activeStep: PropTypes.number,
  steps: PropTypes.array
};

export default CartHeader;
