import React from 'react';
import createStyles from './Alert.styles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

const AlertBase = props => {
  // const classes = createStyles(props);
  const [open, setOpen] = React.useState(true);

  return (
    <Collapse in={open} style={{position: 'absolute'}}>
        <Alert
          severity={props.severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {props.message}
        </Alert>
      </Collapse>
  );
};



export default AlertBase;