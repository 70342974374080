import * as Yup from 'yup';
import { validate as validateCPF } from 'gerador-validador-cpf';
import moment from 'moment';
import 'moment/locale/pt-br'

moment.locale('pt-br')

const dateRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/

const registrationForm = {  
  firstname: Yup.string().required('Este campo é obrigatório'),
  lastname: Yup.string().required('Este campo é obrigatório'),
  email: Yup.string().email(),
  cpf: Yup.string()
    .required('Este campo é obrigatório')
    .test('cpf', 'CPF inválido', function(value) {
      return validateCPF(value);
    }), 
  rg: Yup.string().required('Este campo é obrigatório'),
  maritalStatus: Yup.string().required('Este campo é obrigatório'), 
  scholarity: Yup.string().required('Este campo é obrigatório'),
  hometown: Yup.string().required('Este campo é obrigatório'),
  homestate: Yup.string().required('Este campo é obrigatório'),
  birthDate: Yup.string().required('Este campo é obrigatório')
    .test('birthDate', 'Data de nascimento inválida', function(value) {
      return value && value.match(dateRegex) &&
          moment(value, "DD/MM/YYYY").isBefore(moment(new Date, "DD/MM/YYYY")) && 
          moment(value, "DD/MM/YYYY").isAfter(moment('01/01/1900', "DD/MM/YYYY"));
  }),
  rgEmissionDate: Yup.string().required('Este campo é obrigatório')
  .test('rgEmissionDate', 'Data de emissão inválida', function(value) {
    return value && value.match(dateRegex) &&
          moment(value, "DD/MM/YYYY").isBefore(moment(new Date, "DD/MM/YYYY")) && 
          moment(value, "DD/MM/YYYY").isAfter(moment('01/01/1900', "DD/MM/YYYY"));
  }),
  cepBilling: Yup.string().required('Este campo é obrigatório')
    .test('cep', 'Este campo é obrigatório', function(value) {
      // needed because placeholder `_____-___` counts as a non-empty string for required check above
      return value && value.replace(/_|-/g, '');
    }),
  stateBilling: Yup.string().required('Este campo é obrigatório'),
  cityBilling: Yup.string().required('Este campo é obrigatório'),
  neighbourhoodBilling: Yup.string().required('Este campo é obrigatório'),
  addressBilling: Yup.string().required('Este campo é obrigatório'),
  addressNumberBilling: Yup.string().required('Este campo é obrigatório').test('addressNumberBilling', 'Número inválido. Favor informar apenas números ou "s/n" caso não possua número', function (value) {
    return value && (
      value.toLowerCase() === "s/n" || 
      (!isNaN(value) && !isNaN(parseInt(value))) // verify if it is numeric
    );
  }),
  mothersName: Yup.string().required('Este campo é obrigatório'),
  phone1: Yup.string().test('phone1', 'Este campo é obrigatório', function(value) {
      return (value !== undefined ? value.replace(/_|-|\(|\)|\s/g, '') : '').length >= 10;
    }),
  gender: Yup.string().required('Este campo é obrigatório')
};

export const registrationFormValidator = Yup.object().shape(registrationForm);
