import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  carousel: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    maxWidth: '130rem',
    maxHeight: '80rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    width: '100%',
    maxWidth: '130rem',
    maxHeight: '80rem',
    overflow: 'hidden'
  },
  tray: {
    display: 'flex'
  },
  slide: {
    width: '100%'
  },
  image:{
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  buttonsContainer: {
    width: '115%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translate(-50%, -50%)',
  },
  buttonsContainerInner: {
    width: '100%',
    maxWidth: '144rem',
    margin: '0 2%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  button: {
    width: '3rem',
    height: '10rem',
    backgroundColor: 'transparent',
    border: '1px solid',
    borderRadius: '3px',
    borderColor: theme.colors.white,
    color: theme.colors.white,
    padding: '0',
    zIndex: '1',
    transition: 'all 150ms ease-in-out',

    '&:disabled': {
      opacity: '.3',
      cursor: 'unset'
    },

    '&:not(:disabled):hover': {
      backgroundColor: theme.colors.blueCompany,
      color: theme.colors.white
    },
    '@media (max-width: 600px)': {
      width: '2.5rem',
      height: '6rem'
    }
  },
  dotIndicators: {
    position: 'absolute',
    bottom: '-5rem',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2rem'
  },

}));

export default createStyles;
