import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  root: {},
  courseDetailsCardContainer: (descriptionHeight) => ({
    position: 'absolute',
    right: '5%',
    top: '-22rem',
    width: '32%',
    maxWidth: '46rem',
    height: descriptionHeight,
    '@media (max-width: 1160px)': {
      position: 'unset',
      right: 'unset',
      top: 'unset',
      width: '100%',
      maxWidth: 'unset',
      height: 'unset',
      display: 'flex',
      justifyContent: 'center',
      margin: '3rem 0'
    }
  }),
  courseDetailsCardSticky: {
    '@media (min-height: 1080px)': {
      width: '100%',
      float: 'right',
      position: 'sticky',
      top: '12rem',
      bottom: '200rem',
    },
    '@media (max-width: 1160px)': {
      maxWidth: '46rem',
      float: 'unset',
      position: 'unset',
      top: 'unset',
      bottom: 'unset'
    }
  },
  descriptionContainer: {
    marginBottom: '5rem',
  },
  bannerContent: {
    padding: '12rem 27rem',
    maxWidth: '44rem',
    [theme.breakpoints.down('md')]: {
      padding: '10rem 20rem'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '6rem'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10%'
    }
  },
  rootLoading: {
    height: 'calc(100vh - 7rem)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  linksBar: {
    marginTop: '3rem'
  },
  title: {
    color: theme.colors.blueCompany,
    textTransform: 'uppercase'
  },
  selectSearch: {
    marginTop: '3rem',
    marginBottom: '3rem',
    padding: ' 0 1rem'
  },
  cityFilterTitle: {
    marginTop: '3rem'
  },
  description: {
    color: theme.colors.text.dark,
    lineHeight: '1.5',
    margin: '3rem 0',
    fontSize: '14px',
  },
  sectionContainer: {
    marginTop: '3rem',
    padding: '0.75rem 0.75rem',
    '&:nth-child(last)': {
      backgroundColor: theme.colors.background.bg03,
    }
  },
  sectionTitle: {
    position: 'relative',
    color: theme.colors.text.dark,
    marginBottom: '2rem',
    '&::after': {
      content: '""',
      width: '7rem',
      height: '.3rem',
      backgroundColor: theme.colors.blueCompany,
      position: 'absolute',
      bottom: '0',
      left: '0'
    }
  },
  subSectionTitle: {
    // color: theme.colors.text.dark,
    marginTop: '2rem',
    marginBottom: '1rem',
    marginLeft: '1.5rem'
  },
  subSectionItem: {
    position: 'relative',
    color: theme.colors.text.dark,
    margin: '1rem 0',
    marginLeft: '3.2rem',
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
    whiteSpace: 'pre-wrap'
  }
}));

export default createStyles;
