import { makeStyles } from '@material-ui/core/styles';
import { flexbox } from '@material-ui/system';

const createStyles = makeStyles(theme => ({
  root: mediaQuery => ({
    width: mediaQuery.isPhone ? '100%' : '36.6rem', 
    boxShadow: '1px 2px 8px rgba(0, 0, 0, 0.2)', 
    display: 'flex', 
    marginBottom: '1.6rem', 
    marginRight: mediaQuery.isMobile? '0' : '1.6rem' ,
  }), 
  container: {
    justifyContent: 'center',
    position: 'relative', 
    display: 'flex', 
    flexDirection: 'column',
    width: '100%', 
    padding: '16px'
  },
  cardContainer: {
    display: 'flex', 
    flexDirection: 'row',
    justifyContent: 'space-between',
  }, 
  cardName:{
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-star',
    alignItems: 'center',

    '& img': {
      marginRight: '2rem',
      width: '4.6rem',
      height: '3.6rem',
      objectFit: 'cover',
    }

  },
  cardInfoContainer: {
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'space-between', 
    position: 'relative', 
    top: '25px'
  },

  addIcon: {
    width: 80,
    height: 80,
    color: theme.colors.background.bg10,
  }, 
  title: {
    textAlign: 'center'

  },
  newContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '169px',
    padding: '1.6rem',
    paddingBottom: '1.6rem!important',
    width: '100%', 

    '&:hover':{
      cursor: 'pointer',

      '& $addIcon': {
        color: theme.colors.blueCompany,
      },
      '& $title': {
        color: theme.colors.blueCompany,
      }
    }

  }
}));

export default createStyles;
