import React from 'react';
import createStyles from './CartConfirmation.styles';
import PropTypes from 'prop-types';
import ConfirmationPF from './ConfirmationPF/ConfirmationPF';
import ConfirmationPJ from './ConfirmationPJ/ConfirmationPJ';

const CartConfirmation = (props) => {

  const { isAuthenticated, user, billet, isLoading, getBilletForPrint } = props;

  if (!isAuthenticated) {
    window.location.assign('auth');
    return null;
  }
  
  return (
      <section>
          {user && user.cpf ? <ConfirmationPF isLoading={isLoading} user={user} billet={billet} getBilletForPrint={getBilletForPrint} /> : <ConfirmationPJ/> }
      </section>
  );
};

CartConfirmation.propTypes = {
  isLoading: PropTypes.bool,
  activeStep: PropTypes.number,
  steps: PropTypes.array
};

export default CartConfirmation;
