import { getMyCourses } from 'api/MyCourses';

// Actions
const FETCH_MYCOURSES_START = 'sesi/login/FETCH_MYCOURSES_START';
const FETCH_MYCOURSES_SUCCESS = 'sesi/login/FETCH_MYCOURSES_SUCCESS';
const FETCH_MYCOURSES_FAILURE = 'sesi/login/FETCH_MYCOURSES_FAILURE';
const MYCOURSES_RESET = 'sesi/login/MYCOURSES_RESET';

// Reducer
const initialState = {
  myCourses: [],
  isLoading: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_MYCOURSES_START:
      return { ...state, isLoading: true };
    case FETCH_MYCOURSES_SUCCESS:
      return { ...state, myCourses: action.payload || [], isLoading: false };
    case FETCH_MYCOURSES_FAILURE:
      return { ...state, ...action.payload };
    case MYCOURSES_RESET:
      return { ...state, ...initialState };
    default:
      return state;
  }
}

// Action Creators
export function fetchMyCoursesStart() {
  return { type: FETCH_MYCOURSES_START };
}

export function fetchMyCoursesSuccess(data) {
  return { type: FETCH_MYCOURSES_SUCCESS, payload: data };
}

export function fetchMyCoursesFailure(data) {
  return { type: FETCH_MYCOURSES_FAILURE, payload: data };
}

export function reset() {
  return { type: MYCOURSES_RESET };
}

// side effects, only as applicable
export const fetchMyCourses = () => {
  return async dispatch => {
    dispatch(fetchMyCoursesStart());

    try {
      const response = await getMyCourses();
      dispatch(fetchMyCoursesSuccess(response));
    } catch (error) {
      dispatch(fetchMyCoursesFailure(error));
    }
  };
};
