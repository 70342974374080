import React from 'react';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

import createStyles from './TabButton.styles';

const TabButton = props => {
  const classes = createStyles(props);

  const { isLoading, buttonText, children, lowercase, selected, ...rest } = props;
  const content = buttonText || children;

  return (
    <Button

      className={`${classes.button} ${selected && classes.active}`}
      variant='contained'
      {...rest}
    >
      {isLoading ? (
        <CircularProgress size={25} className={classes.spinner} />
      ) : (
        content
      )}
    </Button>
  );
};

TabButton.propTypes = {
  isLoading: PropTypes.bool,
  buttonText: PropTypes.string,
  children: PropTypes.string,
  link: PropTypes.string,
  lowercase: PropTypes.bool
};

export default TabButton;
