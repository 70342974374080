import React, { useEffect, useState, useRef } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import createStyles from './MyAccount.styles';
import MainContainer from 'components/MainContainer';
import Registration from '../Registration';
import PurchaseHistory from '../PurchaseHistory';
import PaymentMethods from '../PaymentMethods';
import Adresses from '../Adresses';
import Help from '../Help';
import { Container } from '@material-ui/core';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';

const StyledMenuItem = withStyles(theme => ({
  root: {
    borderRight: '1px solid rgba(0, 0, 0, 0.08)',
    '&:focus': {
      borderBottom: '2px solid #32559D',
      borderLeft: '1px solid rgba(0, 0, 0, 0.08)',
      borderTop: '1px solid rgba(0, 0, 0, 0.08)',
      borderRight: 'none'
    }
  },
  'selected': {
    borderBottom: '2px solid #32559D',
    borderLeft: '1px solid rgba(0, 0, 0, 0.08)',
    borderTop: '1px solid rgba(0, 0, 0, 0.08)',
    borderRight: 'none',
    backgroundColor: 'white !important'
  }
}))(MenuItem);

const Account = props => {

  const { user } = props;

  const [moduleSelected, setModuleSelected] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const rootRef = useRef(null);

  const classes = createStyles(isMobile);

  const breadcrumbs = [
    { label: 'Início', to: '/' },
    { label: 'Minha conta', to: '' }
  ];

  useEffect(() => {
    const updateWidth = () => {
      if (rootRef.current) {
        setIsMobile(rootRef.current.offsetWidth < 720 ? true : false)
      }
    };
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Breadcrumbs items={breadcrumbs} style={{ padding: '0px 135px' }} />
      <MainContainer>
        <header className={classes.header}>
          <h4 className={classes.title}>MINHA CONTA</h4>
          {isMobile &&
            <div className={classes.responsiveMenu}>
              <Button className={classes.responsiveMenuButton} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} style={{ color: 'white', backgroundColor: '#32559D' }}>
                <MoreVertRoundedIcon />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <StyledMenuItem value={0} selected={moduleSelected == 0} onClick={() => setModuleSelected(0)}>Cadastro</StyledMenuItem>
                {/* <StyledMenuItem value={1} selected={moduleSelected == 1} onClick={() => setModuleSelected(1)}>Histórico de Compra</StyledMenuItem> */}
                {/* <StyledMenuItem value={2} selected={moduleSelected == 2} onClick={() => setModuleSelected(2)}>Formas de Pagamento</StyledMenuItem> */}
                {/* <StyledMenuItem value={3} selected={moduleSelected == 3} onClick={() => setModuleSelected(3)}>Endereços</StyledMenuItem> */}
                <StyledMenuItem value={4} selected={moduleSelected == 4} onClick={() => setModuleSelected(4)}>Ajuda</StyledMenuItem>
              </Menu>
            </div>}
        </header>
        <Container ref={rootRef} className={classes.container} >

          {!isMobile &&
            <Paper className={classes.menu}>
              <MenuList className={classes.menuList}>
                <StyledMenuItem value={0} selected={moduleSelected == 0} onClick={() => setModuleSelected(0)}>Cadastro</StyledMenuItem>
                {/* <StyledMenuItem value={1} selected={moduleSelected == 1} onClick={() => setModuleSelected(1)}>Histórico de Compra</StyledMenuItem> */}
                {/* <StyledMenuItem value={2} selected={moduleSelected == 2} onClick={() => setModuleSelected(2)}>Formas de Pagamento</StyledMenuItem> */}
                {/* <StyledMenuItem value={3} selected={moduleSelected == 3} onClick={() => setModuleSelected(3)}>Endereços</StyledMenuItem> */}
                <StyledMenuItem value={4} selected={moduleSelected == 4} onClick={() => setModuleSelected(4)}>Ajuda</StyledMenuItem>
                <li className={classes.menuItem}><br /></li>
                <li className={classes.menuItem}><br /></li>
                <li className={classes.menuItem}><br /></li>
                <MenuItem className={classes.menuItem}>Sair</MenuItem>
              </MenuList>
            </Paper>
          }
          <section className={classes.selectedMenu}>
            {moduleSelected == 0 && <Registration user={user} />}
            {moduleSelected == 1 && <PurchaseHistory />}
            {/* {moduleSelected == 2 && <PaymentMethods />} */}
            {/* {moduleSelected == 3 && <Adresses />} */}
            {moduleSelected == 4 && <Help />}
          </section>

        </Container>

      </MainContainer>
    </React.Fragment>

  );
};

export default Account;
