import React, { useState, useEffect } from 'react';
import { Typography, Checkbox, InputLabel } from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';
import ButtonPrimary from 'components/ButtonPrimary';

import createStyles from './GratuityForm.styles';

const GratuityForm = (props) => {
  const classes = createStyles();
  const {
    errors,
    touched,
    values,
    isLoading,
    externalErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
    totals,
    products
  } = props;

  const formatPrice = (value) => {
    if (value)
      return value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    else return '-';
  };

  function priceTotal() {
    const rawPrice = totals.totalSegments.filter((segment) => segment.code === "grand_total")[0]
    var valor = parseFloat(rawPrice.value)
    return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
  }

  return (
    <div className={classes.billetFormRoot}>
      <Typography className={classes.totalPrice} variant='subtitle2'>
        {priceTotal()}
      </Typography>
      <Typography>
        Você receberá um e-mail com os próximos passos para matrícula.
      </Typography>

      <form onSubmit={handleSubmit}>
        <div className={classes.actions}>
          <Checkbox
            size='medium'
            color='primary'
            name='useTerms'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.useTerms}
          />
          <Typography
            className={classes.checkboxLabel}
            variant='body2'
            color='textPrimary'
          >
            Li e concordo com as <a href="/politicas" target="_blank">políticas do curso</a>
          </Typography>
        </div>

        <div className={classes.actions}>
          <ButtonPrimary type='submit' isLoading={isLoading} disabled={!isValid} lowercase>
            Confirmar
          </ButtonPrimary>
        </div>

        {externalErrors && (
          <FormHelperText className={classes.errorsText} error>
            {externalErrors}
          </FormHelperText>
        )}
      </form>
    </div>
  );
};

export default GratuityForm;
