import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    padding: '6rem 0',
    backgroundColor: theme.colors.blueCompany,
    margin: '0',
    alignSelf: 'flex-start',
    position: 'relative',
    marginBottom: '4rem',
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 26,
    textTransform: 'uppercase',
    color: theme.colors.white,
    letterSpacing: '-0.04em',
    margin: '0 0 1.5rem 0'
  },
  registrationContent: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center"
  },
  label: {
    fontSize: theme.typography.subtitle2.fontSize,
    color: theme.colors.white,
    marginBottom: '1.2rem',
  },
  topic: {
    color: theme.colors.text.dark,
    marginBottom: '2rem',
  },
  question: {
    fontWeight: 'bold',
    color: theme.colors.text.dark,
    marginBottom: '1.2rem',
  },
  answerContainer: {
    marginBottom: '2rem',
  },
  answer: {
    maxHeight: '4rem',
    overflow: 'hidden',
    marginBottom: '.5rem',
  },
  expandedAnswer: {
    maxHeight: 'unset',
  },
  viewMore: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: '.3rem .4rem',
    marginRight: '-.4rem',
    color: theme.colors.blueCompany,
    '&:hover': {
      cursor: 'pointer',
    }
  },
  container: isMobile => ({
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    marginBottom: '8rem',
    position: 'relative',
  }),
  menu: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    width: '28rem',
    alignSelf: 'flex-start',
    borderRadius: 0,
    boxShadow: 'none'
  },
  menuList: {
    paddingTop: '0px'
  },
  selectedMenu: isMobile => ({
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    marginLeft: isMobile ? 0 : '3rem',
    alignSelf: 'flex-start',
    width: '100%',
  }),
  selectSearch: {
    marginTop: '0rem',
    marginBottom: '4rem',
    padding: ' 0rem'
  },
  responsiveMenu: {
  },
}));

export default createStyles;
