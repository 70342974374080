import { applyVoucher as fetchApplyVoucher, create, sale, billetSale, getBillet, getItemsCart, getTotalsCart, removeVoucher as delVoucher, deleteItem } from 'api/Cart';
import courseImage from 'assets/images/courseImage.jpg';
import { addCourseToCart, deleteCourseFromCart, deleteAllFromCart } from 'shared/util/LocalStorage';

// Actions
const APPLY_VOUCHER_START = 'sesi/cart/APPLY_VOUCHER_START';
const APPLY_VOUCHER_SUCCESS = 'sesi/cart/APPLY_VOUCHER_SUCCESS';
const APPLY_VOUCHER_FAILURE = 'sesi/cart/APPLY_VOUCHER_FAILURE';
const APPLY_VOUCHER_RESET = 'sesi/cart/APPLY_VOUCHER_RESET';
const DELETE_VOUCHER_START = 'sesi/cart/DELETE_VOUCHER_START';
const DELETE_VOUCHER_SUCCESS = 'sesi/cart/DELETE_VOUCHER_SUCCESS';
const DELETE_VOUCHER_FAILURE = 'sesi/cart/DELETE_VOUCHER_FAILURE';
const SALE_START = 'sesi/cart/SALE_START';
const SALE_SUCCESS = 'sesi/cart/SALE_SUCCESS';
const SALE_FAILURE = 'sesi/cart/SALE_FAILURE';
const SALE_RESET = 'sesi/cart/SALE_RESET';
const REMOVE_ITEM_FROM_CART = 'sesi/register/REMOVE_ITEM_FROM_CART';
const ADD_ITEM_TO_CART = 'sesi/cart/ADD_ITEM_TO_CART';
const ADD_ITEM_TO_CART_FAILURE = 'sesi/cart/ADD_ITEM_TO_CART_FAILURE';
const ADD_ITEM_TO_CART_START = 'sesi/cart/ADD_ITEM_TO_CART_START';
const REMOVE_VOUCHER = 'sesi/register/REMOVE_VOUCHER';
const PRINT_BILLET_START = 'sesi/cart/PRINT_BILLET_START';
const PRINT_BILLET_SUCCESS = 'sesi/cart/PRINT_BILLET_SUCCESS';
const PRINT_BILLET_FAILURE = 'sesi/cart/PRINT_BILLET_FAILURE';

const GET_ITEMS_CART_START = 'sesi/cart/GET_ITEMS_CART_START';
const GET_ITEMS_CART_SUCCESS = 'sesi/cart/GET_ITEMS_CART_SUCCESS';
const GET_ITEMS_CART_FAILURE = 'sesi/cart/GET_ITEMS_CART_FAILURE';

const DELETE_ITEM_CART_START = 'sesi/cart/DELETE_ITEM_CART_START';
const DELETE_ITEM_CART_SUCCESS = 'sesi/cart/DELETE_ITEM_CART_SUCCESS';
const DELETE_ITEM_CART_FAILURE = 'sesi/cart/DELETE_ITEM_CART_FAILURE';

const GET_TOTAL_CART_START = 'sesi/cart/GET_TOTAL_CART_START';
const GET_TOTAL_CART_SUCCESS = 'sesi/cart/GET_TOTAL_CART_SUCCESS';
const GET_TOTAL_CART_FAILURE = 'sesi/cart/GET_TOTAL_CART_FAILURE';
const CLEAR_ITEM_CART_FAILURE = 'sesi/cart/CLEAR_ITEM_CART_FAILURE';

// Initial State
const initialState = {
  isLoading: false,
  isLoadingTotals: false,
  isLoadingVoucher: false,
  voucherErrors: '',
  cartVouchers: [],
  items: [],
  cart: {},
  cartError: '',
  saleError: undefined,
  saleSuccess: false,
  hasSaleError: false,
  billet: false,
  totals: {}
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //VOUCHER
    case APPLY_VOUCHER_START:
      return { ...state, isLoadingTotals: true };
    case APPLY_VOUCHER_SUCCESS:
      return {
        ...state,
        isLoadingTotals: false,
      };
    case APPLY_VOUCHER_FAILURE:
      return { ...state, isLoadingTotals: false, voucherErrors: action.data };
    case DELETE_VOUCHER_START:
      return { ...state, isLoadingTotals: true };
    case DELETE_VOUCHER_SUCCESS:
      return {
        ...state,
        isLoadingTotals: false,
        cartVouchers: []
      };
    case DELETE_VOUCHER_FAILURE:
      return { ...state, isLoadingTotals: false, voucherErrors: action.data };
    case APPLY_VOUCHER_RESET:
      return initialState;
    case REMOVE_VOUCHER:
      return {
        ...state,
        cartVouchers: [
          ...state.cartVouchers.filter((voucher) => {
            return voucher.id !== action.payload;
          })
        ]
      };
    //SALE
    case SALE_START:
      return { ...state, isLoading: true, saleError: undefined, hasSaleError: false, saleSuccess: false };
    case SALE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        saleSuccess: true,
        cart: {},
        hasSaleError: false,
        billet: action.payload.isBillet ? action.payload : false 
      };
    case SALE_FAILURE:
      return { ...state, isLoading: false, saleError: action.data, hasSaleError: true, saleSuccess: false, billet: false };
    case SALE_RESET:
      return {
        ...state,
        isLoading: false,
        voucherErrors: '',      
        cartError: '',
        saleError: undefined,
        saleSuccess: false,
        hasSaleError: false,
        billet: false
      }
    //CART
    case REMOVE_ITEM_FROM_CART:
      const nextItems = state.items ? state.items?.filter((item) => {
        return item.sku !== action.payload;
      }) : [];
      return {
        ...state,
        items: nextItems
      };
    case ADD_ITEM_TO_CART_START:
      return {
        ...state,
        cartError: '',
        isLoading: true
      };
    case ADD_ITEM_TO_CART:
      return {
        ...state,
        cart: action.payload,
        isLoading: false
      };
    case ADD_ITEM_TO_CART_FAILURE:
      return {
        ...state,
        cart: {},
        cartError: action.payload,
        isLoading: false
      };
    case DELETE_ITEM_CART_START:
      return {
        ...state,
        cartError: '',
        isLoading: true
      };
    case DELETE_ITEM_CART_SUCCESS:
      const itens = state.items.filter((item) => {
        return item.product.sku !== action.payload;
      });
      return {
        ...state,
        items: itens,
        isLoading: false
      };
    case DELETE_ITEM_CART_FAILURE:
      return { ...state, isLoading: false, saleError: action.data, hasSaleError: true, saleSuccess: false, billet: false };
    case PRINT_BILLET_START:
      return {
        ...state,
        cartError: '',
        isLoading: true
      };
    case PRINT_BILLET_SUCCESS:
      return {
        ...state,
        cart: action.payload,
        isLoading: false
      };
    case PRINT_BILLET_FAILURE:
      return {
        ...state,
        cart: {},
        cartError: action.payload,
        isLoading: false
      };
    case GET_ITEMS_CART_START:
      return {
        ...state,
        cartError: '',
        isLoading: true
      };
    case GET_ITEMS_CART_SUCCESS:
      return {
        ...state,
        items: action.payload,
        isLoading: false
      };
    case GET_ITEMS_CART_FAILURE:
      return {
        ...state,
        items: [],
        cartError: action.payload,
        isLoading: false
      };
    case GET_TOTAL_CART_START:
      return {
        ...state,
        cartError: '',
        isLoadingTotals: true
      };
    case GET_TOTAL_CART_SUCCESS:
      var voucher = []
      action.payload.totalSegments.map((segment) => {
        if(segment.code === "discount"){
            voucher.push(segment)
        }
      })
      return {
        ...state,
        totals: action.payload,
        isLoadingTotals: false,
        cartVouchers:voucher
      };
    case GET_TOTAL_CART_FAILURE:
      return {
        ...state,
        totals: {},
        cartError: action.payload,
        isLoadingTotals: false
      };
    case CLEAR_ITEM_CART_FAILURE:
      return {
        ...state,
        cartError: {},
      };
    default:
      return state;
  }
}

// Action Creators
export const addItemToCart = (item) => {
  return async (dispatch) => {
    dispatch(addItemToCartStart());

    try {
      // addCourseToCart(item);
      let request = { sku: item.product.sku };
      const response = await create(request);
      dispatch(addItemToCartSuccess(response));
      return response;
    } catch (error) {
      // TODO: limpar o carrinho (resolver erro 500)
      // deleteAllFromCart();
      dispatch(addItemToCartFailure(error));
    }
  };
}

export const addItemToCartStart = () => {
  return { type: ADD_ITEM_TO_CART_START };
};

export const addItemToCartFailure = (data) => {
  return { type: ADD_ITEM_TO_CART_FAILURE, payload: data };
};

export const addItemToCartSuccess = (data) => {
  return { type: ADD_ITEM_TO_CART, payload: data };
};

export function removeItemFromCart(itemId) {
  return { type: REMOVE_ITEM_FROM_CART, payload: itemId };
}

export function removeVoucher(voucherId) {
  return { type: REMOVE_VOUCHER, payload: voucherId };
}

export function applyVoucherStart() {
  return { type: APPLY_VOUCHER_START };
}

export function applyVoucherSuccess(data) {
  return { type: APPLY_VOUCHER_SUCCESS, payload: data };
}

export function applyVoucherFailure(data) {
  return { type: APPLY_VOUCHER_FAILURE, payload: data };
}

export function applyVoucherReset() {
  return { type: APPLY_VOUCHER_RESET };
}

export function saleStart() {
  return { type: SALE_START };
}

export function saleSuccess(data) {
  return { type: SALE_SUCCESS, payload: data };
}

export function saleFailure(data) {
  return { type: SALE_FAILURE, payload: data };
}

export function saleReset() {
  return { type: SALE_RESET };
}

export function printBilletStart() {
  return { type: PRINT_BILLET_START };
}

export function printBilletSuccess(data) {
  return { type: PRINT_BILLET_SUCCESS, payload: data };
}

export function printBilletFailure(data) {
  return { type: PRINT_BILLET_FAILURE, payload: data };
}

export function getItemsCartStart() {
  return { type: GET_ITEMS_CART_START };
}

export function getItemsCartSuccess(data) {
  return { type: GET_ITEMS_CART_SUCCESS, payload: data };
}

export function getItemsCartFailure(data) {
  return { type: GET_ITEMS_CART_FAILURE, payload: data };
}

export function getTotalsCartStart() {
  return { type: GET_TOTAL_CART_START };
}

export function getTotalsCartSuccess(data) {
  return { type: GET_TOTAL_CART_SUCCESS, payload: data };
}

export function getTotalsCartFailure(data) {
  return { type: GET_TOTAL_CART_FAILURE, payload: data };
}

export function deleteVoucherStart() {
  return { type: DELETE_VOUCHER_START };
}

export function deleteVoucherSuccess(data) {
  return { type: DELETE_VOUCHER_SUCCESS, payload: data };
}

export function deleteVoucherFailure(data) {
  return { type: DELETE_VOUCHER_FAILURE, payload: data };
}


export function deleteItemStart() {
  return { type: DELETE_ITEM_CART_START };
}

export function deleteItemSuccess(data) {
  return { type: DELETE_ITEM_CART_SUCCESS, payload: data };
}

export function deleteItemFailure(data) {
  return { type: DELETE_ITEM_CART_FAILURE, payload: data };
}

export function clearErrorCart() {
  return { type: CLEAR_ITEM_CART_FAILURE };
}

export const deleteItemInCart = (sku) => {
  return async (dispatch) => {
    dispatch(deleteItemStart());
    try {
      const response = await deleteItem(sku);
      dispatch(deleteItemSuccess(sku));
    } catch (error) {
      dispatch(deleteItemFailure(error));
    }
  };
};

//INICIO DOS METODOS
export const deleteVoucher = (voucherCode) => {
  return async (dispatch) => {
    dispatch(deleteVoucherStart());

    try {
      const response = await delVoucher(voucherCode);
      dispatch(deleteVoucherSuccess(response.data));
    } catch (error) {
      dispatch(deleteVoucherFailure(error));
    }
  };
};

export const applyVoucher = ({ voucherCode }) => {
  return async (dispatch) => {
    dispatch(applyVoucherStart());

    try {
      const response = await fetchApplyVoucher(voucherCode);
      dispatch(applyVoucherSuccess(response.data));
    } catch (error) {
      dispatch(applyVoucherFailure(error));
    }
  };
};

export const saleCreditCard = (data) => {
  return async (dispatch) => {
    dispatch(saleStart());
    try {
      const response = await sale(data);
      deleteCourseFromCart(); // FIXME: passar itemId
      var responseObject = {
        orderId : response,
        isBillet: false
      }
      dispatch(saleSuccess(responseObject));
      return responseObject
    } catch (error) {
      dispatch(saleFailure(error));
    }
  };
};

export const saleBillet = (data) => {
  return async (dispatch) => {
    dispatch(saleStart());
    try {
      const response = await billetSale(data);
      deleteCourseFromCart(); // FIXME: passar itemId
      const result = {
        orderId: response,
        isBillet: true
      }
      dispatch(saleSuccess(result));
    } catch (error) {
      dispatch(saleFailure(error));
    }
  };
};

export const getBilletForPrint = (data) => {
  return async (dispatch) => {
    dispatch(printBilletStart());
    try {
      const response = await getBillet(data);
      dispatch(printBilletSuccess(response));
    } catch (error) {
      dispatch(printBilletFailure(error));
    }
  };
};

export const getItemsInCart = () => {
  return async (dispatch) => {
    dispatch(getItemsCartStart());
    try {
      const response = await getItemsCart();
      dispatch(getItemsCartSuccess(response));
    } catch (error) {
      dispatch(getItemsCartFailure(error));
    }
  };
};

export const getTotalsInCart = () => {
  return async (dispatch) => {
    dispatch(getTotalsCartStart());
    try {
      const response = await getTotalsCart();
      dispatch(getTotalsCartSuccess(response));
    } catch (error) {
      dispatch(getTotalsCartFailure(error));
    }
  };
};

