import React, { useEffect, useState, Fragment } from 'react';
import ButtonPrimary from 'components/ButtonPrimary';
import { Typography } from '@material-ui/core';
import SelectSearch from 'components/SelectSearch';
import ReactPaginate from 'react-paginate';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import createStyles from './Pagination.styles';

const Pagination = props => {
  const classes = createStyles(props);

  const {
    totalSearchResults,
    itensPerPage,
    onPageChange,
    className,
    initialPage
  } = props;
  const [pageCount, setPageCount] = useState(0);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(2);
  const [marginPagesDisplayed, setMarginPagesDisplayed] = useState(2);

  useEffect(() => {
    setPageCount(totalSearchResults / itensPerPage);
  }, [totalSearchResults, itensPerPage]);

  return (
    pageCount > 1 && (
      <div className={className}>
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={pageRangeDisplayed}
          initialPage={initialPage}
          marginPagesDisplayed={marginPagesDisplayed}
          onPageChange={onPageChange}
          containerClassName={classes.paginationContainer}
          pageClassName={classes.paginationListItem}
          previousClassName={classes.previousLabel}
          previousLinkClassName={classes.previousLinkClassName}
          nextClassName={classes.nextLabel}
          nextLinkClassName={classes.nextLinkClassName}
          activeClassName={classes.activePage}
          activeClassName={classes.activePage}
          activeLinkClassName={classes.activePageLink}
          breakClassName={classes.breakClassName}
          disabledClassName={classes.disabledClassName}
          pageLinkClassName={classes.pageLinkClassName}
          nextLabel={<ChevronRightIcon fontSize='small' />}
          previousLabel={<ChevronLeftIcon fontSize='small' />}
        />
      </div>
    )
  );
};
export default Pagination;
