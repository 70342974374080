import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  root: {
    width: 340,
    borderRadius: 0,
    '@media (max-width: 400px)': {
      width: '100%'
    },
    boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.25)'
  },
  forgotPasswordLabel: {
    fontSize: '1.2rem',
    color: theme.colors.blueCompany
  },
  registerCall: {
    margin: '2rem 0 1rem 0'
  },
  registerLink: {
    marginLeft: '0.5rem',
    color: theme.colors.blueCompany
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  title: {
    marginTop: '1.4rem'
  },
  cardContent: {
    padding: '1.5rem 3rem'
  }
}));

export default createStyles;
