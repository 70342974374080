import React, { useState, useEffect, useRef } from 'react';
import Input from 'components/Input';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from 'components/ButtonPrimary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormHelperText } from '@material-ui/core';

import createStyles from './EditAddressForm.styles';

const StyledCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#32559D;'
    }
  },
  checked: {},
  body1: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '19px'
  }
})((props) => <Checkbox color='default' {...props} />);

const EditAddressForm = (props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const visibilityIcon = isPasswordVisible ? <Visibility /> : <VisibilityOff />;

  const classes = createStyles(isMobile);
  const {
    errors,
    touched,
    values,
    isSubmitting,
    isLoading,
    // externalErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    className,
    loginErrors
  } = props;

  useEffect(() => {
    let inputCEP = '';

    if (values.cep) {
      inputCEP = values.cep.replace(/_|-/g, '');
    }

    if (inputCEP.length == 8) {
      getCEP(inputCEP);
    }
  }, [values.cep]);

  useEffect(() => {
    const updateWidth = () => {
      setIsMobile(window.innerWidth < 800 ? true : false);
    };
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  });

  function getCEP(cep) {
    setLoading(true);
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((response) => response.json())
      .then((data) => {
        if (data.erro) {
          values.address = '';
          values.neighbourhood = '';
          values.city = '';
          values.state = '';
          setError(true);
        } else {
          values.address = data.logradouro;
          values.neighbourhood = data.bairro;
          values.city = data.localidade;
          values.state = data.uf;
          setError(false);
        }
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => setLoading(false));

    return;
  }

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      {loading && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}

      <div className={classes.formColumnsContainer}>
        <div className={classes.formColumn}>
          <Input
            placeholder='Casa'
            label='Nome (opcional)'
            type='text'
            name='name'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            customClasses={{ root: classes.input }}
            error={touched.name ? errors.name : null}
          />

          <Input
            placeholder='Ex: 11023-456'
            mask={'99999-999'}
            label='CEP'
            type='text'
            name='cep'
            helperText='Seu endereço aparecerá automaticamente.'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.cep}
            customClasses={{ root: classes.input }}
            error={error ? 'Esse CEP não existe.' : touched.cep ? errors.cep : null}
          />

          <Input
            label='Estado'
            type='text'
            name='state'
            disabled='true'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.state}
            customClasses={{ root: classes.input }}
            error={touched.state ? errors.state : null}
          />

          <Input
            label='Cidade'
            type='text'
            name='city'
            disabled='true'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.city}
            customClasses={{ root: classes.input }}
            error={touched.city ? errors.city : null}
          />
        </div>

        <div className={classes.formColumn}>
          <Input
            label='Bairro'
            type='text'
            name='neighbourhood'
            disabled='true'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.neighbourhood}
            customClasses={{ root: classes.input }}
            error={touched.neighbourhood ? errors.neighbourhood : null}
          />

          <Input
            label='Endereço'
            type='text'
            name='address'
            disabled='true'
            helperText=' '
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address}
            customClasses={{ root: classes.input }}
            error={touched.address ? errors.address : null}
          />

          <Input
            placeholder='Ex: 250'
            label='Número'
            type='number'
            name='number'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.number}
            customClasses={{ root: classes.input }}
            error={touched.number ? errors.number : null}
          />

          <Input
            placeholder='Ex: Condomínio ABC, Bloco A, APT 202'
            label='Complemento'
            type='text'
            name='complement'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.complement}
            customClasses={{ root: classes.input }}
            error={touched.complement ? errors.complement : null}
          />
        </div>
      </div>
      <div style={{ marginBottom: errors.deliveryAddress ? '0' : '25px' }}>
        <FormControlLabel
          style={{ position: 'relative', top: '15%' }}
          control={
            <StyledCheckbox
              onChange={handleChange}
              name='chargeAddress'
              checked={values.chargeAddress}
            />
          }
          label={<Typography variant='body2'>Endereço de cobrança</Typography>}
        />
        <FormControlLabel
          style={{ position: 'relative', top: '15%' }}
          control={
            <StyledCheckbox
              onChange={handleChange}
              name='deliveryAddress'
              checked={values.deliveryAddress}
            />
          }
          label={<Typography variant='body2'>Endereço de entrega</Typography>}
        />
      </div>
      {errors.deliveryAddress && (
        <FormHelperText
          style={{ marginBottom: '25px' }}
          className={classes.helperTextError}
          error={true}
        >
          {errors.deliveryAddress}
        </FormHelperText>
      )}
      {/* {errors.chargeAddress && (
        <FormHelperText className={classes.helperTextError} error={true}>
          {errors.chargeAddress}
        </FormHelperText>
      )} */}
      <Button
        style={{ width: '244px' }}
        lowercase
        isLoading={isLoading}
        type='submit'
        width='100%'
      >
        Salvar
      </Button>
    </form>
  );
};

export default EditAddressForm;
