import { makeStyles, withTheme } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  editAddressRoot: {
    display: 'flex',
    // width: '90rem',
    padding: '2rem',
    '& p': {
      color: theme.colors.text.dark
    },
    '& h6': {
      color: theme.colors.text.dark
    },
    '@media(max-width:922px)': {
      width: 'auto',
      flexDirection: 'column'
    },
    '@media(max-width:422px)': {
      padding: '4rem 0rem'
    }
  },
  addressCard: {
    width: '50%',
    padding: '2rem',
    boxShadow: '1px 2px 8px rgba(0, 0, 0, 0.2)',
    margin: '1rem 0.5rem',
    '@media(max-width:922px)': {
      margin: '0',
      width: '100%',
      boxSizing: 'border-box'
    }
  },
  addressCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  alterBtn: {
    padding: '0.5rem 1.5rem'
  }
}));

export default createStyles;
