import React from 'react';
import LoginBox from 'components/AuthenticationBox/LoginBox';
import createStyles from './Login.styles';
import { Redirect, Route } from 'react-router-dom';

const Login = (props) => {
  const classes = createStyles(props);

  const { history, isAuthenticated, location } = props;

  const previousUrl = location.state ? location.state.from : '/';

  // se usuário já está autenticado, redireciona ele para a url destino (default é '/' - ver redux)
  if (isAuthenticated) return <Redirect to={{ pathname: previousUrl }} />;

  function register() {
    window.location.href = '/cadastro';
  }

  var path = formatter(props.location.pathname.replace("/login/", ''))

  function formatter(url) {
    if(url === '/login'){
      return '/'
    }

    if( url !== '' ){
      return '/curso/-/' + url
    }
  }

  return <>
    {path !== '/' ?
      <div className={classes.root}>
        <LoginBox history={history} handleRegisterClick={register}  redirectAfterLogin={() => { history.push(path)  }} />
      </div>
      :
      <div className={classes.root}>
        <LoginBox
          history={history}
          handleRegisterClick={register}
        />
      </div>
    }
  </>
};

export default Login;
