import { makeStyles, withTheme } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
    paper: props => ({
        position: 'absolute',
        width: 360,
        height: props.height, 
        backgroundColor: theme.palette.background.paper,
        padding: 30,
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        left: 0,
        top: 0,
    }), 
    titleContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center'
    },
    deleteIcon: {
        position: 'relative', 
        width: 50,
        height: 50,
    }, 
    title: {
        fontStyle: 'normal', 
        fontWeight: 'normal', 
        fontSize: '26px',
        color: ' rgba(0, 0, 0, 0.65)',
    }, 
    text: {
        color: ' rgba(0, 0, 0, 0.65)',
        width: 300, 
        textAlign: 'justify', 
        marginBottom: '16px'
    }, 
    button: {
        background: '#32559D', 
        color: 'white', 
        textTransform:'capitalize', 
        borderRadius:'0%',
        width: '85%',
        '&:hover': {
            background: '#32559D'
        }
    },
    clearIcon: {
        position: 'absolute',
        left: '90.83%',
        top: '0.83%',
        cursor: 'pointer'
    }
}));

export default createStyles;