import React from 'react';
import Typography from '@material-ui/core/Typography';
import createStyles from './MyPaymentCard.styles';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { addThousandDot } from 'shared/util/textTransformation.js';
import MyCourseCard from 'components/MyCourseCard'
import { getCustomAttribute } from 'shared/util/ProductCustomAttribute';
import { toUrlFriendly } from 'shared/util/textTransformation';

const MyPaymentCard = props => {

  const {
    id,
    total,
    items,
    status,
    createdAt,
    paymentMethod,
    discountAmount,
  } = props;
  const classes = createStyles(props);

  const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      borderRadius: '5px',
      background: 'transparent',
      boxShadow: 'none',
      width: '100%',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgb(36 84 160);',
      color: "#fff",
      borderRadius: '5px',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 48,
      '&$expanded': {
        minHeight: 48,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column"
    },
  }))(MuiAccordionDetails);

  let statusName = "";
  switch (status) {
    case 1:
      statusName = "Em andamento";
      break;
    case 2:
      statusName = "Aguardando início";
      break;
    case 3:
      statusName = "Documentação pendente";
      break;
    case 4:
      statusName = "Finalizado";
      break;
  }

  function metodopagamento(payment) {
    if (payment === "boleto_bb") {
      return "Boleto"
    } else {
      return "Cartão"
    }
  }

  const formatPrice = (total) => {
    if (total)
      return addThousandDot(total.toString().replace('.', ','));
    else return '-';
  };

  function priceTotal(total) {
    return `R$ ${formatPrice(total)}`
  }

  return (
    <div className={classes.root}>
      <div className={classes.infoContainer}>
        <div className={classes.lowerInfo}>
          <div className={classes.coursepropreties}>
            <ul className={classes.detailsList}>
              <li className={classes.detail}>
                <Typography>Número do Pedido: </Typography>
                <Typography color='textPrimary'>{id}</Typography>
              </li>
              <li className={classes.detail}>
                <Typography>Data da compra: </Typography>
                <Typography color='textPrimary'>{moment(createdAt).format('DD/MM/YYYY HH:mm')}</Typography>
              </li>
            </ul>
            <ul className={classes.detailsListCenter}>
              <li className={classes.detail}>
                <Typography>Status: </Typography>
                <Typography color='textPrimary'>
                  { status === 'complete' ? 'Completo'
                    : status === 'pending' ? 'Pendente'
                    : ''
                  }
                </Typography>
              </li>
              <li className={classes.detail}>
                <Typography>
                  Valor total:
                </Typography>
                <Typography color='textPrimary'>
                  {priceTotal(total)}
                </Typography>
              </li>
            </ul>
            <ul className={classes.detailsListRight}>
              <li className={classes.detail}>
                <Typography>Método de pagamento: </Typography>
                <Typography color='textPrimary'>
                  {metodopagamento(paymentMethod)}
                </Typography>
              </li>
              <li className={classes.detail}>
                <Typography>
                  Valor do desconto:
                </Typography>
                <Typography color='textPrimary'>
                  {discountAmount ? 'R$ ' + discountAmount : '-'}
                </Typography>
              </li>
            </ul>

          </div>
          {/* <Typography color='textPrimary' className={classes.status}>{statusName}</Typography> */}
        </div>
      </div>
      <div className={classes.accordionContainer}>
        {items && items.map((item) => {
          return (
            <>
              <Accordion square>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>Itens</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ width: '100%' }}>
                  <MyCourseCard
                        key={item.id}
                        id={item.id}
                        image={item.image}
                        areaTag={item.areaTag}
                        title={item.title}
                        place={item.classes && item.classes[0].place}
                        duration={item.classes[0].duration}
                        courseClasses={item.classes}
                        modalidade={item.presencialDistancia}
                        price={item.price}
                        parcels={item.parcels}
                        link={`/curso/${toUrlFriendly(item.title)}/${item.sku}`}
                        favorite={item.favorite}
                        discount={item.discount}
                        priceWithDiscount={item.priceWithDiscount}
                        status={item.status}
                        date={getCustomAttribute(item.classes[0].product, "start_date")}
                      />
                  </Typography>
                </AccordionDetails>
              </Accordion>

            </>
          );
        })}

      </div>
    </div>
  );
};

MyPaymentCard.propTypes = {};

export default MyPaymentCard;
