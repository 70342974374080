import RegisterBoxPF from './RegisterBoxPF.jsx';
import { connect } from 'react-redux';
import { citiesByState } from 'modules/Registration/Registration.ducks'

const mapStateToProps = (state) => {
  return {
    citys: state.userRegistration.citys,
    isLoading: state.userRegistration.isLoading, 
  };
};

const mapDispatchToProps = {
  citiesByState
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterBoxPF);
