import React, { useState, useEffect } from 'react';
import PlaceIcon from '@material-ui/icons/Place';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import EventIcon from '@material-ui/icons/Event';
import { Button, Typography } from '@material-ui/core';
import ButtonPrimary from 'components/ButtonPrimary';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ButtonClass from '../ButtonClass/ButtonClass';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CarouselGallery from '../CarouselGallery/CarouselGallery';
import { addThousandDot } from 'shared/util/textTransformation.js';
import PeopleIcon from '@material-ui/icons/People';
import createStyles from './CourseDetailsCard.styles';
import ReactJson from 'react-json-view';
import { getCustomAttribute } from 'shared/util/ProductCustomAttribute';
import moment from 'moment';
import RegisterInterest from '../CourseCard/RegisterInterest';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import NightsStay from '@material-ui/icons/NightsStay';

import { useHistory } from 'react-router-dom';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CourseDetailsCard = (props) => {
  const [courseClass, setCourseClass] = useState({});
  const [courseIndex, setCourseIndex] = useState(0);
  const [courseFavorite, setCourseFavorite] = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (classes) {
      setCourseClass(classes[0]);
    }

    setCourseFavorite(favorite)
  }, []);

  const classesStyle = createStyles(props);
  const { loading, items, deleteItemInCart, openLoginBox } = props;
  const {
    sku,
    image,
    areaTag,
    classes,
    price,
    parcels,
    favorite,
    discount,
    priceWithDiscount,
    senaiNacional,
    link,
    success
  } = props.course;

  const { user, isAuthenticated, setFavorite } = props;
  const [userLogged, setUserLogged] = useState(isAuthenticated);
  const [isDragging, setIsDragging] = useState(false);
  const hasDiscount = discount && discount > 0;

  const formattedPrice =
    price && addThousandDot(price.toFixed(2).toString().replace('.', ','));

  const formattedPriceWithDiscount =
    priceWithDiscount &&
    addThousandDot(priceWithDiscount.toFixed(2).toString().replace('.', ','));

    const handelPriceParcel = () =>{
      if(priceWithDiscount && parcels){
        return addThousandDot((priceWithDiscount / parcels).toFixed(2).toString().replace('.', ','));
      }else if (price && parcels){
        return addThousandDot((price / parcels).toFixed(2).toString().replace('.', ','));
      }
    }
    let priceParcel = handelPriceParcel();

  const [openLoginWarning, setOpenLoginWarning] = useState(false);

  const handleLoginWarningClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenLoginWarning(false);
  };

  const handleFavoriteClick = (value) => {
    if (isAuthenticated) {
      setCourseFavorite(value)
      setFavorite(sku, user.email, value);
    } else {
      setOpenLoginWarning(true);
    }
  };

  const handleEnrollClick = async () => {

    if (isAuthenticated) {
      if (senaiNacional) {
        history.push(link)
      }

      let clazz = classes[courseIndex];

      clazz = {
        ...clazz,
        parcels: parcels,
        image: image,
      };

      var objeto = await props.addItem(clazz);
      if (objeto) {
        history.push('/carrinho/step:1')
      }
    } else {
      openLoginBox();
    }
  };
  
  useEffect(() => {
    if(userLogged === false && isAuthenticated){
      
      setUserLogged(true)
      handleEnrollClick();
    }

  }, [isAuthenticated]);


  function handleClass(name, index) {
    setCourseClass(name);
    setCourseIndex(index);
  }

  const [open, setOpen] = useState(false);
  const [openInterest, setOpenInterest] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openInterests = () => {
    setOpenInterest(true);
  };

  const closeInterest = () => {
    setOpenInterest(false);
  };

  const handleTouchEnd = (callback) => {
    // se usuário esta arrastando, não considera click no botão
    // caso contrário executa a função de click passada pelo button
    if (isDragging) {
      return;
    } else {
      callback();
    }
  };

  return (
    <div className={classesStyle.root}>
      {classes && classes[courseIndex] &&
        <>
          <div className={classesStyle.imageContainer}>
            <div
              onClick={(e) => e.preventDefault()}
              className={classesStyle.areaTagContainer}
            >
              <Typography className={classesStyle.areaTagText}>{classes && classes[courseIndex] && getCustomAttribute(classes[courseIndex].product, 'area')}</Typography>
            </div>

            {props.gallery ? (
              <React.Fragment>
                <Typography className={classesStyle.galleryLabel}>
                  Galeria de fotos
                </Typography>
                <div className={classesStyle.galleryOverlay} onClick={handleOpen}>
                  <Typography className={classesStyle.galleryLabel}>
                    Galeria de fotos
                  </Typography>
                </div>
              </React.Fragment>
            ) : (
              ''
            )}
            <Modal
              aria-labelledby='Galeria de fotos'
              aria-describedby='Galeria de fotos com imagens relacionadas ao curso'
              className={classesStyle.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                classes: { root: classesStyle.backdrop },
                timeout: 300
              }}
            >
              <Fade in={open}>
                <CarouselGallery imageList={props.gallery}></CarouselGallery>
              </Fade>
            </Modal>
            {discount && discount > 0 ? (
              <div
                onClick={(e) => e.preventDefault()}
                className={classesStyle.tagDiscount}
              >
                <Typography className={classesStyle.areaTagText}>
                  {`${discount}% OFF!`}
                </Typography>
              </div>
            ) : (
              ''
            )}
          </div>

          <div className={classesStyle.infoContainer}>
            <div className={classesStyle.classSelector}>
              {classes &&
                classes.map((item, index) => {
                  return (
                    <ButtonClass
                      onClick={() => handleClass(item, index)}
                      selected={courseClass.id === item.id}
                      buttonText={item.name}
                    />
                  );
                })}
            </div>
            <div className={classesStyle.lowerInfo}>
              {classes && (
                <ul className={classesStyle.detailsList}>
                  <li className={classesStyle.detail}>
                    <PlaceIcon fontSize='small' />
                    <Typography variant='subtitle2'>Cidade : </Typography>
                    <Typography variant='subtitle2' className={classesStyle.detailInfo}>
                      {classes[courseIndex].place}
                    </Typography>
                  </li>
                  <li className={classesStyle.detail}>
                    <QueryBuilderIcon fontSize='small' />
                    <Typography variant='subtitle2'>Duração: </Typography>
                    <Typography variant='subtitle2' className={classesStyle.detailInfo}>
                      {classes[courseIndex] && parseInt(classes[courseIndex].duration) + ' horas'}
                    </Typography>
                  </li>
                  <li className={classesStyle.detail}>
                    {classes[courseIndex].turnoLabel === 'Noturno' ?
                      <NightsStay fontSize='small' />
                    :
                      <WbSunnyIcon fontSize='small' />}
                    <Typography variant='subtitle2'>Turno: </Typography>
                    <Typography variant='subtitle2' className={classesStyle.detailInfo}>
                      {classes[courseIndex].turnoLabel}
                    </Typography>
                  </li>
                  <li className={classesStyle.detail}>
                    <EditIcon fontSize='small' />
                    <Typography variant='subtitle2'>Modalidade: </Typography>
                    <Typography variant='subtitle2' className={classesStyle.detailInfo}>
                      {classes[courseIndex] && classes[courseIndex].product.custom_attributes.filter(item => item.attribute_code === "presencial_distancia")[0]?.value}
                    </Typography>
                  </li>
                  <li className={classesStyle.detail}>
                    <EventIcon fontSize='small' />
                    <Typography variant='subtitle2'>Data: </Typography>
                    <Typography variant='subtitle2' className={classesStyle.detailInfo}>
                      {courseClass && courseClass.openChairs > 0 ? classes[courseIndex] && moment(getCustomAttribute(classes[courseIndex].product, 'start_date')).format('DD/MM/yyyy') : "Sem data prevista"}
                    </Typography>
                  </li>
                  {/*   <li className={classesStyle.detail}>
                    <PeopleIcon fontSize='small' />
                    <Typography variant='subtitle2'>Número de vagas: </Typography>
                    <Typography variant='subtitle2' className={classesStyle.detailInfo}>
                      {classes[courseIndex] && getCustomAttribute(classes[courseIndex].product, 'quantity')}
                    </Typography>
                  </li> */}
                </ul>
              )}

              {classes && (classes.length > 0 && courseClass.openChairs > 0) ? (
                <div className={classesStyle.priceContainer}>
                  <div>
                    {formattedPrice == 0 ? (
                      <>
                        <Typography color='textSecondary' className={classesStyle.price}>
                          Gratuito
                        </Typography>
                        <Typography className={classesStyle.price}></Typography>
                        <Typography className={classesStyle.paymentConditions}></Typography>
                      </>
                    ) : (
                      <>
                        <Typography
                          color='textSecondary'
                          className={classesStyle.originalPrice}
                        >
                          {hasDiscount ? `R$${formattedPrice}` : ''}
                        </Typography>

                        <Typography className={classesStyle.price}>
                          {`em até ${parcels.toString()}x de R$${priceParcel}`}
                        </Typography>

                        <Typography
                          color='textSecondary'
                          className={classesStyle.paymentConditions}
                          variant='body1'
                        >
                          {hasDiscount
                            ? `R$${formattedPriceWithDiscount}`
                            : `R$${formattedPrice}`}
                        </Typography>

                        <Typography
                          color='textSecondary'
                          className={classesStyle.paymentConditions}
                          variant='body2'
                        >
                          {getCustomAttribute(classes[courseIndex].product, 'desc_plano')}
                        </Typography>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <Typography
                  color='textSecondary'
                  variant='subtitle1'
                  className={classesStyle.noClassesAvailable}
                >
                  Vagas esgotadas
                </Typography>
              )}
              {courseClass && courseClass.openChairs > 0 ? (
                <ButtonPrimary
                  buttonText={
                    !isAuthenticated || (isAuthenticated && user.cpf)
                      ? 'Matricule-se'
                      : 'Comprar'
                  }
                  width='100%'
                  // asLink={!senaiNacional}
                  isLoading={loading}
                  // link={senaiNacional ? link : '/carrinho/step:1'}
                  onClick={() => handleEnrollClick()}
                />
              ) : (
                <ButtonPrimary
                  onTouchStart={() => setIsDragging(false)}
                  onTouchMove={() => setIsDragging(true)}
                  onTouchEnd={() => handleTouchEnd(openInterest)}
                  buttonText='Cadastrar interesse'
                  width='100%'
                  variant='outlined'
                  onClick={openInterests}
                />

              )}
              {courseFavorite === true ? (
                <Button
                  onClick={() => handleFavoriteClick(false)}
                  variant='text'
                  classes={{
                    root: classesStyle.favoriteButton,
                    label: classesStyle.favoriteLabel
                  }}
                  startIcon={
                    <FavoriteIcon
                      onClick={() => handleFavoriteClick(false)}
                      className={classesStyle.favoriteIcon}
                    />
                  }
                >
                  Remover dos favoritos
                </Button>
              ) : (
                <Button
                  onClick={() => handleFavoriteClick(true)}
                  variant='text'
                  classes={{
                    root: classesStyle.favoriteButton,
                    label: classesStyle.favoriteLabel
                  }}
                  startIcon={
                    <FavoriteBorderIcon
                      onClick={() => handleFavoriteClick(true)}
                      className={classesStyle.favoriteIcon}
                    />
                  }
                >
                  Adicionar aos favoritos
                </Button>
              )}
            </div>
          </div>
        </>
      }
      <Modal
        open={openInterest}
        onClose={closeInterest}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        {<RegisterInterest course={props.course} closeInterest={closeInterest} place={classes ? classes[0]?.place : ""} />}

      </Modal>
      <Snackbar style={{zIndex: "99999"}} open={openLoginWarning} autoHideDuration={6000} onClose={handleLoginWarningClose}>
        <Alert style={{zIndex: "99999"}} onClose={handleLoginWarningClose} severity="warning">
          Você precisa estar logado para favoritar um curso
        </Alert>
      </Snackbar>
    </div>
  );
};

CourseDetailsCard.propTypes = {};

export default CourseDetailsCard;
