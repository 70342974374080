import { makeStyles, withTheme } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 1)), url(${props.backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top'
  }),
  mainContainer: {
    position: 'absolute !important',
    bottom: '2rem'
  },
  title: {
    color: theme.colors.white,
    marginBottom: '2rem'
  },
  paper: {
    position: 'absolute',
    // width: 776,
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`
  },
  frame: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    position: 'static',
    // width: '776px',
    left: 30,
    top: 30,
    flex: 'none',
    order: 0,
    alignSelf: 'center',
    margin: '0px 0px'
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '27px',
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'center',
    letterSpacing: '-0.04em',
    color: 'rgba(0, 0, 0, 0.8)',
    flex: 'none',
    order: 0,
    alignSelf: 'center',
    margin: '0 10px',
    position: 'static',
    height: 27,
    left: 0,
    top: 0
  },
  subtitle: {
    position: 'static',
    height: 19,
    width: 300,
    left: 0,
    top: 37,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '-0.04em',
    color: 'rgba(0, 0, 0, 0.65)',
    flex: 'none',
    alignSelf: 'center',
    margin: '10px 0px'
  },
  clearIcon: {
    position: 'absolute',
    top: 8,
    right: 8,
    cursor: 'pointer'
  },
  activeTab: {
    backgroundColor: theme.colors.blueCompany,
    color: theme.colors.white
  },
  nonActiveTab: {
    backgroundColor: 'white',
    color: theme.colors.text.primary
  },
  paymentOptionsTabs: {
    display: 'flex',
    listStyle: 'none',
    justifyContent: 'flex-start',
    margin: '2.5rem 0 0 0',
    padding: 0,
    '& li': {
      padding: '1.5rem 2.5rem',
      cursor: 'pointer'
    }
  }
}));

export default createStyles;
