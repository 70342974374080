import * as Yup from 'yup';
import { validate as validateCPF } from 'gerador-validador-cpf';
import moment from 'moment'
import 'moment/locale/pt-br'

// Locale.setDefault(new Locale("pt", "BR"));
moment.locale('pt-br')
const getFormatedDate = (currentDate) => {
  return currentDate.split('/').reverse().join('-');
 }

const rgRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const rgRegexMothersName = /^([a-zA-Zà-úÀ-Ú]|-|_|\s)+$/
const dateRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/

const loginForm = {
  name: Yup.string().required('Este campo é obrigatório'),
  lastname: Yup.string().required('Este campo é obrigatório'),
  rg: Yup.string().required('Este campo é obrigatório').matches(rgRegex,'RG no formato errado.'),
  birthDate: Yup.string().required('Este campo é obrigatório')
  .test('birthDate', 'Data de nascimento inválida', function(value) {
    return value && value.match(dateRegex) &&
          moment(value, "DD/MM/YYYY").isBefore(moment(new Date, "DD/MM/YYYY")) && 
          moment(value, "DD/MM/YYYY").isAfter(moment('01/01/1900', "DD/MM/YYYY"));
  }),
  rgEmissionDate: Yup.string().required('Este campo é obrigatório')
  .test('rgEmissionDate', 'Data de emissão inválida', function(value) {
    return value && value.match(dateRegex) &&
          moment(value, "DD/MM/YYYY").isBefore(moment(new Date, "DD/MM/YYYY")) && 
          moment(value, "DD/MM/YYYY").isAfter(moment('01/01/1900', "DD/MM/YYYY"));
  }),
  addressNumber: Yup.string().required('Este campo é obrigatório').test('addressNumber', 'Número inválido. Favor informar apenas números ou "s/n" caso não possua número', function (value) {
    return value && (
      value.toLowerCase() === "s/n" || 
      (!isNaN(value) && !isNaN(parseInt(value))) // verify if it is numeric
    );
  }),
  // hometown: Yup.string().required('Este campo é obrigatório'),
  municipality: Yup.string().required('municipality'),
  cep: Yup.string().required('Este campo é obrigatório')
    .test('cep', 'Este campo é obrigatório', function(value) {
      // needed because placeholder `_____-___` counts as a non-empty string for required check above
      return value && value.replace(/_|-/g, '');
    }),
  state: Yup.string().required('Este campo é obrigatório'),
  city: Yup.string().required('Este campo é obrigatório'),
  neighbourhood: Yup.string().required('Este campo é obrigatório'),
  address: Yup.string().required('Este campo é obrigatório'),
  situacaoOcup: Yup.string().required('Este campo é obrigatório'),
  // rgUf: Yup.string().required('Este campo é obrigatório'),
  rgEmitter: Yup.string().required('Este campo é obrigatório'),
  homestate: Yup.string().required('Este campo é obrigatório'),
  mothersName: Yup.string().required('Este campo é obrigatório').matches(rgRegexMothersName, 'Este campo não permite números e símbolos'),
  maritalStatus: Yup.string().required('Este campo é obrigatório'),
  scholarity: Yup.string().required('Este campo é obrigatório'),
  phone1: Yup.string().test('phone1', 'Este campo é obrigatório', function(value) {
    return (value !== undefined ? value.replace(/_|-|\(|\)|\s/g, '') : '').length >= 10;
  }),
  email: Yup.string()
    .required('Este campo é obrigatório')
    .email('E-mail com formato errado'),
  cpf: Yup.string()
    .required('Este campo é obrigatório')
    .test('cpf', 'CPF inválido', function(value) {
      return validateCPF(value);
    }),
  password: Yup.string()
    .required('Este campo é obrigatório')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?_&])[A-Za-z\d@$!%*#?_&]{8,}$/,
      "A senha deve ter no mínimo oito caracteres, contendo letra maiúscula, minúscula, números e um caractere especial"
    ).min(8, 'A senha deve conter no mín. 8 caracteres'),
  confirmPassword: Yup.string()
    .required('As senhas devem ser iguais')
    .oneOf(
    [Yup.ref('password'), null],
    'As senhas devem ser iguais'
  )
};

export const registerFormValidator = Yup.object().shape(loginForm);
