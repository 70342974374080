import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import createStyles from './PaymentMethodCard.styles';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MastercardIcon from 'assets/icons/mastercard-logo-7 1.png';
import VisaIcon from 'assets/icons/visa-logo.png';
import DeleteModal from 'components/DeleteModal';
import AddCardModal from 'modules/PaymentMethods/AddCardModal';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const StyledCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#32559D;',
    },
  },
  checked: {},
  body1: {
    fontFamily: 'Open Sans', 
    fontStyle: 'normal', 
    fontWeight: 'normal', 
    fontSize: '14px', 
    lineHeight: '19px'
  }
})((props) => <Checkbox color="default" {...props} />);


const PaymentMethodCard = props => {

  const mediaQuery = {
    isPhone: useMediaQuery('(max-width:450px)'),
    isMobile: useMediaQuery('(max-width:814px)')
  }
  const classes = createStyles(mediaQuery);

  const [state, setState] = React.useState({ paymentSelected: false });
  const [ isOpen, setOpenModal ] = React.useState(false);
  const [ isAdding, setOpenAddingModal ] = React.useState(false);


  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleClose = () => {
    setOpenModal(false);
  }

  const handleAddingClose = () => {
    setOpenAddingModal(false);
  }
 

  return (
    <Card className={classes.root}> 
      {props.data ?
        (
          <CardContent className={classes.container}>
              <div className={classes.cardContainer} key={props.data.id}>
                <div className={classes.cardName}>
                {props.data.flag == 'Mastercard' ? 
                    ( <img src={MastercardIcon}/>) :
                    ( <img src={VisaIcon}/>)
                }
                <Typography variant="subtitle1" style={{color: 'rgba(0, 0, 0, 0.8)'}}>
                  {props.data.flag} •••• {props.data.number}
                  </Typography>
                </div>
                <DeleteForeverIcon style={{cursor: 'pointer'}} onClick={setOpenModal}/>
              </div>

              <div className={classes.cardInfoContainer}>
                <Typography variant="body1" style={{fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.8)'}}>{props.data.fullName}</Typography>
                <Typography variant="body1" style={{color: 'rgba(0, 0, 0, 0.8)'}}>Validade: {props.data.formatedDate} </Typography>
              </div>
              
              <FormControlLabel style={{position: 'relative', top: '15%'}}
                control={
                  <StyledCheckbox
                    checked={state.paymentSelected}
                    onChange={handleChange}
                    name="paymentSelected"
                    checked={props.data.paymentSelected}
                  />
                }
                label={<Typography variant="body2">Usar como forma de pagamento padrão</Typography>}
            />
             <DeleteModal 
                open={isOpen} 
                handleClose={handleClose} 
                data={props.data} 
                title="forma de pagamento"
                handleDeleteMethod={props.delete}/>
          </CardContent>
        ) : (

          <CardContent className={classes.newContainer}>
            {/* <div className={classes.content}  key={0}> */}
              <AddOutlinedIcon fontSize="large" className={classes.addIcon} onClick={setOpenAddingModal}/>
              <Typography variant="subtitle1" className={classes.title} >Adicionar cartão</Typography>
            {/* </div> */}
          </CardContent>
        )
      }
      
      <AddCardModal open={isAdding} handleClose={handleAddingClose}/>
    </Card>
  );
};

PaymentMethodCard.propTypes = {};

export default PaymentMethodCard;
