import request from 'shared/request';
import axios from 'axios';

const url = process.env.REACT_APP_API_URL || 'https://senai-gatewayd.exablack.com/api/';

export const create = async (course) => {
  try {
    const response = await request.post('/carts', course);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const applyVoucher = async (code) => {
  try {
    const response = await request.put(`/carts/mine/coupons/${code}`);
    return response.data;
  } catch (error) {
    //TODO
  }
};

export const sale = async (data) => {
  try {
    const response = await request.post('/carts/sale', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const billetSale = async (data) => {
  try {
    const response = await request.post('/carts/sale/billet', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBillet = async (data) => {
  try {
    const requestPdf = axios.create({
      baseURL: `${url}`,
      headers: { 'Content-Type': 'application/json'},
      responseType: 'arraybuffer'
    });
    const response = await requestPdf.post('/boletobb/imprimir', data).then (response => {
      const type = response.headers['content-type']
      const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'boleto.pdf'
      link.click()
  })
    return response;
  } catch (error) {
    throw error;
  }
};

export const getItemsCart = async () => {
  try {
    const response = await request.get('/carts/mine/items');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTotalsCart = async () => {
  try {
    const response = await request.get('/carts/mine/totals');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removeVoucher = async (code) => {
  try {
    const response = await request.delete('/carts/mine/coupons/', {code: code});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteItem = async (sku) => {
  try {
    const response = await request.post('/carts/mine/items/remove', {sku: sku});
    return response.data;
  } catch (error) {
    throw error;
  }
};
