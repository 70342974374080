import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from 'components/ButtonPrimary';
import { Typography, Checkbox, InputLabel } from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';
import {getCEP} from 'shared/util/GetCEP';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import createStyles from './RegisterBoxForm.styles';

const LoginForm = (props) => {
  const classes = createStyles();
  const {
    errors,
    touched,
    values,
    isLoading,
    handleChange,
    handleBlur,
    handleSubmit,
    loginErrors,
    setFieldValue,
    setFieldTouched,
  } = props;

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [ value, setValue] = useState('Indústria');
  const visibilityIcon = isPasswordVisible ? <Visibility /> : <VisibilityOff />;
  const [formError, setFormError] = useState({});

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    values.industry = event.target.value;
  };


  const clientTypeOptions = [
    { value: '1', label: 'Tipo de cliente 1' },
    { value: '2', label: 'Tipo de cliente 2' },
    { value: '3', label: 'Tipo de cliente 3' }
  ];

  useEffect(() => {
    let inputCEP = '';

    if (values.cep) {
      inputCEP = values.cep.replace(/_|-/g, '');
    }

    if (inputCEP.length === 0) {
      setFormError({ cep : false });
    }
    else if (inputCEP.length > 0 && inputCEP.length !== 8) {
      setFormError({ cep : true });
    }
    else if (inputCEP.length === 8) {
      getCEP(inputCEP).then(
        (response) => {
          values.address = response.address;
          values.neighbourhood = response.neighbourhood;
          values.city = response.city;
          values.state = response.state;
          values.ibge = response.ibge;
          setFormError({ cep : false });
        },
        () => {
          values.address = "";
          values.neighbourhood = "";
          values.city = "";
          values.state = "";
          values.ibge = "";
          setFormError({ cep : true });
        });
    }
  }, [values.cep]);


  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Input
        placeholder=''
        label='Nome Fantasia'
        type='text'
        name='tradeName'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.tradeName}
        // className={classes.input}
        customClasses={{ root: classes.input }}
        error={touched.name ? errors.name : null}
      />
      <Input
        placeholder=''
        label='Razão Social'
        type='text'
        name='legalName'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.legalName}
        // className={classes.input}
        customClasses={{ root: classes.input }}
        error={touched.name ? errors.name : null}
      />
      <Input
        placeholder='Ex.: 12.345.678/9123-00'
        mask={'99.999.999/9999-99'}
        label='CNPJ'
        type='text'
        name='cnpj'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.cnpj}
        customClasses={{ root: classes.input }}
        error={touched.cnpj ? errors.cnpj : null}
      />
    <FormControl component="fieldset">
      <FormLabel className={classes.label} component="legend">Indústria</FormLabel>
      <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleRadioChange} style={{marginBottom: '1.6rem'}}>
        <FormControlLabel className={classes.label}   value="industry" control={<Radio />} label="Indústria" />
        <FormControlLabel className={classes.label}  value="industryA" control={<Radio />} label="Indústria associada ou contribuinte" />
        <FormControlLabel className={classes.label}  value="nonindustry" control={<Radio />} label="Não indústria" />
      </RadioGroup>
    </FormControl>


      <Typography
        variant='subtitle1'
        align='left'
        className={classes.addressLabel}
      >Endereço </Typography>


      <Input
        placeholder='Ex: 11023-456'
        mask={'99999-999'}
        label='CEP'
        type='text'
        name='cep'
        helperText='Seu endereço aparecerá automaticamente.'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.cep}
        customClasses={{ root: classes.input }}
        error={formError.cep ? 'Esse CEP não existe.' : touched.cep ? errors.cep : null}
      />
      <Input
          label='Estado'
          type='text'
          name='state'
          disabled={true}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.state}
          customClasses={{ root: classes.input }}
          error={touched.state ? errors.state : null}
        />

        <Input
          label='Cidade'
          type='text'
          name='city'
          disabled={true}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.city}
          customClasses={{ root: classes.input }}
          error={touched.city ? errors.city : null}
        />

        <Input
            label='Bairro'
            type='text'
            name='neighbourhood'
            disabled={true}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.neighbourhood}
            customClasses={{ root: classes.input }}
            error={touched.neighbourhood ? errors.neighbourhood : null}
          />

          <Input
            label='Endereço'
            type='text'
            name='address'
            disabled={true}
            helperText=' '
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address}
            customClasses={{ root: classes.input }}
            error={touched.address ? errors.address : null}
          />

          <Input
            placeholder='Ex: 250'
            label='Número'
            type='number'
            name='number'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.number}
            customClasses={{ root: classes.input }}
            error={touched.number ? errors.number : null}
          />

          <Input
            placeholder='Ex: Condomínio ABC, Bloco A, APT 202'
            label='Complemento'
            type='text'
            name='complement'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.complement}
            customClasses={{ root: classes.input }}
            error={touched.complement ? errors.complement : null}
          />
     
      <Input
        placeholder='Ex.: joao.silva@gmail.com'
        label='E-mail'
        name='email'
        type='text'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        // className={classes.input}
        customClasses={{ root: classes.input }}
        error={touched.email ? errors.email : null}
      />

    <Input
        placeholder='Ex.: João da Silva'
        label='Nome para contato'
        type='text'
        name='name'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.name}
        // className={classes.input}
        customClasses={{ root: classes.input }}
        error={touched.name ? errors.name : null}
      />
      <Input
        placeholder='(27) 99999-9999'
        label='Telefone'
        type='text'
        name='phone'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.phone}
        customClasses={{ root: classes.input }}
        mask={'(99) 9 9999-9999'}
      />

      <Input
          placeholder='(27) 99999-9999'
          label='Segundo telefone (opcional)'
          type='text'
          name='phone'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phone}
          customClasses={{ root: classes.input }}
          mask={'(99) 9 9999-9999'}
        />
      <div className={classes.actions}>
        <Typography
          className={classes.checkboxLabel}
          variant='body2'
          color='textSecondary'
        >
          <Checkbox
            size='medium'
            color='primary'
            inputProps={{ 'aria-label': 'quero receber ofertas' }}
            name='subscribeOffers'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.subscribeOffers}
            checked={values.subscribeOffers}
            className={classes.checkbox}
          />
          Quero receber ofertas e novidades por e-mail, WhastApp e contato telefônico.
        </Typography>
      </div>
      {loginErrors && (
        <FormHelperText className={classes.errorsText} error>
          {loginErrors}
        </FormHelperText>
      )}
      <Button lowercase isLoading={isLoading} type='submit' width='100%'>
        Salvar alterações
      </Button>
    </form>
  );
};

export default LoginForm;
