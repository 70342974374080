import React, { useEffect, useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Toolbar, AppBar } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import FavoriteIcon from '@material-ui/icons/Favorite';
import MainContainer from 'components/MainContainer';
import MenuIcon from '@material-ui/icons/Menu';
import UserMenu from './UserMenu';
import MobileMenu from './MobileMenu/MobileMenu';
import SenaiLogoBranco from 'assets/icons/SenaiLogoBranco.svg';
import NavSearch from './NavSearch/NavSearch';
import SearchFilter from 'components/SearchFilter';
import { Link, useHistory } from 'react-router-dom';
import { getSearchFilterUrl } from 'shared/util/UrlTransformation';

import createStyles from './NavBar.styles';

const NavBar = (props) => {
  const {
    searchTerm,
    searchQuery,
    isAuthenticated,
    openLoginBox,
    fetchFilters,
    searchFilters,
    session,
    categories
  } = props;

  const history = useHistory();
  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const navRef = useRef(null);
  const searchRef = useRef(null);
  const size = useWindowSize();

  const classes = createStyles({ state: { isUserMenuOpen } });

  useEffect(() => {
    if (searchFilters && searchFilters.length === 0) {
      fetchFilters();
    }
    setIsSearchOpen(isSearchOpen);
  });

  const updateFilters = (filters) => {
    setFilters({ ...filters });
  };

  function useWindowSize() {
    const isClient = typeof window === 'object';

    function getSize() {
      return {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined
      };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {

      if (!isClient) {
        return false;
      }

      function handleResize() {
        setWindowSize(getSize());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
  }

  const handleSearch = (searchTerm) => {
    const urlToRedirect = getSearchFilterUrl(filters, searchTerm);
    history.push(urlToRedirect);
  };

  const handleFavoritesClick = () => {
    if (isAuthenticated) {
      history.push('/favoritos');
    } else {
      openLoginBox();
    }
  };

  return (
    <div className={classes.rootNavBar} ref={navRef}>
      <AppBar className={classes.appBar} position='static'>
        <MainContainer className={classes.mainContainer}>
          <Toolbar className={classes.toolBar}>
            <Link to='/'>
              <img
                src={SenaiLogoBranco}
                alt='Logo SENAI'
                className={classes.logo}
              />
            </Link>
            <div className={classes.menuMobile}>
              <IconButton
                onClick={() => setIsMenuMobileOpen(true)}
                color='inherit'
                aria-label='open menu'
                component='span'
                className={classes.menuMobile}
              >
                <MenuIcon className={classes.menuIcon} />
              </IconButton>
            </div>
            {size.width > 980 && (
              <NavSearch
                searchTerm={searchTerm}
                isOpen={true}
                searchRef={searchRef}
                setIsSearchOpen={setIsSearchOpen}
                handleSearch={handleSearch}
              />
            )}
            <div className={classes.userMenuContainer}>
              <UserMenu
                className={classes.userMenuItem}
                isLogged={isAuthenticated}
                isUserMenuOpen={isUserMenuOpen}
                setIsUserMenuOpen={setIsUserMenuOpen}
              />
              <FavoriteIcon
                onClick={handleFavoritesClick}
                color='inherit'
                className={classes.userMenuItem + ' ' + classes.icon}
              />
              <Link className={classes.link} to={'/carrinho/itens'}>
                <ShoppingCartIcon
                  color='inherit'
                  className={classes.userMenuItem + ' ' + classes.icon}
                />
              </Link>
            </div>
          </Toolbar>
          {size.width <= 980 && (
            <div className={classes.searchMobileContainer}>
              <NavSearch
                searchTerm={searchTerm}
                isOpen={true}
                searchRef={searchRef}
                setIsSearchOpen={setIsSearchOpen}
                handleSearch={handleSearch}
                classes={{
                  root: classes.searchMobile,
                  input: classes.searchMobile
                }}
              />
            </div>
          )}
        </MainContainer>
        <SearchFilter
          searchFilters={searchFilters}
          isOpen={isSearchOpen}
          onFiltersChange={updateFilters}
          handleClose={() => setIsSearchOpen(false)}
          searchQuery={searchQuery}
        />
      </AppBar>
      <MobileMenu
        toggleDrawer={setIsMenuMobileOpen}
        isOpen={isMenuMobileOpen}
        categories={categories}
      />
    </div>
  );
};

NavBar.propTypes = {
  isAuthenticated: PropTypes.bool
};

export default NavBar;
