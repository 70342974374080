import React from 'react'; 
import createStyles from './ConfirmationPJ.styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Typography, Card, CardContent } from '@material-ui/core';
import Button from 'components/ButtonPrimary';
import ButtonClass from 'components/ButtonClass';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';



const ConfirmationPJ = () => {

    const classes = createStyles();

    function createData(name, openPlaces, value, total) {
        return { name, openPlaces, value, total};
      }
      
    const rows = [
        createData('“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”', 1, 100, 100),
    ];

    const goTo = (link) => {
        window.open(link, '_self')
    }
      

    return (
        <>
            
            <Typography className={classes.warning}>Atenção: Os vouchers serão entregues somente após a confirmação do pagamento</Typography>

            <div className={classes.btn}>
                <ButtonClass buttonText="Continuar comprando"  style={{margin: '0 8px'}} onClick={() => goTo('/')}></ButtonClass>
                <Button buttonText="Distribuir vouchers" onClick={() => goTo('/minha-conta/vouchers')} ></Button>
            </div>

            <Card>
                <CardContent classes={{root: classes.cardContent}}>
                    <Typography variant="subtitle1">Detalhe da compra</Typography>
                    

                    <TableContainer component={Paper} classes={{root: classes.table}}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell align="left"><Typography variant="subtitle2">Curso comprado</Typography></TableCell>
                                <TableCell align="left"><Typography variant="subtitle2">Qtd. vagas</Typography></TableCell>
                                <TableCell align="left"><Typography variant="subtitle2">Valor unitário</Typography></TableCell>
                                <TableCell align="left"><Typography variant="subtitle2">Total</Typography></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.name}>
                                        <TableCell component="th" scope="row" classes={{root: classes.tableLine}}>
                                            <Typography variant="subtitle2">{row.name}</Typography>
                                        </TableCell>
                                        <TableCell classes={{root: classes.tableLine}} align="left">
                                         <Typography variant="body1"> {row.openPlaces}</Typography>
                                        </TableCell>
                                        <TableCell classes={{root: classes.tableLine}} align="left">
                                            <Typography variant="body1"> { 'R$' + row.value} </Typography>
                                        </TableCell>
                                        <TableCell classes={{root: classes.tableLine}} align="left">
                                            <Typography variant="body1"> {'R$' + row.total} </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>



                    <div className={classes.details}>

                        <div>
                            <Typography variant="body1" className={classes.detailTitle}>Empresa Ficticia</Typography>
                            <Typography variant="body1">CNPJ: 12.345.678/9000-11</Typography>
                            <Typography variant="body1">Telefone: (99) 99999-9999</Typography>
                            <Typography variant="body1">Email: contato@empresa.com</Typography>
                        </div>

                        <div>
                            <Typography variant="body1" className={classes.detailTitle}>Endereço</Typography>
                            <Typography variant="body1" style={{width: '90%'}}>Rua do Fictícia de Santos, 400. Coídomínio do João - APT 505</Typography>
                            <Typography variant="body1">Jardim da Penha</Typography>
                            <Typography variant="body1">Vitória - ES</Typography>
                            <Typography variant="body1">CEP: 11023-456</Typography>
                        </div>

                        <div>
                            <Typography variant="body1" className={classes.detailTitle}>Forma de pagamento</Typography>
                            <Typography variant="body1">Cartão de crédito:</Typography>
                            <Typography variant="body1">Mastercard **** 8415</Typography>
                            <Typography variant="body1">Validade: 31/12/2027 </Typography>
                            <Typography variant="body1">Parcelas: 1x de R$ 100,00 sem juros</Typography>
                        </div>
                    </div>
                </CardContent>
            </Card>


            <div className={classes.btn}>
                <ButtonClass buttonText="Continuar comprando" style={{margin: '0 8px'}} onClick={() => goTo('/')}></ButtonClass>
                <Button buttonText="Distribuir vouchers" onClick={() => goTo('/minha-conta/vouchers')} ></Button>
            </div>
        </>
    )
}

export default ConfirmationPJ;