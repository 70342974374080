import SearchFilter from './SearchFilter.jsx';
import { connect } from 'react-redux';
import { addFilter, removeFilter } from 'modules/Search/Search.ducks.js';

const mapStateToProps = (state) => {
  return {
    filters: state.search.filters
  };
}

const mapDispatchToProps = {
  addFilter,
  removeFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter);
