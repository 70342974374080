import MyCourses from './MyCourses.view';
import { connect } from 'react-redux';
import { fetchMyCourses } from 'modules/MyCourses/MyCourses.ducks.js';

const mapStateToProps = state => {
  return {
    myCourses: state.myCourses.myCourses,
    isLoading: state.myCourses.isLoading, 
    user: state.session.user
  };
};

const mapDispatchToProps = {
  fetchMyCourses
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCourses);
