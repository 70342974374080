import React, { useEffect } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import CourseCard from 'components/CourseCard';
import CircularProgress from '@material-ui/core/CircularProgress';
import MainContainer from 'components/MainContainer';
import CustomTitle from 'components/CustomTitle'
import createStyles from './Favorites.styles';
import { getCustomAttribute } from 'shared/util/ProductCustomAttribute';
import { toUrlFriendly } from 'shared/util/textTransformation';

const Favorites = props => {
  const {
    fetchFavorites,
    favorites,
    isLoading 
  } = props;

  const classes = createStyles(props);

  const breadcrumbs = [
    { label: 'Início', to: '/' },
    { label: 'Favoritos', to: '' },
  ];

  useEffect(() => {
    fetchFavorites();
  }, []);

  return (
    <React.Fragment>
      <Breadcrumbs items={breadcrumbs} />

      {isLoading ? (
        <div className={classes.rootLoading}>
          <CircularProgress />
        </div>
      ) : (
          <MainContainer>
            <CustomTitle
              className={classes.title}
              title='SEUS FAVORITOS'
              subtitle='SENAI'
            />
            <div className={classes.container}>
              {favorites && favorites.map(course => {
                return (
                  <div className={classes.course} key={course.id}>
                    <CourseCard
                      id={course.id}
                      image={course.image}
                      areaTag={getCustomAttribute(course.classes[0].product, 'area')}
                      title={course.title}
                      place={course.classes[0].place}
                      duration={parseInt(course.classes[0].duration)}
                      courseClasses={course.hasOpenChairs}
                      price={course.price}
                      modalidade={course.presencialDistancia}
                      parcels={course.parcels == 0 ? '' : course.parcels}
                      link={`/curso/${toUrlFriendly(course.title)}/${course.sku}`}
                      favorite={course.favorite}
                      discount={course.discount}
                      startDate={getCustomAttribute(course.classes[0].product, 'start_date')}
                      priceWithDiscount={course.priceWithDiscount}
                      urlNacional={course.nacionalUrl}
                     sku={course.sku}
                    />
                  </div>

                );
              })}
            </div>
          </MainContainer>
        )}
    </React.Fragment>
  );
};

export default Favorites;
