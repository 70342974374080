import React, { useState, useEffect, useRef } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import Carousel from '../../components/Carousel/Carousel';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MainContainer from 'components/MainContainer';
import { Typography } from '@material-ui/core';
import theme from 'App.theme.js';
import createStyles from './CourseDetails.styles';
import CourseBanner from '../../components/CourseBanner/CourseBanner';
import CourseDescriptionContainer from '../../components/CourseDetailsContainer/CourseDestailsContainer';
import CourseDetailsCard from '../../components/CourseDetailsCard/CourseDetailsCard';
import { useParams } from 'react-router-dom';
import { addProductToHistory, getHistory } from 'shared/util/LocalStorage';
import { getCustomAttribute } from 'shared/util/ProductCustomAttribute';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const CourseDetails = (props) => {
  const { sku } = useParams();
  const {
    fetchCourseDetails,
    resetInterest,
    addItemToCart,
    deleteItemInCart,
    isAuthenticated,
    user,
    setFavorite,
    success,
    loading,
    items,
    cartError,
    clearErrorCart,
    openLoginBox
  } = props;

  const {
    course,
    relatedCourses,
    navigationHistory,
    isLoadinCourseDetail,
  } = props.courseDetails;

  const applyMetas = (course) => {
    const metaDescription = document.querySelector('meta[name="description"]');
    const metakeywords = document.querySelector('meta[name="keywords"]');
    const metaTitle = document.querySelector('meta[name="title"]');
    metaDescription.setAttribute('content', course.metaDescription);
    metakeywords.setAttribute('content', course.metaKeywords);
    metaTitle.setAttribute('content', course.metaTitle);
  }

  const [descriptionHeight, setDescriptionHeight] = useState(0);
  const descriptionRef = useRef(null);

  const classes = createStyles(descriptionHeight);
  const historyBrowser = useHistory();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const breadcrumbs = [
    { label: 'Início', to: '/' },
    { label: 'Curso' },
    { label: (course && !isLoadinCourseDetail) ? course.title : 'Carregando...'}
  ];

  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    type: "",
    msg: ""
  });

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchCourseDetails(sku);
  }, [sku]);

  useEffect(() => {
    const updateHeight = () => {
      if (descriptionRef.current) {
        setDescriptionHeight(descriptionRef.current.offsetHeight + 250);
      }
    };
    window.addEventListener('resize', updateHeight);
    updateHeight();
    return () => window.removeEventListener('resize', updateHeight);
  });

  const [history, setHistory] = useState([]);

  useEffect(() => {
    setHistory(getHistory(sku));
    addProductToHistory(course);
    applyMetas(course)
  }, [sku, course]);

  const convertToCarouselProduct = (products) => {
    const carouselProducts = products.map((product) => {
      return {
        id: product.id,
        image: product.image,
        areaTag: product.areaTag,
        title: product.title,
        unit: product.unit,
        duration: product.duration,
        hasOpenChairs: product.hasOpenChairs,
        classes: product.classes,
        price: product.price,
        parcels: product.parcels == 0 ? '' : product.parcels,
        presencialDistancia: product.presencialDistancia,
        favorite: product.favorite,
        discount: product.discount,
        place: product.place,
        priceWithDiscount: product.priceWithDiscount,
        sku: product.sku,
        startDate: product.startDate
      };
    });

    return carouselProducts;
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    resetInterest()
    setOpenSnack(false);
  };

  useEffect(() => {
    setSnack({
      type: "success",
      msg: "Informações atualizadas com sucesso!"
    })
    setOpenSnack(success);
  }, [success]);

  useEffect(() => {
    if(cartError.message === "Request failed with status code 400"){
      historyBrowser.push('/carrinho/step:1')
      // /carrinho/step:1
      // setSnack({
      //   type: "warning",
      //   msg: "Este curso já está adicionado no carrinho!"
      // });
      // setOpenSnack(true)
      // clearErrorCart()
    }
  }, [cartError]);
  
  return (
    <React.Fragment>
      <Breadcrumbs items={breadcrumbs} />
      {isLoadinCourseDetail ? (
        <div className={classes.rootLoading}>
          <CircularProgress />
        </div>
      ) : (
        <>
        {course && <div className={classes.root}>
          <CourseBanner title={course.title} backgroundImage={course.bannerImage} />
          <MainContainer>
            <div className={classes.courseDetailsCardContainer}>
              <div className={classes.courseDetailsCardSticky}>
                <CourseDetailsCard
                  items={items}
                  course={course}
                  gallery={course.gallery}
                  addItem={addItemToCart}
                  deleteItemInCart={deleteItemInCart}
                  isAuthenticated={isAuthenticated}
                  user={user}
                  setFavorite={setFavorite}
                  loading={loading}
                  openLoginBox={openLoginBox}
                />
              </div>
            </div>
            <CourseDescriptionContainer
              className={classes.descriptionContainer}
              descriptionRef={descriptionRef}
            >
              <Typography className={classes.description} variant='subtitle1'>
                {() =>
                  getCustomAttribute(course.classes[0].product, 'description')
                }
              </Typography>
              {course.details &&
                course.details.map((detail) => {
                  return (
                    <React.Fragment>
                      <div className={classes.sectionContainer}>
                        {detail.title && (
                          <Typography
                            className={classes.sectionTitle}
                            variant='h6'
                          >
                            {detail.title}
                          </Typography>
                        )}
                        {detail.content && (
                          <pre className={classes.subSectionItem} variant='p' dangerouslySetInnerHTML={ { __html: detail.content } } />
                        )}
                      </div>
                    </React.Fragment>
                  );
                })}
            </CourseDescriptionContainer>
            {relatedCourses && relatedCourses.length > 0 && (
              <Carousel
                title='Cursos Relacionados'
                courses={convertToCarouselProduct(relatedCourses)}
              />
            )}
            {history && history.length > 0 && (
              <Carousel
                title='Histórico de Navegação'
                courses={convertToCarouselProduct(history)}
              />
            )}
          </MainContainer>
        </div>
        }
        </>
      )}
          <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
            <Alert onClose={handleCloseSnack} severity={snack.type}>
              {snack.msg}
            </Alert>
          </Snackbar>
    </React.Fragment>
  );
};

export default CourseDetails;
