import Politicas from './Politicas.view';
import { connect } from 'react-redux';
import { fetchPoliticaCompras } from './Politicas.ducks'

const mapStateToProps = state => {
  return {
    politicaCompras: state.policy.politicaCompras,
    isLoading: state.policy.isLoading,
  };
};

const mapDispatchToProps = {
  fetchPoliticaCompras
};

export default connect(mapStateToProps, mapDispatchToProps)(Politicas);
