import React from 'react';
import createStyles from './ChangePasswordModal.styles';
import Modal from '@material-ui/core/Modal';
import { Typography } from '@material-ui/core';
import ChangePasswordForm from './Form/ChangePasswordForm.formik';
import ClearIcon from '@material-ui/icons/Clear';
import SuccessModal from '../SuccessModal';
import ForgotPasswordModal from 'components/AuthenticationBox/LoginBox/ForgotPasswordModal';


function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    paddingBottom: "100px"
  };
}


const ChangePasswordModal = props => {
  const classes = createStyles(props);

  const {
    handlePasswordChange,
    isEditing,
    errors,
    afterPasswordChange, 
  } = props;

  const [ isSuccess, setSuccess ] = React.useState(false);
  const [ isOpen, setOpen ] = React.useState(false);
  const [ modalStyle ] = React.useState(getModalStyle);

  const handleClose = () => {
    setSuccess(true);
    props.handleClose();
  }

  const handleCloseSuccess = () => {
    setSuccess(false);
  }

  const handleCloseRecoverPass = () => {
    setOpen(false);
  }

  const body = (
    <div style={modalStyle} className={classes.paper}>
       <ClearIcon className={classes.clearIcon} onClick={props.handleClose}/>
      <div className={classes.frame}>
        <Typography className={classes.title} variant="subtitle1">Alterar senha</Typography>
        <Typography className={classes.subtitle} variant="subtitle2">Crie uma nova senha abaixo</Typography>
        
        <ChangePasswordForm 
          handlePasswordChange={handlePasswordChange}
          handleClose={handleClose}
          handleOpenRecoverPass={setOpen}
          isLoading={isEditing}
          errors={errors}
          afterPasswordChange={afterPasswordChange}/>
      </div>

      <ForgotPasswordModal
          open={isOpen}
          handleClose={handleCloseRecoverPass}
        />              
    </div>
  );

  return (
      <div>
          <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>
         
        <SuccessModal open={isSuccess} 
                      height="283px"
                      handleClose={handleCloseSuccess} 
                      buttonTitle="FECHAR" 
                      title="Senha alterada"
                      isPassword={true}/>

       
      </div> 
  );
};


export default ChangePasswordModal;
