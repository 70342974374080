import CartBody from './CartBody';
import { connect } from 'react-redux';
import { fetchFavorites } from 'modules/Favorites/Favorites.ducks.js';

// const mapStateToProps = (state) => {
//   return {
//     favorites: state.favorites.favorites,
//     isLoading: state.favorites.isLoading
//   };
// };

// const mapDispatchToProps = {
//   fetchFavorites
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
export default connect(null, null)(CartBody);
