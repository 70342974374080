import PaymentMethods from './PaymentMethods.view';
import { connect } from 'react-redux';
import { fetchPaymentMethod } from 'modules/PaymentMethods/PaymentMethods.ducks.js';
import { handleDeletePaymentMethod } from 'modules/PaymentMethods/PaymentMethods.ducks.js';

const mapStateToProps = state => {
  return {
    paymentMethods: state.paymentMethods.paymentMethods,
    isLoading: state.paymentMethods.isLoading
  };
};

const mapDispatchToProps = {
  fetchPaymentMethod, 
  handleDeletePaymentMethod
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);
