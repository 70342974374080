import { makeStyles } from '@material-ui/core/styles';


const createStyles = makeStyles(theme => ({
    root: {
        position: 'absolute'
    }
}));


export default createStyles;