import CourseDetails from './CourseDetails.view';
import { connect } from 'react-redux';
import { fetchCourseDetails } from 'modules/CourseDetails/CourseDetails.ducks.js';
import { addItemToCart , deleteItemInCart, clearErrorCart } from 'modules/Cart/Cart.ducks.js';
import { setFavorite  } from 'modules/Favorites/Favorites.ducks.js';
import {resetInterest } from 'modules/Registration/Registration.ducks'
import { openLoginBox, closeLoginBox, session } from 'modules/Login/Login.ducks.js';

const mapStateToProps = state => {

  return {
    courseDetails: state.courseDetails,
    isAuthenticated: state.session.isAuthenticated,
    user: state.session.user,
    success: state.userRegistration.success,
    loading: state.cart.isLoading,
    items: state.cart.items,
    cartError: state.cart.cartError
  };
};

const mapDispatchToProps = {
  fetchCourseDetails,
  addItemToCart,
  deleteItemInCart,
  setFavorite,
  resetInterest,
  clearErrorCart,
  openLoginBox,
  closeLoginBox
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);
