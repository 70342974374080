import { getPoliticaCompras } from 'api/Politicas';

// Actions
const FETCH_POLITICA_COMPRAS_START = 'sesi/policy/FETCH_POLITICA_COMPRAS_START';
const FETCH_POLITICA_COMPRAS_SUCCESS = 'sesi/policy/FETCH_POLITICA_COMPRAS_SUCCESS';
const FETCH_POLITICA_COMPRAS_FAILURE = 'sesi/policy/FETCH_POLITICA_COMPRAS_FAILURE';
const POLITICA_COMPRAS_RESET = 'sesi/policy/POLITICA_COMPRAS_RESET';

// Reducer
const initialState = {
  politicaCompras: null,
  isLoading: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_POLITICA_COMPRAS_START:
      return { ...state, isLoading: true };
    case FETCH_POLITICA_COMPRAS_SUCCESS:
      return { ...state, politicaCompras: action.payload, isLoading: false };
    case FETCH_POLITICA_COMPRAS_FAILURE:
      return { ...state, ...action.payload };
    case POLITICA_COMPRAS_RESET:
      return { ...state, ...initialState };
    default:
      return state;
  }
}

// Action Creators
export function fetchPoliticaComprasStart() {
  return { type: FETCH_POLITICA_COMPRAS_START };
}

export function fetchPoliticaComprasSuccess(data) {
  return { type: FETCH_POLITICA_COMPRAS_SUCCESS, payload: data };
}

export function fetchPoliticaComprasFailure(data) {
  return { type: FETCH_POLITICA_COMPRAS_FAILURE, payload: data };
}

export function reset() {
  return { type: POLITICA_COMPRAS_RESET };
}

// side effects, only as applicable
export const fetchPoliticaCompras = () => {
  return async dispatch => {
    dispatch(fetchPoliticaComprasStart());
    try {
      const response = await getPoliticaCompras();
      dispatch(fetchPoliticaComprasSuccess(response));
    } catch (error) {
      dispatch(fetchPoliticaComprasFailure(error));
    }
  };
};
