import CartAuthentication from './CartAuthentication';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.session.isAuthenticated
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CartAuthentication);
