import React, { useEffect, useState, useRef } from 'react';
import Input from '../../Input/Input';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';

import createStyles from './NavSearch.styles';

const NavSearch = (props) => {
  const styles = createStyles({ props });

  const {
    searchRef,
    classes,
    endIconProps,
    handleSearch,
    setIsSearchOpen,
    ...rest
  } = props;

  const [searchTerm, setSearchTerm] = useState(props.searchTerm || '');

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchIconClick = () => {
    setIsSearchOpen(false)
    handleSearch(searchTerm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch(searchTerm);
    setIsSearchOpen(false);
  };

  return (
    <div
      ref={searchRef}
      className={`${styles.root} ${classes && classes.root}`}
    >
      <form className={styles.formSearch} onSubmit={handleSubmit}>
        <Input
          customClasses={{
            input: `${styles.input} ${classes && classes.input}`
          }}
          placeholder='Procure pelo nome do curso'
          value={searchTerm}
          onChange={handleChange}
          endIcon={{
            icon: <SearchIcon className={styles.inputIcon} />
          }}
          endIconProps={{
            onClick: handleSearchIconClick
          }}
          onFocus={() => setIsSearchOpen(true)}
        />
      </form>
    </div>
  );
};

NavSearch.propTypes = {
  searchRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  classes: PropTypes.object,
  endIconProps: PropTypes.object,
  handleSearch: PropTypes.func,
  setIsSearchOpen: PropTypes.func
};

export default NavSearch;
