import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  sampleRoot: {
    padding: '2rem',
    margin: (props) => (props.x ? '2rem' : '')
  },
  header: {
    background: '#32559D',
    padding: '28px 135px', 
    display: 'flex', 
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center', 
    alignContent: 'center',
    margin: '23px 0',
    '& > svg': {
      color: '#fff',
      margin: '0 22px'
    },
    '& > p': {
      color: '#fff', 
      fontSize: '36px', 
      lineHeight: '42px'
    },
  }, 
  confirmationContent: {
    background: 'rgba(0, 0, 0, 0.05)',
    padding: '20px', 
    margin: '23px 0 80px 0', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center',
    alignItems: 'center',
    '& > button': {
      margin: '20px', 
      // width: '20%'
    }, 
    '& > p': {
      fontSize: '18px', 
      lineHeight: '21px',
      fontWeight: 'normal',
      margin: '20px'
    }
  }
}));

export default createStyles;
