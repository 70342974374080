import Registration from './RegisterConfirmation';
import { connect } from 'react-redux';
import { confirmationRegister, resetConfirmationRegistert } from '../Registration.ducks';

const mapStateToProps = state => {
  return {
    isLoading: state.userRegistration.isLoading,
    isConfirmation: state.userRegistration.isConfirmation
  };
};

const mapDispatchToProps = {
  confirmationRegister, 
  resetConfirmationRegistert
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);

