import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  stepIconRoot: {
    backgroundColor: '#C7C7C7',
    color: theme.colors.white,
    padding: '1rem',
    borderRadius: '50%',
    display: 'flex'
  },
  stepActive: {
    backgroundColor: theme.colors.blueCompany
  },
  stepCompleted: {
    color: theme.colors.blueCompany,
    backgroundColor: theme.colors.white
  }
}));

export default createStyles;
