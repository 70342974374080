import React from 'react';
import Modal from '@material-ui/core/Modal';
import createStyles from './ForgotPasswordModal.styles';
import { Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ForgotPasswordForm from './Form/ForgotPasswordForm.formik';
import SuccessModal from './SuccesModal';


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    paddingBottom: "100px"
  };
}

const ForgotPasswordModal = (props) => {
  const classes = createStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const {
    handlePasswordRecover,
    isRecovering,
    errors,
    afterRecover, 
  } = props;

  const [ isSuccess, setSuccess ] = React.useState(false);

  const handleClose = () => {
    setSuccess(true);
    props.handleClose();
  }

  const handleCloseSuccess = () => {
    setSuccess(false);
  }
  
  
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <ClearIcon className={classes.clearIcon} onClick={props.handleClose}/>
      <div className={classes.content}>
          <Typography className={classes.title} variant="subtitle1">Recuperar senha</Typography>
          <Typography className={classes.text} variant="body2">Você receberá um e-mail com as instruções e link para criar uma nova senha.</Typography>
          <ForgotPasswordForm 
              recoverPassword={handlePasswordRecover}
              isLoading={isRecovering} 
              handleClose={handleClose}
              errors={errors}
              afterRecover={afterRecover} />
      </div>
    </div>
  );
  
  return (

    <div>
        <Modal
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
            
        </Modal>
        <SuccessModal open={isSuccess} handleClose={handleCloseSuccess}/>
    </div>
    
  );
};

export default ForgotPasswordModal;
