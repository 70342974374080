import { makeStyles } from '@material-ui/core/styles';
import { width } from '@material-ui/system';
import { TextareaAutosize } from '@material-ui/core';

const createStyles = makeStyles(theme => ({
    registrationContent: isMobile => ({
        display: 'flex', 
        flexDirection: isMobile ? 'column' : 'row', 
        flexFlow: 'wrap',
        width: '100%',
    }),
    titleContainer: isMobile => ({
        display: 'flex',
        flexDirection:  'column',
        marginBottom: '3rem',
    }),
    title: {
        color: theme.colors.text.dark,
        margin: '0 0 30px 0',
      },
    buttonContainer: isMobile => ({
        display: 'flex', 
        flexDirection: isMobile ? 'row' : 'column',
        width: '100%',
    }),
    button: isMobile => ({
        margin: isMobile ? '0 1.6rem 0 0' : '0 0 1.6rem 0',
    }),
    cardContainer: isMobile => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flex: 1
    }),
    cardContent:{
        padding: '3rem',
    },
    registerCard: isMobile => ({ 
        maxWidth: isMobile ? '382px' : '680px',
        display: 'flex', 
        flexDirection: 'column', 
        padding: 0, 
        borderRadius: 0, 
        boxShadow: '1px 2px 8px rgba(0, 0, 0, 0.2)', 
    }),
    rootLoading: {
        height: 'calc(100vh - 7rem)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

export default createStyles;
