import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  rootPageContent: {
    marginTop: '9rem',
    minHeight: '60vh',
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "center",
    '@media (max-width: 980px)': {
      marginTop: '15.7rem'
    }
  }
}));

export default createStyles;
