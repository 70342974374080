import React, { useEffect } from 'react';
import './App.css';
import AppRoutes from './App.routes';
import { Router } from 'react-router-dom';
import NavBar from 'components/NavBar';
import Footer from 'components/Footer';
import PageContent from 'components/PageContent';
import { ThemeProvider } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import { configureIntecerptor } from 'shared/request';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";

import theme from './App.theme';

const history = createBrowserHistory();
configureIntecerptor(history);

const handleAcceptCookie = () => {
  // Initialize Google Tag Manager (GTM)
  if (process.env.REACT_APP_GTM_GTMID && process.env.REACT_APP_GTM_AUTH && process.env.REACT_APP_GTM_PREVIEW) {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_GTMID,
      auth: process.env.REACT_APP_GTM_AUTH,
      preview: process.env.REACT_APP_GTM_PREVIEW
    });
  }

  // Initialize Google Analytics (UA)
  if (process.env.REACT_APP_ANALYTICS_TRACKINGID) {
    const trackPageView = location => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    };

    ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKINGID);
    trackPageView(history.location);
    history.listen(trackPageView);
  }
}

function App() {
  let searchTerm = '';
  let searchQuery = window.location.search.substring(1) || '';

  if (window.location.pathname.includes('/busca/')) {
    searchTerm = window.location.pathname.substring(7);
  }

  useEffect(() => {
    document.title = "Loja SENAI ES";

    const hasConsented = getCookieConsentValue('marketplace_CookieConsent');
    if (hasConsented) {
      handleAcceptCookie();
    }
  }, []);

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <NavBar searchTerm={searchTerm} searchQuery={searchQuery} />
        <PageContent history={history}>
          <AppRoutes />
        </PageContent>
        <CookieConsent
          buttonText="Entendi"
          cookieName="marketplace_CookieConsent"
          onAccept={handleAcceptCookie}
          expires={150}
          style={{
            background: "#22498D"
          }}
          buttonStyle={{ background: "#fff", fontSize: "15px", zIndex: "1000001" }}
        >
          Esse site utiliza cookies para melhorar a experiência de usuário. Ao continuar a usar o site, você consente com a utilização de cookies.
        </CookieConsent>
        <Footer />
      </ThemeProvider>
    </Router>
  );
}

export default App;
