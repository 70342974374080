import React, { useEffect, Fragment, useCallback } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import CircularProgress from '@material-ui/core/CircularProgress';
import MainContainer from 'components/MainContainer';
import CourseCard from 'components/CourseCard';
import SearchHeader from './SearchHeader';
import Pagination from '../../components/Pagination';
import { useState } from 'react';
import PropTypes from 'prop-types';
import createStyles from './Search.styles';
import { getCustomAttribute } from 'shared/util/ProductCustomAttribute';
import { toUrlFriendly } from 'shared/util/textTransformation';

const Search = props => {
  const classes = createStyles(props);

  const {
    isLoading,
    search,
    searchResults,
    totalSearchResults,
    staticFilters,
    searchFilters,
    getCities,
    cities,
    openLoginBox, 
    closeLoginBox,
    searchTermProps
  } = props;

  const searchTerm = props.match.params.searchTerm || searchTermProps || '';
  const searchParams = props.location.search;

  //pagination
  const [itensPerPage, setItensPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderBy, setOrderBy] = useState('more_relevant');
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  
  const breadcrumbs = [
    { label: 'Início', to: '/' },
    { label: 'Pesquisa', to: '/minha-conta' },
    { label: `"${searchTerm}"`, to: '' }
  ];

  const getParameterByName = (name) => {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

  useEffect(() => {
    //getCities();
  }, [getCities])

  useEffect(() => {
    if (cities && cities.length > 0) {
      const cidade = getParameterByName("cidade");
      let cidadeArr = [];
      if (cidade) {
        cidadeArr = cidade.split(',');
        cidadeArr.forEach(cidade => {
          cities.forEach(city => {
            if (city.name === cidade) {
              searchFilters.cidade.push(city);
            }
          })
        })
      }
    }
  }, [searchFilters, cities]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      search(searchTerm, searchParams, currentPage, itensPerPage, "", searchFilters);
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
    
  },[search, searchTerm, searchParams, currentPage, itensPerPage, searchFilters])

  const onPageChange = page => {
    setCurrentPage(page.selected);
  };
  const onOrderByChange = value => {
    if (value === "lower_price") {
      searchResults.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    } 
    if (value === "higher_price") {
      searchResults.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    }
    if (value === "a_z") {
      searchResults.sort((a, b) => {
        if(a.title < b.title)
          return -1;
        else if(a.title > b.title)
          return 1;
        return 0;
      });
    }
    if (value === "z_a") {
      searchResults.sort((a, b) => {
        if(a.title < b.title)
          return 1;
        else if(a.title > b.title)
          return -1;
        return 0;
      });
    }
    forceUpdate();
    setOrderBy(value);
  };

  return (
    <Fragment>
      <Breadcrumbs items={breadcrumbs} />
      <MainContainer header>
        <SearchHeader
          searchTerm={searchTerm}
          totalSearchResults={totalSearchResults}
          staticFilters={staticFilters}
          searchQuery={searchParams}
          onOrderByChange={onOrderByChange}
        />
        {isLoading ? (
          <div className={classes.rootLoading}>
            <CircularProgress />
          </div>
        ) : (
          <>
          <div className={classes.searchResultsContainer}>
            {searchResults && searchResults.map(course => {
              return (
                <div className={classes.courseCard} key={course.id}>
                  <CourseCard
                    id={course.id}
                    image={course.image}
                    areaTag={getCustomAttribute(course.classes[0].product, 'area')}
                    title={course.title}
                    place={course.classes[0].place}
                    duration={parseInt(course.classes[0].duration)}
                    modalidade={course.presencialDistancia}
                    courseClasses={course.hasOpenChairs}
                    price={course.price}
                    modalidade={course.presencialDistancia}
                    parcels={course.parcels == 0 ? '' : course.parcels}
                    link={`/curso/${toUrlFriendly(course.title)}/${course.sku}`}
                    favorite={course.favorite}
                    discount={course.discount}
                    priceWithDiscount={course.priceWithDiscount}
                    sku={course.sku}
                    startDate={getCustomAttribute(course.classes[0].product, 'start_date')}
                    urlNacional={course.link}
                    showLoginBox={openLoginBox}
                    closeLoginBox={closeLoginBox}
                  />
                </div>
              );
            })}
          </div>
          </>
        )}
        {/*
        <div className={classes.searchResultsPagination}>
          <Pagination
            className={classes.paginationContainer}
            totalSearchResults={totalSearchResults}
            itensPerPage={itensPerPage}
            initialPage={currentPage}
            onPageChange={onPageChange}
          />
        </div>
        */}
      </MainContainer>
    </Fragment>
  );
};

Search.propTypes = {
  isLoading: PropTypes.bool,
  search: PropTypes.func,
  searchResults: PropTypes.array,
  totalSearchResults: PropTypes.number,
  staticFilters: PropTypes.array
};

export default Search;
