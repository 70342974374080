import * as Yup from 'yup';

const CreditCardForm = {
  cardNumber: Yup.string().required('Este campo é obrigatório'),
  personName: Yup.string().required('Este campo é obrigatório'),
  cardMonth: Yup.string().required('Este campo é obrigatório'),
  cardYear: Yup.string().required('Este campo é obrigatório'),
  securityCode: Yup.string()
    .required('Este campo é obrigatório')
    .max(3, 'O código de segurança deve possuir apenas 3 digitos'),
  installmentOptions: Yup.string().required('Este campo é obrigatório'),
  useTerms: Yup.bool().oneOf([true], 'Você deve aceitar as políticas do curso')
};

export const CreditCardFormValidator = Yup.object().shape(CreditCardForm);
