import request from 'shared/request';
// import BadRequestError from 'errors/BadRequestError';
import { getPurchaseHistoryMock } from 'mock/PurchaseHistory';

export const getPurchaseHistory = async () => {
  try {
    // const response = await request.get('/products/showcase');
    // return response.data;

    return new Promise(resolve =>
      setTimeout(function() {
        const result = getPurchaseHistoryMock();
        resolve(result);
      }, 1000)
    );
  } catch (error) {
    //TODO
  }
};
