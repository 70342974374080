import Address from './Address.view';
import { connect } from 'react-redux';
import { fetchAddress } from 'modules/Adresses/Address.ducks.js';
import { handleDeleteAddress } from 'modules/Adresses/Address.ducks.js';



const mapStateToProps = state => {
  return {
    adresses: state.adresses.adresses, 
    isLoading: state.adresses.isLoading
  };
};

const mapDispatchToProps = {
  fetchAddress, 
  handleDeleteAddress
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);