import React from 'react';
import createStyles from './AddCardModal.styles';
import Modal from '@material-ui/core/Modal';
import { Typography } from '@material-ui/core';
import AddCardForm from './Form/AddCardForm.formik';
import ClearIcon from '@material-ui/icons/Clear';


function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const ChangeEmailModal = props => {
  const classes = createStyles(props);
  const [ modalStyle ] = React.useState(getModalStyle);

  const {
    handleSavePaymentMethod, 
    isAdding, 
    handleClose
  } = props;

  
  const body = (
    <div style={modalStyle} className={classes.paper}>
       <ClearIcon className={classes.clearIcon} onClick={props.handleClose}/>
      <div className={classes.frame}>
        <Typography className={classes.title} variant="subtitle1">Adicionar forma de pagamento</Typography>
        <AddCardForm 
            handleClose={handleClose}
            handleAddPayment={handleSavePaymentMethod}
            isLoading={isAdding}/>
      </div>
    </div>
  );

  return (
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
  );
};


export default ChangeEmailModal;
