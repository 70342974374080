import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography, IconButton } from '@material-ui/core';
import ButtonPrimary from 'components/ButtonPrimary';
import CloseIcon from '@material-ui/icons/Close';
import { filterOptions } from 'shared/util/search';
import PropTypes from 'prop-types';
import createStyles from './SearchFilterDesktop.styles';

const SearchFilterDesktop = props => {
  const classes = createStyles({ props });
  const {
    checkedFilters,
    handleFilterChange,
    handleFilterSubmitClick,
    handleClose,
    variant,
    searchFilters
  } = props;

  return (
    <div className={classes.root} onClick={handleClose}>
      <div
        className={classes.container}
        onClick={e => e.stopPropagation()}
        ref={props.filterRef}
      >
        <div className={classes.filterBox} ref={props.filterRef}>
          {searchFilters && searchFilters.map((item, index) => {
            return (
              <div key={index} className={classes.filter}>
                <Typography
                  color='textPrimary'
                  classes={{ root: classes.filterLabel }}
                >
                  {item.filterName}
                </Typography>
                {item.options.map((option, index) => {
                  return (
                    <FormControlLabel
                      key={item.filterKey + '_' + index}
                      classes={{
                        root: classes.option,
                        label: classes.optionLabel
                      }}
                      label={option.name}
                      control={
                        <Checkbox
                          key={option.id}
                          name={option.id}
                          checked={
                            checkedFilters[item.filterKey] !== undefined &&
                            checkedFilters[item.filterKey].includes(option.name)
                          }
                          onChange={() =>
                            handleFilterChange(item.filterKey, option)
                          }
                          color='primary'
                        />
                      }
                    />
                  );
                })}
              </div>
            );
          })}
          <IconButton
            onClick={handleClose}
            aria-label='upload picture'
            component='span'
          >
            <CloseIcon />
          </IconButton>
        </div>
        {variant && variant === 'formBased' && (
          <div className={classes.buttonSubmit}>
            <ButtonPrimary
              buttonText='Filtrar'
              onClick={handleFilterSubmitClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

SearchFilterDesktop.propTypes = {
  checkedFilters: PropTypes.object,
  handleFilterChange: PropTypes.func,
  handleFilterSubmitClick: PropTypes.func,
  handleClose: PropTypes.func,
  variant: PropTypes.string
};

export default SearchFilterDesktop;
