import React, { useState, useEffect, useRef } from 'react';
import Input from 'components/Input';
import Button from 'components/ButtonPrimary';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getCEP } from 'shared/util/GetCEP.js';
import FormLabel from '@material-ui/core/FormLabel';

import createStyles from './AddEditAddressFormPJ.styles';

const StyledCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#32559D;'
    }
  },

  checked: {},

  body1: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '19px'
  }
})((props) => <Checkbox color='default' {...props} />);

const AddEditAddressForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [cepError, setCepError] = useState('');
  const [cepCanChange, setCepCanChange] = useState(true);

  const classes = createStyles(isMobile);

  const {
    errors,
    touched,
    isLoading,
    values,
    handleChange,
    handleBlur,
  } = props;

  useEffect(() => {
    let inputCEP = '';

    if (values.cep) {
      inputCEP = values.cep.replace(/_|-/g, '');
    }

    if (inputCEP.length === 0) {
      setCepCanChange(true);
      setCepError('');
    }
    else if (inputCEP.length > 0 && inputCEP.length !== 8) {
      setCepCanChange(true);
      setCepError('CEP inválido');
    }
    else if (inputCEP.length === 8) {
      getCEP(inputCEP).then(
        (response) => {
          values.address = response.address;
          values.neighbourhood = response.neighbourhood;
          values.city = response.city;
          values.state = response.state;
          values.cityCode = response.ibge;
          if (
            response.city === "" ||
            response.state === ""
          ) {
            setCepCanChange(false);
          }
          setCepError('');
        },
        () => {
          setCepError('CEP não encontrado');
        });
    }
  }, [values.cep]);

  useEffect(() => {
    const updateWidth = () => {
      setIsMobile(window.innerWidth < 800 ? true : false);
    };
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  });

  const handleSubmitForm = () => {
    props.handleSubmit(values);
  };

  return (
    <form className={classes.container} onSubmit={handleSubmitForm}>
      {loading && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}

      <div className={classes.form}>
        <div className={classes.formColumn}>
          <Input
            placeholder='Ex.: 12.345.678/9123-00'
            mask={'99.999.999/9999-99'}
            label='CNPJ'
            type='text'
            name='cpfCnpj'
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.cpfCnpj}
            customClasses={{ root: classes.input }}
            error={touched.cpfCnpj ? errors.cpfCnpj : null}
          />

          <Input
            label='Razão Social'
            type='text'
            name='name'
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            customClasses={{ root: classes.input }}
            error={touched.name ? errors.name : null}
          />

          <Input
            required
            placeholder='Ex: 11023-456'
            mask={'99999-999'}
            label='CEP'
            type='text'
            name='cep'
            // autoComplete={"off"}
            helperText='Seu endereço aparecerá automaticamente.'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.cep}
            customClasses={{ root: classes.input }}
            error={cepError ? cepError : touched.cep ? errors.cep : null}
          />

          <Input
            label='Estado'
            required
            type='text'
            name='state'
            disabled={cepCanChange}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.state}
            customClasses={{ root: classes.input }}
            error={touched.state ? errors.state : null}
          />

          <Input
            label='Cidade'
            required
            type='text'
            name='city'
            disabled={cepCanChange}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.city}
            customClasses={{ root: classes.input }}
            error={touched.city ? errors.city : null}
          />

          <Input
            label='Bairro'
            required
            type='text'
            name='neighbourhood'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.neighbourhood}
            customClasses={{ root: classes.input }}
            error={touched.neighbourhood ? errors.neighbourhood : null}
          />

          <Input
            label='Endereço'
            required
            type='text'
            name='address'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address}
            customClasses={{ root: classes.input }}
            error={touched.address ? errors.address : null}
          />
        </div>

        <div className={classes.formColumn}>
          <Input
            placeholder='Ex: 250'
            required
            label='Número'
            type='text'
            name='number'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.number}
            customClasses={{ root: classes.input }}
            error={touched.number ? errors.number : null}
          />

          <Input
            placeholder='Ex: Condomínio ABC, Bloco A, APT 202'
            label='Complemento'
            type='text'
            name='complement'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.complement}
            customClasses={{ root: classes.input }}
            error={touched.complement ? errors.complement : null}
          />

          <FormLabel className={classes.legend} component='legend'>CONTATO EMPRESA</FormLabel>
         
          <Input
            label='Nome'
            type='text'
            name='firstName'
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            customClasses={{ root: classes.input }}
            error={touched.firstName ? errors.firstName : null}
          />

          <Input
            label='Sobrenome'
            type='text'
            name='lastName'
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            customClasses={{ root: classes.input }}
            error={touched.lastName ? errors.lastName : null}
          />

          <Input
            placeholder=''
            label='Data de nascimento'
            type='text'
            name='birthDate'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.birthDate}
            // className={classes.input}
            mask={'99/99/9999'}
            error={touched.birthDate ? errors.birthDate : null}
          />

          <Input
            placeholder='Ex.: (12) 93456-7890'
            mask={'(99) 99999-9999'}
            label='Telefone celular'
            type='celphone'
            name='phone'
            required
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete={"off"}
            value={values.phone}
            customClasses={{ root: classes.input }}
            error={touched.phone ? errors.phone : null}
          />

          <Input
            label='E-mail'
            type='text'
            name='email'
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            customClasses={{ root: classes.input }}
            error={touched.email ? errors.email : null}
          />
        </div>
      </div>

      <Button
        disabled={!(props.isValid && props.dirty)}
        style={{ width: '244px' }}
        lowercase
        isLoading={isLoading}
        type='submit'
        width='100%' >
        Continuar
      </Button>
    </form>
  );
};

export default AddEditAddressForm;
