import request from 'shared/request';

export const getQuestions = async () => {
  try {
    const response = await request.get('/faqs/');
    return response;
  } catch (error) {
    throw error;
  }
};
