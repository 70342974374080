import { getPaymentMethod } from 'api/PaymentMethod';
import { deletePaymentMethod } from 'api/PaymentMethod';
import { savePaymentMethod } from 'api/PaymentMethod';

// Actions
const FETCH_PAYMENT_METHOD_START = 'sesi/login/FETCH_PAYMENT_METHOD_START';
const FETCH_PAYMENT_METHOD_SUCCESS = 'sesi/login/FETCH_PAYMENT_METHOD_SUCCESS';
const FETCH_PAYMENT_METHOD_FAILURE = 'sesi/login/FETCH_PAYMENT_METHOD_FAILURE';
const PAYMENT_METHOD_RESET = 'sesi/login/PAYMENT_METHOD_RESET';

const SAVE_PAYMENT_METHOD_START = 'sesi/login/SAVE_PAYMENT_METHOD_START';
const SAVE_PAYMENT_METHOD_SUCCESS = 'sesi/login/SAVE_PAYMENT_METHOD_SUCCESS';
const SAVE_PAYMENT_METHOD_FAILURE = 'sesi/login/SAVE_PAYMENT_METHOD_FAILURE';
const SAVE_PAYMENT_METHOD_RESET = 'sesi/login/SAVE_PAYMENT_METHOD_RESET';

const DELETE_PAYMENT_METHOD_START = 'sesi/login/DELETE_PAYMENT_METHOD_START';
const DELETE_PAYMENT_METHOD_SUCCESS = 'sesi/login/DELETE_PAYMENT_METHOD_SUCCESS';
const DELETE_PAYMENT_METHOD_FAILURE = 'sesi/login/DELETE_PAYMENT_METHOD_FAILURE';
const DELETE_PAYMENT_METHOD_RESET = 'sesi/login/DELETE_PAYMENT_METHOD_RESET';

// Reducer
const initialState = {
  paymentMethods: [],
  isLoading: false, 
  isAdding: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_PAYMENT_METHOD_START:
      return { ...state, isLoading: true };
    case FETCH_PAYMENT_METHOD_SUCCESS:
      return { ...state, ...action.payload, isLoading: false };
    case FETCH_PAYMENT_METHOD_FAILURE:
      return { ...state, ...action.payload };
    case PAYMENT_METHOD_RESET:
      return { ...state, ...initialState };
    case SAVE_PAYMENT_METHOD_START:
      return { ...state, isAdding: true };
    case SAVE_PAYMENT_METHOD_SUCCESS:
      return { ...state, ...action.payload, isAdding: false };
    case SAVE_PAYMENT_METHOD_FAILURE:
      return { ...state, ...action.payload };
    case SAVE_PAYMENT_METHOD_RESET:
      return { ...state, ...initialState };
    case DELETE_PAYMENT_METHOD_START:
      return { ...state, isLoading: true };
    case DELETE_PAYMENT_METHOD_SUCCESS:
      return { ...state, ...action.payload, isLoading: false };
    case DELETE_PAYMENT_METHOD_FAILURE:
      return { ...state, ...action.payload };
    case DELETE_PAYMENT_METHOD_RESET:
      return { ...state, ...initialState }; 
    default:
      return state;
  }
}

// Action Creators
export function fetchPaymentMethodStart() {
  return { type: FETCH_PAYMENT_METHOD_START };
}

export function fetchPaymentMethodSuccess(data) {
  return { type: FETCH_PAYMENT_METHOD_SUCCESS, payload: data };
}

export function fetchPaymentMethodFailure(data) {
  return { type: FETCH_PAYMENT_METHOD_FAILURE, payload: data };
}

export function reset() {
  return { type: PAYMENT_METHOD_RESET };
}

export function savePaymentMethodStart() {
  return { type: SAVE_PAYMENT_METHOD_START };
}

export function savePaymentMethodSuccess(data) {
  return { type: SAVE_PAYMENT_METHOD_SUCCESS, payload: data };
}

export function savePaymentMethodFailure(data) {
  return { type: SAVE_PAYMENT_METHOD_FAILURE, payload: data };
}

export function savePaymentMethodReset() {
  return { type: SAVE_PAYMENT_METHOD_RESET };
}


export function deletePaymentMethodStart() {
  return { type: DELETE_PAYMENT_METHOD_START };
}

export function deletePaymentMethodSuccess(data) {
  return { type: DELETE_PAYMENT_METHOD_SUCCESS, payload: data };
}

export function deletePaymentMethodFailure(data) {
  return { type: DELETE_PAYMENT_METHOD_FAILURE, payload: data };
}

export function deleteReset() {
  return { type: DELETE_PAYMENT_METHOD_RESET };
}

// side effects, only as applicable
export const fetchPaymentMethod = () => {
  return async dispatch => {
    dispatch(fetchPaymentMethodStart());

    try {
      const response = await getPaymentMethod();
      dispatch(fetchPaymentMethodSuccess(response));
    } catch (error) {
      dispatch(fetchPaymentMethodFailure(error));
    }
  };
};

export const handleSavePaymentMethod = ({flag, number, fullName, month, year, defaultPayment}) => {
  return async dispatch => {
    dispatch(savePaymentMethodStart());

    try {
      const response = await savePaymentMethod({flag, number, fullName, month, year, defaultPayment});
      dispatch(savePaymentMethodSuccess(response));
    } catch (error) {
      dispatch(savePaymentMethodFailure(error));
    }
  };
}

export const handleDeletePaymentMethod = (id) => {
  return async dispatch => {
    dispatch(deletePaymentMethodStart());

    try {
      const response = await deletePaymentMethod(id);
      dispatch(deletePaymentMethodSuccess(response));
    } catch (error) {
      dispatch(deletePaymentMethodFailure(error));
    }
  };
};


