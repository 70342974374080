import * as Yup from 'yup';

const changeEmailForm = {
    email: Yup.string()
    .required('Este campo é obrigatório')
    .email('Email inválido'),
    confirmationPassword: Yup.string()
    .required('Este campo é obrigatório'),
};

export const ChangeEmailFormValidator = Yup.object().shape(changeEmailForm);
