import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  forgotPasswordLabel: {
    color: theme.colors.blueCompany, 
    textDecoration: 'none'
  },
  rememberMeLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    marginBottom: '1.6rem'
  },
  input2: {
    marginBottom: '1.6rem', 
    width: '125px'
  }, 
  form: {
    marginTop: '2.4rem'
  }, 
  warning: {
    marginBottom: '1.6rem',
    height: 15,
    left: '0%',
    right: '0%',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 11,
    lineHeight: 15,
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.04em',
    color: 'rgba(0, 0, 0, 0.65)'
  },
  errorsText: {
    margin: '-1rem 0 2rem 0'
  }, 
  selectContainer: {
    display: 'flex', 
    flexDirection: 'row', 
    marginTop: '2px'
  }, 
  select: {
    width: '35%', 
    marginRight: '18px', 
    marginBottom: '1.6rem'
  }, 
  label: {
    color: 'rgba(0, 0, 0, 0.65)',
    marginBottom: '0.5rem',
    fontSize: '1.4rem'
  },
}));

export default createStyles;
