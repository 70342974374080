import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Vitrine from './modules/Vitrine/Vitrine.connect';
import Category from './modules/Category/Category.connect';
import NotFound from './shared/error/NotFound/NotFound.view';
import Login from './modules/Login';
import Register from './modules/Register';
import RegisterConfirmation from './modules/Registration/RegisterConfirmation';
import Search from './modules/Search';
import SearchFromCourseTitle from 'modules/Search/SearchFromCourseTitle';
import PrivateRoute from 'shared/PrivateRoute';
import ErrorBoundaryRoute from 'shared/error/ErrorBoundaryRoute';
import CourseDetails from './modules/CourseDetails';
import MyCourses from './modules/MyCourses';
import Favorites from './modules/Favorites';
import Account from './modules/MyAccount';
import PurchaseHistory from './modules/PurchaseHistory';
import Cart from './modules/Cart';
import Courses from './modules/Courses';
import FAQ from './modules/FAQ';
import Politicas from './modules/Politicas';
import NewPassword from './modules/NewPassword';
import MyPaymentHistory from './modules/MyPaymentHistory';

const AppRoutes = () => {
  return (
    <Switch>
      <ErrorBoundaryRoute exact path='/' component={Vitrine} />
      <ErrorBoundaryRoute exact path='/curso/:title' component={SearchFromCourseTitle} />
      <ErrorBoundaryRoute exact path='/curso/:title/:sku' component={CourseDetails} />
      <Route path='/cursos/cidade/:city/:id' component={Courses} />
      <PrivateRoute exact path='/meus-cursos' component={MyCourses} />
      <PrivateRoute exact path='/meus-pedidos' component={MyPaymentHistory} />
      <PrivateRoute exact path='/favoritos' component={Favorites} />
      <PrivateRoute
        exact
        path='/minha-conta/historico-de-compra'
        component={PurchaseHistory}
      />
      <Route path='/categoria/:categoryId' component={Category} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/login/:sku' component={Login} />
      <Route exact path='/busca/:searchTerm?' component={Search} />
      <Route exact path='/cadastro' component={Register} />
      <Route path='/confirmar-cadastro' component={RegisterConfirmation} />
      <PrivateRoute exact path='/minha-conta' component={Account} />
      <Route exact path='/perguntas-frequentes' component={FAQ} />
      <Route exact path='/politicas' component={Politicas} />
      <Route exact path='/nova-senha' component={NewPassword} />
      <PrivateRoute exact path='/carrinho/:step?' component={Cart} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default AppRoutes;
