import React from 'react';
import createStyles from './AddEditAddressModal.styles';
import Modal from '@material-ui/core/Modal';
import { Typography } from '@material-ui/core';
import AddEditAddressForm from './Form/AddEditAddressForm.formik';
import ClearIcon from '@material-ui/icons/Clear';


function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const AddEditAddressModal = props => {
  const classes = createStyles(props);

  const [ modalStyle ] = React.useState(getModalStyle);

  const { handleSaveAddress, isLoading } = props;
  
  const body = (
    <div style={modalStyle} className={classes.paper}>
       <ClearIcon className={classes.clearIcon} onClick={props.handleClose}/>
      <div className={classes.frame}>
        {props.isEdit ? 
            <Typography className={classes.title} variant="subtitle1">Alterar endereço</Typography>
          : 
            <Typography className={classes.title} variant="subtitle1">Novo endereço</Typography>
        }

        <AddEditAddressForm 
            buttonTitle={props.isEdit ? 'Salvar' : 'Cadastrar endereço'} 
            handleCloseModal={props.handleClose}
            handleSave={handleSaveAddress}
            isLoading={isLoading}
            isEdit={props.isEdit}
            formData={props.data}/>
      </div>
    </div>
  );

  return (
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
  );
};


export default AddEditAddressModal;
