import React, { useState, useEffect, useRef } from 'react';
import Input from 'components/Input';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from 'components/ButtonPrimary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getCEP } from 'shared/util/GetCEP.js';

import createStyles from './AddEditAddressForm.styles';

const StyledCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#32559D;'
    }
  },
  checked: {},
  body1: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '19px'
  }
})((props) => <Checkbox color='default' {...props} />);

const AddEditAddressForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [formError, setFormError] = useState({});

  const classes = createStyles(isMobile);
  const {
    errors,
    touched,
    values,
    isSubmitting,
    isLoading,
    // externalErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    isEdit
  } = props;

  useEffect(() => {
    let inputCEP = '';

    if (values.cep) {
      inputCEP = values.cep.replace(/_|-/g, '');
    }

    if (inputCEP.length === 0) {
      setFormError({ cep: false });
    }
    else if (inputCEP.length > 0 && inputCEP.length !== 8) {
      setFormError({ cep: true });
    }
    else if (inputCEP.length === 8) {
      getCEP(inputCEP).then(
        (response) => {
          values.address = response.address;
          values.neighbourhood = response.neighbourhood;
          values.city = response.city;
          values.state = response.state;
          values.ibge = response.ibge;
          setFormError({ cep : false });
        },
        () => {
          values.address = "";
          values.neighbourhood = "";
          values.city = "";
          values.state = "";
          values.ibge = "";
          setFormError({ cep : true });
        });
    }
  }, [values.cep]);

  useEffect(() => {
    const updateWidth = () => {
      setIsMobile(window.innerWidth < 800 ? true : false);
    };
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  });

  

  return (
    <form className={classes.container} onSubmit={handleSubmit}>
      {loading && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}

      <div className={classes.form}>
        <div className={classes.formColumn}>
          <Input
            placeholder='Casa'
            label='Nome (opcional)'
            type='text'
            name='name'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            className={classes.input}
            error={touched.name ? errors.name : null}
          />

          <Input
            placeholder='Ex: 11023-456'
            mask={'99999-999'}
            label='CEP'
            type='text'
            name='cep'
            helperText='Seu endereço aparecerá automaticamente.'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.cep}
            className={classes.input}
            error={formError.cep ? 'Esse CEP não existe.' : touched.cep ? errors.cep : null}
          />

          <Input
            label='Estado'
            type='text'
            name='state'
            disabled={true}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.state}
            className={classes.input}
            error={touched.state ? errors.state : null}
          />

          <Input
            label='Cidade'
            type='text'
            name='city'
            disabled={true}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.city}
            className={classes.input}
            error={touched.city ? errors.city : null}
          />
        </div>

        <div className={classes.formColumn}>
          <Input
            label='Bairro'
            type='text'
            name='neighbourhood'
            disabled={true}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.neighbourhood}
            className={classes.input}
            error={touched.neighbourhood ? errors.neighbourhood : null}
          />

          <Input
            label='Endereço'
            type='text'
            name='address'
            disabled={true}
            helperText=' '
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address}
            className={classes.input}
            error={touched.address ? errors.address : null}
          />

          <Input
            placeholder='Ex: 250'
            label='Número'
            type='number'
            name='number'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.number}
            className={classes.input}
            error={touched.number ? errors.number : null}
          />

          <Input
            placeholder='Ex: Condomínio ABC, Bloco A, APT 202'
            label='Complemento'
            type='text'
            name='complement'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.complement}
            className={classes.input}
            error={touched.complement ? errors.complement : null}
          />
        </div>
      </div>

      <div style={{ marginBottom: '25px' }}>
        <FormControlLabel
          style={{ position: 'relative', top: '15%' }}
          control={
            <StyledCheckbox
              onChange={handleChange}
              name='chargeAddress'
              checked={values.chargeAddress}
            />
          }
          label={<Typography variant='body2'>Endereço de cobrança</Typography>}
        />
        <FormControlLabel
          style={{ position: 'relative', top: '15%' }}
          control={
            <StyledCheckbox
              onChange={handleChange}
              name='deliveryAddress'
              checked={values.deliveryAddress}
            />
          }
          label={<Typography variant='body2'>Endereço de entrega</Typography>}
        />
      </div>

      <Button
        style={{ width: '244px' }}
        lowercase
        isLoading={isLoading}
        type='submit'
        width='100%'
      >
        {values.buttonTitle}
      </Button>
    </form>
  );
};

export default AddEditAddressForm;
