import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import CartItem from 'components/CartItem';
import ButtonPrimary from 'components/ButtonPrimary';
import OrderSummary from 'modules/Cart/OrderSummary';
import createStyles from './CartItems.styles';
import AddEditAddressModal from '../../AddEditAddressModal';
import { getCustomAttribute } from 'shared/util/ProductCustomAttribute';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toUrlFriendly } from 'shared/util/textTransformation';

const CartItems = (props) => {
  const {
    isLoading,
    isLoadingTotals,
    getTotalsInCart,
    handleNext,
    user,
    isAuthenticated,
    items,
    getItemsInCart,
    deleteItemInCart,
    saleReset
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [updateSummary, setUpdateSummary] = useState(0);
  const classes = createStyles(props);
  const defaultImage = 'https://admin.loja.senaies.com.br/pub/media/catalog/product/1200x630wa.png';

  const handleDelete = async (itemId) => {
    await deleteItemInCart(itemId);
    getTotalsInCart();
  };

  const handleCloseModal = () => {
    setOpenModal(!openModal);
  };

  const handleImage = (product) => {
    if (getCustomAttribute(product, 'image') !== "") {
      const baseUrl = process.env.REACT_APP_ADMIN_BASE ? process.env.REACT_APP_ADMIN_BASE : 'https://adminsenaid.exablack.com/'; 
      return baseUrl + "pub/media/catalog/product/" + getCustomAttribute(product, 'image');
    } else {
      return null;
    }
  };

  const handlePrice = (product) => {
    if(getCustomAttribute(product, 'special_price') !== ""){
      return getCustomAttribute(product, 'special_price');
    }else{
      return product.price;
    }
  };

  useEffect(() => {
    saleReset();
    getItemsInCart();
    getTotalsInCart();
  }, []);

  return (
    <React.Fragment>
      <div>
        {isLoading ? (
          <div className={classes.rootLoading}>
            <CircularProgress />
          </div>
        ) : (<>
          <div className={classes.cartItemsRoot}>
            {items && items.length > 0 ? (
              <React.Fragment>
                <div className={classes.cartItemsContainer}>
                  {items.map((course) => {
                    return (
                      <div key={course.sku} className={classes.course}>
                        <CartItem
                          itemId={course.product.sku}
                          image={handleImage(course.product) || defaultImage}
                          areaTag={course.product.areaTag}
                          title={course.product.name}
                          place={getCustomAttribute(course.product, 'unit')}
                          duration={parseInt(getCustomAttribute(course.product, 'duration'))}
                          courseClass={course.name}
                          price={handlePrice(course.product)}
                          priceTotal={course.product.price}
                          special_price={getCustomAttribute(course.product, 'special_price')}
                          parcels={course.parcels}
                          link={`/curso/${toUrlFriendly(course.product.name)}/${course.product.sku}`}
                          isFavorite={course.product.isFavorite}
                          quantity={getCustomAttribute(course.product, 'quantity')}
                          discount={course.product.discount}
                          priceWithDiscount={course.product.priceWithDiscount}
                          date={moment(getCustomAttribute(course.product, 'start_date')).format('DD/MM/yyyy')}
                          handleDelete={handleDelete}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className={classes.orderResumeContainer}>
                  {isLoadingTotals ? (
                    <div className={classes.rootInnerLoading}>
                      <CircularProgress />
                    </div>
                  ) : (<>
                    <OrderSummary updateSummary={updateSummary} items={items} />
                    <ButtonPrimary
                      onClick={() => {
                        isAuthenticated ? handleCloseModal() : handleNext();
                      }}
                      className={classes.continueBtn}
                      lowercase
                    >
                      Continuar
                    </ButtonPrimary>
                  </>)}
                </div>
              </React.Fragment>
            ) : (
              <>
                <header className={classes.header}>
                  <Typography>
                    Seu carrinho está vazio! =(
                  </Typography>
                </header>
              </>
            )}
          </div>
        </>)}
        <AddEditAddressModal
          open={openModal}
          isEdit={false}
          handleClose={handleCloseModal}
          user={user}
          isAuthenticated={isAuthenticated}
          handleNext={handleNext}
        />
      </div>
    </React.Fragment>
  );
};

CartItems.propTypes = {
  isLoading: PropTypes.bool,
  cartItems: PropTypes.array,
  removeItemFromCart: PropTypes.func,
  handleNext: PropTypes.func
};

export default CartItems;
