import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  title: {
    color: theme.colors.text.dark,
    top: 0, 
    left: 0, 
    margin: '0 0 30px 0'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'center',
    margin: '0',
    marginBottom: '8rem',
    width: '100%',
  },
  input: isMobile => ({
    border: '1px solid rgba(0, 0, 0, 0.45)', 
    width: isMobile ? '90%' : '50%'
  }), 
  subcontainer: isMobile => ({
    boxSizing: 'border-box',
    display: 'flex', 
    flexFlow: isMobile ? 'column' : 'row wrap', 
    justifyContent: 'flex-start',
    position: 'relative', 
    width: '100%',
    alignItems: 'center',
    marginTop: '3rem',
    marginRight: '-1.6rem',
  }), 
  card: {
    maxWidth: 302,
    height: 164,
    boxShadow: '1px 2px 8px rgba(0, 0, 0, 0.2)', 
    display: 'flex', 
    flexDirection: 'column', 
    flexFlow: 'wrap', 
    marginBottom: '1.6rem',
    marginRight: isMobile => isMobile ? '0' : '1.6rem',
  }, 
  titleContainer: {
    display: 'flex', 
    flexDirection: 'row', 
    position: 'relative'
  }, 
  cardIcon:{
    color: theme.colors.purpleBlog,
    marginRight: '1rem',
  },
  cardTitle: {
    color: 'rgba(0, 0, 0, 0.8)', 
    margin: 0, 
    fontSize: '26px', 
    lineHeight: '30px',
    fontWeight: 'normal'
  }, 
  cardParagraph: {
    marginTop: '1rem',
    position: 'relative', 
    lineHeight: '16px', 
    color: theme.palette.text.primary
  }, 
  subtitle: {
    color: theme.colors.text.dark, 
    marginBottom: '1.6rem',
  }, 
  faqWrapper: isMobile => ({
    display: 'flex', 
    flexDirection:  isMobile ? 'column' : 'row',
    width: '100%', 
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginRight: '-1.6rem',

  }),
  faqCard: isMobile =>  ({
    maxWidth: 250, 
    width: '100%',
    display: 'flex', 
    boxShadow: '1px 2px 8px rgba(0, 0, 0, 0.2)', 
    cursor: 'pointer', 
    marginBottom: '1.6rem',
    marginRight: isMobile ? '0' : '1.6rem',
  }), 
  faqContent: {
    display: 'flex', 
    flexDirection: 'row', 
    padding: '1.6rem',
    paddingBottom: '1.6rem!important'
  }, 
  faqIcon:{
    color: theme.colors.purpleBlog,
    marginRight: '1rem',
  },
  faqTitle: {
    fontSize: '22px',
    lineHeight: '26px',
    fontWeight: 'normal',
    position: 'relative',
    margin: 0
  }
}));

export default createStyles;
