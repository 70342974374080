import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import LoginForm from './LoginBoxForm';
import { loginFormValidator } from './LoginBoxForm.validator';
import InvalidCredentialsError from 'shared/error/Exceptions/InvalidCredentialsException';
import AuthenticationBoxConnect from '../../AuthenticationBox.connect';

const LoginBoxFormWrapper = (props) => {
  const initialValues = {
    username: '',
    password: '',
    rememberMe: false
  };

  const {
    login,
    isLoading,
    handleClose,
    errors,
    handleOpenModal,
    afterLogin,
    redirectAfterLogin
  } = props;

  const handleSubmit = async (formValues, action) => {
    action.setSubmitting(true);
    await login(formValues);
    redirectAfterLogin()
    action.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={loginFormValidator}
    >
      {(propsFormik) => (
        <LoginForm
          isLoading={isLoading}
          loginErrors={errors}
          handleOpenModal={handleOpenModal}
          handleSubmit={handleSubmit}
          {...propsFormik}
        />
      )}
    </Formik>
  );
};

export default LoginBoxFormWrapper;
