import React, { useEffect, useState, useRef } from 'react';
import { Typography } from '@material-ui/core';
import Input from 'components/Input';
import SearchIcon from '@material-ui/icons/Search';
import createStyles from './Help.styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ChatIcon from '@material-ui/icons/Chat';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PhoneIcon from '@material-ui/icons/Phone';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PersonIcon from '@material-ui/icons/Person';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import SecurityIcon from '@material-ui/icons/Security';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import { Link } from 'react-router-dom';

const Help = props => {

  const [isMobile, setIsMobile] = useState(false);
  const rootRef = useRef(null);
  const classes = createStyles(isMobile);

  useEffect(() => {
    const updateWidth = () => {
      if (rootRef.current) {
        setIsMobile(rootRef.current.offsetWidth < 720 ? true : false)
      }
    };
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  });

  return (
    <React.Fragment>
      <div ref={rootRef} className={classes.container}>
        <Typography variant='h3' className={classes.title}>Ajuda</Typography>
        {/* <Input
          placeholder='Qual a sua dúvida?'
          type='text'
          name='search'
          className={classes.input}
          endIcon={{
            icon: <SearchIcon/>,
          }}/> */}
        
        <div className={classes.subcontainer}>
        <Link to="/perguntas-frequentes" style={{textDecoration: "none"}}>
            <Card className={classes.card}>
              <CardContent>
                <div className={classes.titleContainer}>
                  <ContactSupportIcon
                    fontSize='large'
                    className={classes.cardIcon}
                  />
                  <h3 className={classes.cardTitle}>Perguntas Frequentes</h3>
                </div>
                <Typography className={classes.cardParagraph} variant='body1'>
                  Tire sua dúvida lendo as respostas das perguntas mais
                  frequentes.
                </Typography>
              </CardContent>
            </Card>
          </Link>

            <Card  className={classes.card}>
              <CardContent>
                <div className={classes.titleContainer}>
                  <PhoneIcon  fontSize="large" className={classes.cardIcon}/>
                  <h3 className={classes.cardTitle}>SAC</h3>
                </div>
                <Typography className={classes.cardParagraph} variant="body1">Ainda ficou com dúvida? Entre em contato com o nosso Serviço de Atendimento ao Cliente: Telefone: 0800 102 0880.</Typography>
              </CardContent>
            </Card>

        </div>
        
        {/* <Typography variant="subtitle1" className={classes.subtitle}>Assuntos nas perguntas frequentes</Typography>
        
        <div className={classes.faqWrapper}>
            <Card className={classes.faqCard}>
              <CardContent className={classes.faqContent}>
                <CreditCardIcon className={classes.faqIcon}/>
                <h6 className={classes.faqTitle}>Pagamento</h6>
              </CardContent>
            </Card>
            <Card className={classes.faqCard}>
              <CardContent className={classes.faqContent}>
                <PersonIcon className={classes.faqIcon}/>
                <h6 className={classes.faqTitle}>Minha Conta</h6>
              </CardContent>
            </Card>
            <Card className={classes.faqCard}>
              <CardContent className={classes.faqContent}>
                <PictureAsPdfIcon className={classes.faqIcon}/>
                <h6 className={classes.faqTitle}>Certificados</h6>
              </CardContent>
            </Card>
            <Card className={classes.faqCard}>
              <CardContent className={classes.faqContent}>
                <DescriptionIcon className={classes.faqIcon}/>
                <h6 className={classes.faqTitle}>Cadastro</h6>
              </CardContent>
            </Card>
            <Card className={classes.faqCard}>
              <CardContent className={classes.faqContent}>
                <SecurityIcon className={classes.faqIcon}/>
                <h6 className={classes.faqTitle}>Segurança</h6>
              </CardContent>
            </Card>
            <Card className={classes.faqCard}>
              <CardContent className={classes.faqContent}>
                <SentimentDissatisfiedIcon className={classes.faqIcon}/>
                <h6 className={classes.faqTitle}>Arrependimento</h6>
              </CardContent>
            </Card>
        </div> */}
       

      </div>
    </React.Fragment >
  );
};

export default Help;
