import React, { useEffect, useState, useRef } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Button, Typography } from '@material-ui/core';
import { filterOptions } from 'shared/util/search';
import PropTypes from 'prop-types';

import createStyles from './SearchFilterMobile.styles';

const SearchFilter = props => {
  const [filter, setFilter] = useState(0);
  // const [checkedFilters, setcheckedFilters] = useState({});

  // const handleStateChange = event => {
  //   setcheckedFilters({
  //     ...checkedFilters,
  //     [event.target.name]: event.target.checked
  //   });
  // };

  const {
    checkedFilters,
    handleFilterChange,
    handleFilterSubmitClick,
    handleClose,
    variant,
    searchFilters
  } = props;

  // const size = useWindowSize();

  // function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  // return windowSize;
  // }

  const classes = createStyles({ props, windowSize });

  function handleClick(name) {
    setFilter(name);
  }

  function resetFilter() {
    setFilter(0);
  }

  // useEffect(() => {
  //   !props.isOpen && handleClose();
  // });

  const closeSearchfilter = e => {
    e.preventDefault();
    e.stopPropagation();
    handleClose();
  };

  return (
    <div className={classes.root} onClick={handleClose} ref={props.filterRef}>
      <div className={classes.container} onClick={e => e.stopPropagation()}>
        <Typography
          variant='subtitle1'
          color='textPrimary'
          classes={{ root: classes.filtersLabel }}
        >
          Filtros
        </Typography>
        {searchFilters && searchFilters.map(item => {
          return (
            <div className={classes.filter}>
              <Button
                onClick={() => handleClick(item.filterName)}
                variant='contained'
                classes={{
                  root: classes.button,
                  label: classes.buttonLabel
                }}
                endIcon={<ArrowForwardIosIcon fontSize='small' />}
              >
                {item.filterName}
              </Button>
            </div>
          );
        })}
        {variant === 'formBased' && (
          <div className={classes.filterBtn}>
            <Button
              fullWidth
              onClick={handleFilterSubmitClick}
              variant='contained'
              color='primary'
            >
              Filtrar
            </Button>
          </div>
        )}
      </div>
      {searchFilters && searchFilters.map(item => {
        return (
          <div
            onClick={e => e.stopPropagation()}
            className={`${classes.optionContainer} ${filter ==
              item.filterName && classes.showOptions}`}
          >
            <Button
              onClick={() => resetFilter()}
              variant='contained'
              classes={{
                root: classes.button,
                label: classes.buttonLabelBack
              }}
              startIcon={<ArrowBackIosIcon fontSize='small' />}
            >
              {item.filterName}
            </Button>
            {item.options.map(option => {
              return (
                <FormControlLabel
                  key={option.key}
                  classes={{
                    root: classes.option,
                    label: classes.optionLabel
                  }}
                  label={option.name}
                  control={
                    <Checkbox
                      key={option.key}
                      name={option.key}
                      checked={
                        checkedFilters[item.filterKey] !== undefined &&
                        checkedFilters[item.filterKey].includes(option.name)
                      }
                      onChange={() =>
                        handleFilterChange(item.filterKey, option)
                      }
                      value='checked'
                      color='primary'
                    />
                  }
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

SearchFilter.propTypes = {
  checkedFilters: PropTypes.object,
  handleFilterChange: PropTypes.func,
  handleFilterSubmitClick: PropTypes.func,
  handleClose: PropTypes.func,
  variant: PropTypes.string
};

export default SearchFilter;
