import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import RegisterBoxForm from './RegisterBoxForm';
import { registerFormValidator } from './RegisterBoxForm.validator';

const LoginBoxFormWrapper = (props) => {

  const {
    register,
    history,
    redirectTo,
    isLoading,
    errors,
    setSignUpCompleted,
    setConfirmationEmail,
    afterRegister,
    editUserRegistration,
    handleClose,
    userData
  } = props;

  const initialValues = {
    name: userData.name,
    fantasyName: userData.fantasyName,
    companyName: userData.companyName,
    email: userData.email,
    cnpj: userData.cnpj,
    subscribeOffers: userData.subscribeOffers, 
    cep: userData.cep, 
    address: userData.address, 
    state:  userData.state, 
    number:  userData.number, 
    city: userData.city, 
    complement: userData.complement, 
    industry: userData.industry
  };

  const handleSubmit = async (formValues, actions) => {
    try {
      const response = await register(formValues);
      if (response.status === 200) {
        setSignUpCompleted(true);
        setConfirmationEmail(formValues.email);
        actions.resetForm();
      }
      if (afterRegister) {
        afterRegister();
      }
    } catch (err) {}
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={registerFormValidator}
    >
      {(propsFormik) => (
        <RegisterBoxForm
          isLoading={isLoading}
          errors={errors}
          {...propsFormik}
        />
      )}
    </Formik>
  );
};

export default LoginBoxFormWrapper;
