import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  rootRegister: {
    '@media (min-width: 400px)': {
      paddingTop: '2rem',
      marginBottom: '2rem',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    }
  },
  registerBox: {}
}));

export default createStyles;
