import React from 'react';
import Modal from '@material-ui/core/Modal';
import createStyles from './SuccessModal.styles';
import { Typography } from '@material-ui/core';
import Button from 'components/ButtonPrimary';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const SuccessModal = (props) => {
  const classes = createStyles();
  const [modalStyle] = React.useState(getModalStyle);
  
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <ClearIcon className={classes.clearIcon} onClick={props.handleClose}/>
      <div className={classes.content} style={{alignItems: 'center'}}>
          <CheckCircleIcon fontSize="large" style={{color: '#32559D'}}  />
          <Typography variant="h3" className={classes.titleSuccess}>Email enviado!</Typography>
          <Typography className={classes.text} variant="body1">Em breve você receberá o email, caso não encontre verifique na caixa de spam ou lixeira.</Typography>
      
          <Button className={classes.button} lowercase width='100%' onClick={props.handleClose}>
            Entendi
          </Button>
          
          <Link
            className={classes.registerLink}
            to='#'
          >
            Não recebeu? Reenviar email.
          </Link>
      </div>
    </div>
  );


  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
        
    </Modal>
  );
};

export default SuccessModal;
