import React, { useState } from 'react';
import { Formik } from 'formik';
import RegisterBoxForm from './RegisterBoxForm';
import { registerFormValidator } from './RegisterBoxForm.validator';
import {authenticate, registerUser} from 'api/Session';

const LoginBoxFormWrapper = (props) => {

  const {
    history,
    setSignUpCompleted,
    setConfirmationEmail,
    afterRegister,
    citiesByState,
    citys
  } = props;

  let initialValues = {
    name : '',
    lastname : '',
    email : '',
    cpf : history.location.state ? history.location.state.cpfCNPJ : '',
    rg : '',
    rgUf: '',
    rgEmitter:'',
    rgEmissionDate:'',
    birthDate : '',
    cep : '',
    state : '',
    city : '',
    neighbourhood : '',
    address : '',
    addressNumber : '',
    addressComplement : '',
    cepBilling : '',
    stateBilling : '',
    cityBilling : '',
    neighbourhoodBilling : '',
    addressBilling : '',
    addressNumberBilling : '',
    addressComplementBilling : '',
    hometown : '',
    homestate : '',
    municipality : '',
    mothersName : '',
    maritalStatus : '',
    scholarity : '',
    situacaoOcup: '',
    phone1 : '',
    phone2 : '',
    password : '',
    confirmPassword : '',
    receiveNews : true,
    addressSame: false,
    acceptSendPersonalInfos : false
  };

  const handleSubmit = async (values, action) => {
    try {
      action.setSubmitting(true);

      // var registerObject = {
      //   name : values.name,
      //   lastname : values.lastname,
      //   email : values.email,
      //   cpf : values.cpf,
      //   rg : values.rg,
      //   birthDate : values.birthDate,
      //   addressShipping : {
      //     cep : values.cep,
      //     state : values.state,
      //     city : values.city,
      //     neighbourhood : values.neighbourhood,
      //     address : values.address,
      //     addressNumber : values.addressNumber,
      //     addressComplement : values.addressComplement,
      //   },
      //   addressBilling : {
      //     cep : values.cepBilling,
      //     state : values.stateBilling,
      //     city : values.cityBilling,
      //     neighbourhood : values.neighbourhoodBilling,
      //     address : values.addressBilling,
      //     addressNumber : values.addressNumberBilling,
      //     addressComplement : values.addressComplementBilling,
      //   },
      //   hometown : values.hometown,
      //   homestate : values.homestate,
      //   mothersName : values.mothersName,
      //   maritalStatus : values.maritalStatus,
      //   scholarity : values.scholarity,
      //   phone1 : values.phone1,
      //   phone2 : values.phone2,
      //   password : values.password,
      //   confirmPassword : values.confirmPassword,
      //   receiveNews : values.receiveNews,
      //   addressSame: values.addressSame,
      //   acceptSendPersonalInfos : values.acceptSendPersonalInfos
      // };
      

      const response = await registerUser(values);

      if (response?.status !== 200){
        throw {error : response};
      }

      action.resetForm({});
      action.setStatus({success: true});

      setSignUpCompleted(true);
      setConfirmationEmail(values.email);
      afterRegister();

    } catch (error) {
      action.setErrors({externalErrors:  error?.response?.data?.detail || "Erro ao efetuar o cadastro. Por favor, tente novamente mais tarde." })
      action.setStatus({success: false});
    }
    finally {
      action.setSubmitting(false);
      _applyNewUserInSession(values);
    }
  };

  async function _applyNewUserInSession(values) {
    const response = await authenticate(values.email, values.password, true);
    let sessionNew = {
      isAuthenticated: true,
      emailToResetPassword:'',
      token: response.token,
      'user': response.user}
    let allStr = localStorage.getItem('persist:root-senai');
    let all = JSON.parse(allStr);
    all.session = JSON.stringify(sessionNew);
    localStorage.setItem('persist:root-senai', JSON.stringify(all));
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={registerFormValidator}
    >
      {(propsFormik) => (
        <RegisterBoxForm
        citiesByState={citiesByState}
        citys={citys}
          {...propsFormik}
        />
      )}
    </Formik>
  );
};

export default LoginBoxFormWrapper;
