import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import LoginForm from './VoucherForm';
import { voucherFormValidator } from './VoucherForm.validator';
import InvalidCredentialsError from 'shared/error/Exceptions/InvalidCredentialsException';

const VoucherFormWrapper = (props) => {
  const { getTotalsInCart } = props;
  const initialValues = {
    voucherCode: ''
  };

  const { applyVoucher, isLoading, handleClose, externalErrors } = props;

  const handleSubmit = async (formValues, actions) => {
    try {
      await applyVoucher(formValues);
      getTotalsInCart();
      // handleClose();
    } catch (err) {
      // if (err instanceof InvalidCredentialsError) {
      // }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={voucherFormValidator}
    >
      {(propsFormik) => (
        <LoginForm
          isLoading={isLoading}
          externalErrors={externalErrors}
          {...propsFormik}
        />
      )}
    </Formik>
  );
};

export default VoucherFormWrapper;
