import NewPassword from './NewPassword.view';
import { connect } from 'react-redux';
import { registerNewPassword } from 'modules/Login/Login.ducks.js';

const mapStateToProps = state => {
  return {
    isLoading: state.session.isLoading,
    success: state.session.success,
    emailToResetPassword: state.session.emailToResetPassword,
    serverError: state.session.errors
  };
};

const mapDispatchToProps = {
  registerNewPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
