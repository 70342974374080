import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import NewPasswordForm from './NewPasswordForm';
import { newPasswordFormValidator } from './NewPasswordForm.validator';
import InvalidCredentialsError from 'shared/error/Exceptions/InvalidCredentialsException';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const NewPasswordFormWrapper = props => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const {
    registerNewPassword,
    isLoading,
    handleClose,
    emailToResetPassword,
    success,
    resetToken,
    errors,
    serverError,
    resetEmail
  } = props;

  const initialValues = {
    newPassword: "", 
    passwordConfirmation: ""
  };

  const handleSubmit = async (formValues, actions) => {
    formValues.email = resetEmail;
    formValues.resetToken = resetToken;
    // debugger
    try {
      const response = await registerNewPassword(formValues);
      if (response) {
        history.push("/");
        return;
      }
    } catch (err) {
     console.log(err)
    }
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setOpen(success);
  }, [success]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={newPasswordFormValidator}
    >
      {propsFormik => (
        <NewPasswordForm
          errors={errors}
          serverError={serverError}
          isLoading={isLoading}
          success={success}
          {...propsFormik}
        />
      )}
    </Formik>
  );
};

export default NewPasswordFormWrapper;
