import React from 'react';
import createStyles from './DeleteModal.styles';
import Modal from '@material-ui/core/Modal';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Typography } from '@material-ui/core';
import ButtonPrimary from 'components/ButtonPrimary';
import ClearIcon from '@material-ui/icons/Clear';


function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const DeleteModal = props => {
  const classes = createStyles(props);
  const [ modalStyle ] = React.useState(getModalStyle);
  
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <ClearIcon className={classes.clearIcon} onClick={props.handleClose}/>
      <div className={classes.titleContainer}>
        <DeleteForeverIcon fontSize="large" style={{ color: '#D31823' }} className={classes.deleteIcon}/>
        <h3 className={classes.title}>Deletar {props.title}?</h3>
      </div>

      {props.title == 'forma de pagamento' ? 
          <div className={classes.cardInfoContainer}>
            <Typography variant="body1" style={{color: 'rgba(0, 0, 0, 0.65)'}}>{props.data.flag} •••• {props.data.number}</Typography>
            <Typography variant="body1" style={{color: 'rgba(0, 0, 0, 0.65)'}}>{props.data.fullName}</Typography>
            <Typography variant="body1" style={{color: 'rgba(0, 0, 0, 0.65)'}}>Validade: {props.data.formatedDate} </Typography>
          </div>
          : 
          <div>
            <Typography variant="body1" style={{color: 'rgba(0, 0, 0, 0.65)'}}>Rua Dr. Antônio Basílio, 400</Typography>
            <Typography variant="body1" style={{color: 'rgba(0, 0, 0, 0.65)'}}>João da Silva</Typography>
            <Typography variant="body1" style={{color: 'rgba(0, 0, 0, 0.65)'}}>Condomínio do João, Bloco C, APT 505 </Typography>
            <Typography variant="body1" style={{color: 'rgba(0, 0, 0, 0.65)'}}>Jardim da Penha </Typography>
            <Typography variant="body1" style={{color: 'rgba(0, 0, 0, 0.65)'}}>Vitória - ES  </Typography>
            <Typography variant="body1" style={{color: 'rgba(0, 0, 0, 0.65)'}}>CEP: 11023-456  </Typography>
          </div>
    
      }
      

      <div className={classes.buttonContainer}>
        <ButtonPrimary style={{marginRight: '1rem'}}
                  variant='outlined'
                  buttonText="Cancelar"
                  onClick={props.handleClose}/>
        
        <ButtonPrimary className={classes.button}
                  variant='contained'
                  buttonText="Deletar"
                  onClick={props.handleDeleteMethod}/>
        

      </div>
      
    </div>
  );


  return (
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
  );
};


export default DeleteModal;
