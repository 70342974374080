import CartConfirmation from './CartConfirmation';
import { connect } from 'react-redux';
import {getBilletForPrint } from '../../Cart.ducks'

const mapStateToProps = (state) => {
  return {
    isLoading: state.cart.isLoading,
    user: state.session.user,
    isAuthenticated: state.session.isAuthenticated,
    billet: state.cart.billet,
  };
};

const mapDispatchToProps = {
  getBilletForPrint
};

export default connect(mapStateToProps, mapDispatchToProps)(CartConfirmation);
