import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import VoucherForm from './VoucherForm/VoucherForm.formik';
import ClearIcon from '@material-ui/icons/Clear';
import createStyles from './OrderSummary.styles';
import { addThousandDot } from 'shared/util/textTransformation.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getCustomAttribute } from 'shared/util/ProductCustomAttribute';
  
const OrderSummary = (props) => {
  const classes = createStyles(props);
  const {
    isLoading,
    totals,
    cartItems,
    cartVouchers,
    applyVoucher,
    deleteVoucher,
    getTotalsInCart
  } = props;

  const [isVoucherOpen, setIsVoucherOpen] = useState(false);
  const [isGone, setIsGone] = useState(false);

  const handleRemoveVoucher = async (voucherId) => {
    var requiredString = voucherId.substring(voucherId.indexOf("(") + 1, voucherId.indexOf(")"));
    await deleteVoucher(requiredString);
    getTotalsInCart();
  };

  const formatPrice = (value) => {
    if (value) {
      var valor = parseFloat(value)
      return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    }
    else return '-';
  };

  const getRawTotalPrice = () => {
    const rawPrice = totals.totalSegments.filter((segment) => segment.code === "subtotal")[0]
    return rawPrice.value
  };

  const getFinalPrice = () => {
    const rawPrice = totals.totalSegments.filter((segment) => segment.code === "grand_total")[0]
    return rawPrice.value
  };

  const handlePrice = (product) => {
    if(getCustomAttribute(product, 'special_price') !== ""){
      return getCustomAttribute(product, 'special_price');
    }else{
      return product.price;
    }
  };

  return (
    <div>
      <Typography variant='subtitle1' className={classes.orderResumeTitle}>
        Resumo da compra ({`${cartItems.length} itens`})
      </Typography>
      {cartItems.map((course, index) => {
        return (
          <div
            key={index}
            className={`${classes.resumeSection} ${classes.resumeItemSection}`}
          >
            <Typography style={{ paddingRight: '1rem' }}>
              {course.product.name}
            </Typography>
            <Typography style={{ whiteSpace: 'nowrap' }}>
              {`${formatPrice(handlePrice(course.product))}`}
            </Typography>
          </div>
        );
      })}
      <div className={classes.priceSectionContainer}>
        <div
          className={`${classes.resumeSection} ${classes.reumePriceSection}`}
        >
          <Typography variant='subtitle2'>Subtotal</Typography>
          <Typography variant='subtitle1' style={{ whiteSpace: 'nowrap' }}>
            {`${formatPrice(getRawTotalPrice())}`}
          </Typography>
        </div>
        <div className={`${classes.discountPriceSection}`}>
          <Typography variant='subtitle2'>Descontos</Typography>
          {isVoucherOpen ? null : (
            <span
              className={classes.link}
              onClick={() => setIsVoucherOpen(true)}
            >
              Adicionar cupom
            </span>
          )}
        </div>
        {cartVouchers.length > 0 && (
          <div className={`${classes.vouchersContainer}`}>
            {cartVouchers.map((voucher) => {
              return (
                <div>
                  <Typography variant='subtitle2'>
                    Cupom "{voucher.title}" aplicado:
                  </Typography>
                  <div className={`${classes.voucherItemContainer}`}>
                    <Typography>{voucher.title}</Typography>
                    <div className={classes.voucherItem}>
                      <Typography className={classes.voucherPriceNumber}>
                        {`${formatPrice(voucher.value)}`}
                      </Typography>
                      <ClearIcon
                        fontSize='small'
                        onClick={() => handleRemoveVoucher(voucher.title)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {isVoucherOpen ? (
          <div className={`${classes.reumePriceSection}`}>
            <div className={classes.resumeSection}>
              <Typography gutterBottom variant='body2'>
                Insira seu cupom
              </Typography>
              <span
                className={classes.link}
                onClick={() => setIsVoucherOpen(false)}
                to='#'
              >
                Cancelar
              </span>
            </div>
            <VoucherForm isLoading={isLoading} getTotalsInCart={getTotalsInCart} applyVoucher={applyVoucher} />
          </div>
        ) : null}
        <div
          className={`${classes.resumeSection} ${classes.reumePriceSection}`}
        >
          <Typography variant='subtitle2'>Total</Typography>
          <Typography variant='subtitle1' style={{ whiteSpace: 'nowrap' }}>
            {`${formatPrice(getFinalPrice())}`}
          </Typography>
        </div>
      </div>
    </div>
  );
};

OrderSummary.propTypes = {};

export default OrderSummary;